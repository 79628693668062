import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next'; // import useTranslation hook
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { IDepartmentData } from "../../../interfaces/IDepartmentData";
import { IBrandData } from "../../../interfaces/IBrandData";

const AddBrand: React.FC<IBrandData> = ({ savedObj, cancelModal, companyId, brandName }) => {
  const { t } = useTranslation();
  const [brandData, setBrandData] = useState<IBrandData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  };


  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  const BrandRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newGroup = {
      brandName: values.brandName || brandName,
      companyId: companyIdToUse || companyId,
    };

    HTTPService.POST(`/Brand/registerBrand`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          // console.log("Marka başarıyla kaydedildi.");
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-brand';
          if (currentUrl.includes(redirectUrl)) {
            window.location.replace('admin/brand-management');
          }
          else {
            savedObj(response.data)
            cancelModal();
          }
        } else if (response.status === 450) {
          toast.error(t(response.data))
        }
        else {
          console.error("Marka kaydı başarısız.");
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        console.error("Marka kaydı başarısız: ", error);
      });
  };

  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';
    const inventoryManage = 'admin/inventory-management';

    if (currentUrl.includes(addInventoryUrl)) {
      cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
      cancelModal();
    }else if(currentUrl.includes(inventoryManage)){
      cancelModal();
    }
    else {
      window.location.replace("/admin/brand-management/");
    }
  };

  return (
    <Card style={{ padding: "50px" }}>
      <h3>{t('register_brand')}</h3>
      <Form onFinish={BrandRegister} layout="vertical"
        fields={[
          { name: ["selectedCompany"], value: companyId },
        ]}
      >
        {!isCompanyIdAvailable && userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '6px' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="brandName"
              label={t('brand_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_brand_name'),
                  whitespace: true,
                }
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                style={{ borderRadius: 0, width: '100%' }}
                placeholder={t('brand_name')}
                maxLength={50}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddBrand;
