import React, { useEffect, useState } from 'react';
import { Alert, Card, Col, Row, Progress, Tag, Table, Button, Modal,Tooltip as AntTooltip} from 'antd';
import HTTPService from '../../utils/makerequest';
import { Column } from '@ant-design/plots';
import './../../assets/sass/components/Dashboard.scss';
import { IPageData } from '../../interfaces/page-data';
import { t } from 'i18next';
import { StateLoader } from '../../redux/StateLoader';
import AltisDataGrid, { IAltisDataGridColumns, IDropdownItem } from '../../AltisComponent/AltisDataGrid';
import { ITrackings } from '../../interfaces/ITracking';
import toast from 'react-hot-toast';
import { ICountings } from '../../interfaces/ICountings';
import moment from 'moment';
import axios from 'axios';
import { UserOutlined, ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';
import { IAuthentication } from '../../interfaces/authentication';
import { IAssetLocation, IAssetLocations} from '../../interfaces/IAssetLocation';
import { PieChart, Cell, ResponsiveContainer,Legend,Tooltip as RechartsTooltip} from 'recharts';
import { Pie as AntdPie } from '@ant-design/plots';
import { ResponsiveSunburst } from '@nivo/sunburst';
import { ResponsiveCirclePacking } from '@nivo/circle-packing';
import { ResponsivePie } from '@nivo/pie'
import { styleText } from 'util';
import { 
  Card as MuiCard, 
  CardContent, 
  Typography, 
  makeStyles,
  Box
} from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import ProgressBar from "@ramonak/react-progress-bar";
import { Pie } from 'react-chartjs-2';
import { IAssetCountings } from '../../interfaces/IAssetCounting';


interface IAsset{
  inventoryName?:string;
  locationId?:number;
}

interface ISelectedAssets {
  total: number;
  count?: number;
  data?:  IAsset[];
}

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    overflow: 'visible',
    marginBottom: theme.spacing(2),
    height: '120px',
  },
  iconBox: {
    position: 'absolute',
    top: -25,
    left: 20,
    padding: theme.spacing(2),
    height: 90,
    width: 90,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  icon: {
    fontSize: 45,
    color: '#fff',
  },
  content: {
    paddingTop: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingRight: theme.spacing(3),
  },
  label: {
    color: '#999',
    fontSize: 16,
    fontWeight: 400,
    marginBottom: theme.spacing(1),
  },
  value: {
    color: '#3C4858',
    fontSize: '2em',
    fontWeight: 500,
    marginBottom: 0,
  }
}));

const Dashboard: React.FunctionComponent<any> = props => {
  const { onSetPage } = props;
  const [pieData, setPieData] = useState<any[]>([]);
  const [shapeData, setShapeData] = useState<any[]>([]);
  const [assetLocation,setAssetLocation] = useState<IAssetLocations>({});
  const [assetLocationDetail,setAssetLocationDetail] = useState<IAssetLocations>({});
  const [dashboardDetails,setDashboardDetails] = useState<any>({});
  const [activeCountings, setActiveCountings] = useState<any[]>([]);
  const [allCounting, setAllCounting] = useState<IAssetCountings>({});
  const [chartData, setChartData] = useState<any[]>([]);
  const [futureCountings, setFutureCountings] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState<ISelectedAssets>();
  const [selectedLocationId, setSelectedLocationId] = useState<number | null>(null);
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const classes = useStyles();

  const pageData: IPageData = {
    title: t('dashboard'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    getWidgets();
    getActiveCountings();
    getFutureCountings();
    AssetLocationFilter();
  }, []);

  const getWidgets = () => {
    HTTPService.GET(`/Dashboard/getDashboardCount`)
    .then(response => {
      if (!response.data) {
        return;
      }
      setDashboardDetails(response.data);
    })
    .catch(error => {
      console.error('Error fetching pie data:', error);
    });

    HTTPService.GET(`/Dashboard/allInventoryStatusCount`)
      .then(response => {
        if (!response.data) {
          return;
        }
        const pieChartData = response.data.map(item => ({
          id: item.inventoryStatusName, 
          label: item.inventoryStatusName, 
          value: Number(item.percentage).toFixed(0),
          formattedValue: `${Number(item.percentage).toFixed(0)}%`
        })) || [];
        
        setPieData(pieChartData);
      })
      .catch(error => {
        console.error('Error fetching pie data:', error);
      });

    HTTPService.GET(`/Dashboard/allInventoryCategoryCount`)
      .then(response => {
        if (!response.data) {
          return;
        }
        const totalCount = response.data.reduce((acc, item) => acc + item.count, 0);
        const categoryChartData =response.data.map(item => ({
          type: item.categoryName.length > 10 ? item.categoryName.slice(0, 15) + '...' : item.categoryName,
          Kategori: item.count,
          percentage: totalCount ? ((item.count / totalCount) * 100).toFixed(2) : 0, 
        }));
    
        setShapeData(categoryChartData);
      })
      .catch(error => {
        console.error('Error fetching category data:', error);
      });
  };

  const AssetLocationFilter = (e?) => {
   const body = {
    // pageSize: e?.pageSize,
    // page: e?.page,
    companyId:userInfo?.companyId,
    campusName:e?.campusName,
    locationName:e?.locationName,
    assetCount:e?.assetCount
   }
   HTTPService.POST(`/Dashboard/assetbylocation`, body).then((response) => {
    if (!response.data) {
      return;
    }
    if (response.status === 200) {
      setAssetLocation(response.data)
      setAssetLocationDetail(response.data);
      //console.log(response.data)
    } else {
      toast.error(response.data);
    }
  });
  };

  const combinedData = chartData.flatMap(item => [
    { month: ` ${item.month} `, value: item.completeCount, type: `Tamamlanan Sayım ` },
    { month: ` ${item.month} `, value: item.totalCount - item.completeCount, type: ` Kalan Sayım ` },
  ]);
  
  const filteredData = combinedData.filter(item => item.value >= 0); // Negatif değerleri filtrele

  const configs = {
    data: filteredData,
    xField: 'month',
    yField: 'value',
    stack: true,
    colorField: 'type',
    yAxis: {
      min: 0,
    },
    tooltip: {
      title: (item) => `${item.month}`,
      formatter: (datum) => {
        const completedItem = filteredData.find(item => item.month === datum.month && item.type === 'Tamamlanan');
        const remainingItem = filteredData.find(item => item.month === datum.month && item.type === 'Kalan');
        return {
          value: `${datum.countingJobName} : ${completedItem ? completedItem.value : 0}: ${remainingItem ? remainingItem.value : 0}`
        };
      },
    },
    label: {
      content: (data) => `${data.value}`,
      textBaseline: 'bottom',
      position: 'inside',
    },
    interaction: {
      elementHighlightByColor: {
        link: true,
      },
    },
    state: {
      active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
      inactive: { opacity: 0.5 },
    },
    legend: {
      position: 'top',
      itemHeight: 20,
      itemMargin: 10,
      layout: 'horizontal'
    },
  };

  const config = {
    data: chartData.length > 0 ? chartData : [],
    xField: 'month',
    yField: 'rainfall',
    colorField: 'name',
    group: true,
  };

  // const customShapeConfig = {
  //   data: shapeData,
  //   xField: 'type',
  //   yField: 'Kategori',
  //   scale: {
  //     count: {
  //       nice: true,
  //       alias: 'category',
  //       formatter: (value) => Math.floor(value),
  //     },
  //   },
  //   axis: {
  //     x: {
  //       label: {
  //         style: {
  //           fontSize: 2,
  //         },
  //       },
  //     },
  //   },
  //   autoFit: true,
  //   tooltip: {
  //     title: (datum) => datum.type, 
  //     formatter: (datum) => {
  //       return {
  //         name: datum.type,
  //         value: datum.Kategori, 
  //       };
  //     },
  //   },
  // };

  const customShapeConfig = {
    data: shapeData,
    xField: 'type',
    yField: 'Kategori',
    stack: true,
    colorField: 'type',
    //colorField:'green',
    label: {
      text: 'Kategori',
      textBaseline: 'bottom',
      position: 'top',
      style:{
        fontSize:8,
        fontWeight: 'bold'
      }
    },
    interaction: {
      elementHighlightByColor: {
        link: true,
      },
    },
    legend:false,
    state: {
      active: { linkFill: 'rgba(0,0,0,0.25)', stroke: 'black', lineWidth: 0.5 },
      inactive: { opacity: 0.5 },
    },
  };
  
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  const CustomPieChart = ({ data }) => {
    const total = data.reduce((sum, entry) => sum + entry['Varlık Sayısı'], 0);
    const type = data.map(x=>x.type)

    const CustomTooltip = ({ active, payload }) => {
      if (active && payload && payload.length) {
        const item = payload[0].payload;
        return (
          <div style={{
            backgroundColor: '#fff',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px'
          }}>
            <p style={{ margin: 0 }}><strong>{item.type}</strong></p>
            <p style={{ margin: 0 }}>Varlık Sayısı: {item['Varlık Sayısı']}</p>
          </div>
        );
      }
      return null;
    };

    // const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
    //   const RADIAN = Math.PI / 180;
    //   const radius = outerRadius * 1.4;
    //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
    //   const y = cy + radius * Math.sin(-midAngle * RADIAN);
    //   const percent = ((value / total) * 100).toFixed(1);

    //   const textAnchor = midAngle < 180 ? 'start' : 'end';

    //   return (
    //     <text
    //       x={x}
    //       y={y}
    //       fill="#000"
    //       textAnchor={textAnchor}
    //       dominantBaseline="central"
    //       fontSize={12}
    //     >
    //       {`%${Number(percent)}`}
    //     </text>
    //   );
    // };

    // return (
    //   <ResponsiveContainer width="100%" height={400}>
    //     <PieChart>
    //       <Pie
    //         data={data}
    //         cx="50%"
    //         cy="50%"
    //         labelLine={false}
    //         label={renderCustomizedLabel}
    //         innerRadius={80}
    //         outerRadius={140}
    //         paddingAngle={2}
    //         dataKey="Envanter Sayısı"
    //       >
    //         {data.map((entry, index) => (
    //           <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
    //         ))}
    //       </Pie>
    //       <RechartsTooltip content={<CustomTooltip active={undefined} payload={undefined} />} />
    //         <Legend
    //           layout="vertical"
    //           align="right"
    //           verticalAlign="middle"
    //           formatter={(value, entry) => {
    //             console.log(type)
    //             return <span style={{ color: '#666', fontSize: '14px' }}>{entry.payload.type}</span>
    //           }}
    //         />
    //     </PieChart>
    //   </ResponsiveContainer>
    // );
  };

  const preparePieData = (data: IAssetLocation[]) => {

    if (!data || data.length === 0) {
      return [];
    }

    const campusMap = data?.reduce((acc, item) => {
      if (acc[item.campusName]) {
        acc[item.campusName] += item.assetCount;
      } else {
        acc[item.campusName] = item.assetCount;
      }
      return acc;
    }, {});

    return Object?.entries(campusMap)?.map(([campusName, assetCount]) => ({
      type: campusName,
      Varlık_Sayısı: assetCount
    }));
  };

  const assetData = preparePieData(assetLocation?.data);
  
  const campusConfig = {
    data: assetData,
    angleField: 'value',
    colorField: 'type',
    innerRadius: 0.6,
    label: {
      content: '{value}',
      style: {
        fontSize: 12,
        fontWeight: 'bold',
      },
    },
    legend: {
      position: 'right',
    },
    annotations: [
      {
        type: 'text',
        position: ['50%', '50%'],
        content: t('assetbyCampus'),
        style: {
          textAlign: 'center',
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
    ],
  };

    const getActiveCountings = () => {
    HTTPService.GET(`/Dashboard/inventoryByCounting`)
      .then(response => {
        if (!response.data) return;
        //console.log(response.data)
        setAllCounting(response.data)
      })
      .catch(error => {
        console.error('Error fetching active countings:', error);
        toast.error(t('errorFetchingData'));
      });
  };
  //console.log(allCounting)



  // const getActiveCountings = () => {
  //   HTTPService.GET(`/Dashboard/inventoryByCounting`)
  //     .then(response => {
  //       if (!response.data) return;
        
  //       // Bugünün tarihini al
  //       const today = new Date();
  //       today.setHours(0, 0, 0, 0);

  //       const formattedData = response.data
  //         .filter(item => {
  //           const finishDate = new Date(item.finishDate);
  //           return finishDate >= today;
  //         })
  //         .map(item => ({
  //           name: item.countingJobName,
  //           percent: item.completionPercentage,
  //           completeCount: item.completeCount,
  //           totalCount: item.totalCount,
  //           remainingCount: item.remainingCount,
  //           responsible: item.responsibleName,
  //           startDate: new Date(item.startDate).toLocaleDateString('tr-TR'),
  //           finishDate: new Date(item.finishDate).toLocaleDateString('tr-TR')
  //         }));

  //       setActiveCountings(formattedData);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching active countings:', error);
  //       toast.error(t('errorFetchingData'));
  //     });
  // };

  const transformedAssetData = assetData?.map((item) => ({
    ...item,
    value: (item['varlık sayısı']), 
  }));
  

  const getFutureCountings = () => {
    HTTPService.GET(`/Dashboard/inventoryByFutureCounting`)
      .then(response => {
        if (!response.data) return;
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const formattedData = response.data
          .filter(item => {
            const startDate = new Date(item.startDate);
            return startDate > today;
          })
          .map(item => ({
            name: item.countingJobName,
            percent: item.completionPercentage, 
            completeCount: item.completeCount,
            totalCount: item.totalCount,
            responsible: item.responsibleName,
            startDate: new Date(item.startDate).toLocaleDateString('tr-TR'),
            finishDate: new Date(item.finishDate).toLocaleDateString('tr-TR')
          }));

        setFutureCountings(formattedData);
      })
      .catch(error => {
        console.error('Error fetching future countings:', error);
        toast.error(t('errorFetchingData'));
      });
  };

  const prepareCirclePackingData = (locationData) => {
    // Kampüslere göre varlıkları grupla
    const campusGroups = {};
    locationData?.forEach(item => {
      if (!campusGroups[item.campusName]) {
        campusGroups[item.campusName] = 0;
      }
      campusGroups[item.campusName] += item.assetCount;
    });

    // Circle packing için veri formatını oluştur
    const data = {
      name: "root",
      children: Object.entries(campusGroups).map(([campusName, totalAssets]) => ({
        name: campusName,
        value: totalAssets,
        label: `${totalAssets} Varlık`
      }))
    };
    return data;
  };

  const assetModalColumns: IAltisDataGridColumns[] = [
    {
      title: t('asset'),
      key: 'inventoryName',
      placeHolder: t('asset'),
      inputType: 'input'
    },
  ];

  const showModal = (locationId: number) => {
    setSelectedLocationId(locationId);
    setIsModalVisible(true);
    getAssetsByLocationId(locationId);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const getAssetsByLocationId = (locationId: number, e?) => {
    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      locationId: locationId
    }
    HTTPService.POST(`/Dashboard/assetbylocations?locationId=${locationId}`, body)
      .then(response => {
        if (!response.data) return;
        setSelectedAssets(response.data);
      })
      .catch(error => {
        console.error('Error fetching assets:', error);
        toast.error(t('errorFetchingData'));
      });
  };

  const locationColumns: IAltisDataGridColumns[] = [
    {
      title: t('campus'),
      placeHolder: 'campusName',
      key: 'campusName',
      render: (campusName) => (
        <div style={{
          fontWeight: 'bold',
          color: '#1890ff'
        }}>
          {campusName}
        </div>
      ),
      inputType: ''
    },
    {
      title: t('location'),
      placeHolder: 'location',
      key: 'locationName',
      inputType: ''
    },
    {
      title: t('asset'),
      placeHolder: 'asset',
      inputType: 'operations',
      key: 'assetCount',
      render: (_, record) => {
        return (
          <AntTooltip title={t('preview')} placement="top">
            <Button
              size='small'
              icon={<EyeOutlined />}
              style={{ width: '60px', borderRadius: '70px' }}
              onClick={() => showModal(record.locationId)}
            />
          </AntTooltip>
        );
      },
    }
  ];

  const countingColumns: IAltisDataGridColumns[] = [
    {
      title: t('Counting Job Name'),
      placeHolder: t('Counting Job Name'),
      key: 'countingJobName',
      inputType: 'input',
      width:100
    },
    {
      title: t('Counting Start Date'),
      placeHolder: t('Counting Start Date'),
      key: 'startDate',
      inputType: 'operations',
      width:150,
      render: (value) => {
        if (!value) {
          return '';
        }
        
        return new Date(value).toLocaleDateString('tr-TR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
    },
    {
      title: t('Counting Finish Date'),
      placeHolder: t('Counting Finish Date'),
      inputType: 'operations',
      key: 'finishDate',
      width:150,
      render: (value) => {
        if (!value) {
          return '';
        }
        
        return new Date(value).toLocaleDateString('tr-TR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
    },
    {
      title: t('counting_progress'),
      placeHolder: t('counting_progress'),
      inputType: 'progress',
      width:90,
      key: 'completionPercentage',
    },
  ];


  return (
    <>
      {
        userInfo.licenceEndDates ? (
          new Date(userInfo.licenceEndDates) > new Date() ? (
            <Alert
              className='mb-4'
              message={t('Welcome to The Tag And Trace Assets System')}
              type='success'
              showIcon
            />
          ) : (
            <Alert
              className='mb-4'
              message={t('licence_end_time')}
              type='error'
              showIcon
            />
          )
        ) : null
      }

      
      <br></br>
      {userInfo.role === 2 || userInfo.role === 3 ?  (
             <Row gutter={16} className="mb-4">
              <Col span={6}  xs={24} sm={12} md={6} lg={6} xl={6}>
                 <MuiCard className={classes.card}>
                 <Box
                      className={classes.iconBox}
                      style={{
                        backgroundColor: '#ff8c00',
                        boxShadow: '0 8px 20px rgba(255, 140, 0, 0.5)',
                        transition: 'box-shadow 0.3s ease',
                        width:'80px',
                        height:'80px'
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 12px 30px rgba(255, 140, 0, 0.7)')}
                      onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '0 8px 20px rgba(255, 140, 0, 0.5)')}
                    >
                      <AccountCircle className={classes.icon} />
                    </Box>
                   <CardContent className={classes.content}>
                   <Typography className={classes.label} style={{marginTop:'-30px'}} >
                       {t("userCount")}
                     </Typography>
                     <Typography className={classes.value} style={{fontSize:'40px'}}>
                       {dashboardDetails?.userCount} / {dashboardDetails?.userLimit}
                     </Typography>
                   </CardContent>
                 </MuiCard>
               </Col>
               <Col span={6}  xs={24} sm={12} md={6} lg={6} xl={6}>
                  <MuiCard className={classes.card}>
                    <Box
                      className={classes.iconBox}
                      style={{
                        backgroundColor: '#43a047',
                        boxShadow: '0 8px 20px rgba(56, 142, 60, 0.5)',
                        transition: 'box-shadow 0.3s ease',
                        width:'80px',
                        height:'80px'
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.boxShadow = '0 12px 30px rgba(56, 142, 60, 0.7)')
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.boxShadow = '0 8px 20px rgba(56, 142, 60, 0.5)')
                      }
                    >
                      <span className="anticon" style={{ fontSize: '35px' }}>📦</span>
                    </Box>
                    <CardContent className={classes.content}>
                      <Typography className={classes.label} style={{ marginTop: '-30px' }}>
                        {t("itemmanage")}
                      </Typography>
                      <Typography className={classes.value} style={{ fontSize: '40px' }}>
                        {dashboardDetails?.assetCount} / {dashboardDetails?.assetLimit}
                      </Typography>
                    </CardContent>
                  </MuiCard>
                </Col>
                <Col span={6}  xs={24} sm={12} md={6} lg={6} xl={6}>
                    <MuiCard className={classes.card}>
                      <Box
                        className={classes.iconBox}
                        style={{
                          backgroundColor: '#48D1CC',
                          boxShadow: '0 8px 20px rgba(114, 46, 209, 0.5)',
                          transition: 'box-shadow 0.3s ease',
                          width:'80px',
                          height:'80px'
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.boxShadow = '0 12px 30px rgba(114, 46, 209, 0.7)')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.boxShadow = '0 8px 20px rgba(114, 46, 209, 0.5)')
                        }
                      >
                        <span className="anticon" style={{ fontSize: '35px' }}>📋</span>
                      </Box>
                      <CardContent className={classes.content}>
                        <Typography className={classes.label} style={{ marginTop: '-30px' }}>
                          {t("totalCountings")}
                        </Typography>
                        <Typography className={classes.value} style={{ fontSize: '40px' }}>
                          {dashboardDetails?.totalCountingCount}
                        </Typography>
                      </CardContent>
                    </MuiCard>
                  </Col>
                  <Col span={6}  xs={24} sm={12} md={6} lg={6} xl={6}>
                    <MuiCard className={classes.card}>
                      <Box
                        className={classes.iconBox}
                        style={{
                          backgroundColor: '#f5222d',
                          boxShadow: '0 8px 20px rgba(245, 34, 45, 0.5)',
                          width: '80px', 
                          height: '80px', 
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'box-shadow 0.3s ease',
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.boxShadow = '0 12px 30px rgba(245, 34, 45, 0.7)')
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.boxShadow = '0 8px 20px rgba(245, 34, 45, 0.5)')
                        }
                      >
                        <span className="anticon" style={{ fontSize: '35px' }}>🖨️</span> 
                      </Box>
                      <CardContent className={classes.content}>
                        <Typography className={classes.label} style={{ marginTop: '-30px' }}>
                          {t("printerCount")}
                        </Typography>
                        <Typography className={classes.value} style={{ fontSize: '40px' }}>
                          {dashboardDetails?.printerCount}
                        </Typography>
                      </CardContent>
                    </MuiCard>
                  </Col>
             </Row>
      ) : (
        <></>
      )}

      <Row gutter={[16, 16]} style={{ marginTop: '-20px' }}>
        {userInfo?.role !== 4 && (
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <MuiCard
              title={t("inventory_status_name")}
              style={{ marginTop: '5px', width: '100%', height: '100%' }}
            >
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                {t("inventory_status_name")}
              </div>
              {pieData && pieData.length > 0 ? (
                <div style={{ width: '400px', height: '400px', margin: '0 auto', padding: '20px' }}>
                  <ResponsivePie
                    data={pieData}
                    margin={{ top: 60, right: 70, bottom: 60, left: 60 }}
                    innerRadius={0.6}
                    padAngle={0.7}
                    cornerRadius={3}
                    colors={['#0077b6', '#0096c7', '#00b4d8', '#48cae4', '#90e0ef']}
                    borderWidth={1}
                    borderColor={{
                      from: 'color',
                      modifiers: [['darker', 0.5]],
                    }}
                    arcLinkLabelsSkipAngle={10} 
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsThickness={2} 
                    arcLinkLabelsColor={{
                      from: 'color',
                      modifiers: [['darker', 0.4]], 
                    }}
                    arcLabelsTextColor={{
                      from: 'color',
                      modifiers: [['darker', 5]],
                    }}
                    arcLinkLabelsOffset={-5}
                    arcLinkLabelsDiagonalLength={10}
                    arcLinkLabelsStraightLength={-5}             
                    legends={[
                      {
                        anchor: 'bottom', 
                        direction: 'row',
                        justify: false,
                        translateX: -5,
                        translateY: 56,
                        itemsSpacing: 10,
                        itemWidth: 75,
                        itemHeight: 10,
                        itemTextColor: '#999',
                        itemDirection: 'left-to-right',
                        symbolSize: 12,
                        symbolShape: 'circle',
                        effects: [
                          {
                            on: 'hover',
                            style: {
                              itemTextColor: '#000',
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </div>
              ) : (
                <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
              )}
            </MuiCard>
          </Col>
        )}

        {userInfo?.role !== 4 && (
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <MuiCard
              style={{ marginTop: '5px', width: '100%', height: '100%' }}
            >
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                {t("category")}
              </div>
              {shapeData && shapeData.length > 0 ? (
                <div style={{ width: '70%', margin: '0 auto', marginTop: '10px' }}>
                  <Column {...customShapeConfig} />
                </div>
              ) : (
                <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
              )}
            </MuiCard>
          </Col>
        )}
      </Row>


      <Row gutter={[16, 16]} style={{ marginTop: '45px' }}>
        {/* <Col span={12}>
          <MuiCard
            title={t("countingJobsNow")}
            style={{ marginTop: '-20px', width: '100%'}}
          >
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                {t("countingJobsNow")}
              </div>
            {activeCountings && activeCountings.length > 0 ? (
              <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
              {activeCountings.map((counting, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '200px',
                      marginRight: '15px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <strong><label>{t('Counting Job Name')}</label></strong>
                    <br></br>
                    <label>{counting.name}</label>
                    <div style={{ fontSize: '11px', color: '#666' }}>
                      <strong style={{ fontSize: '11px' }}>{`${counting.startDate} - ${counting.finishDate}`}</strong>
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    <ProgressBar
                      completed={counting.percent}
                      bgColor="#76c76b"
                      height="15px"
                      labelAlignment="outside"
                      labelColor="#666"
                      baseBgColor="grey"
                      borderRadius="4px"
                      />
                  </div>
                </div>
              ))}
            </div>
            ) : (
              <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
            )}
          </MuiCard>
        </Col> */}

        {/* <Col span={12}>
          <MuiCard
            title={t("futureCountingJobs")}
            style={{width: '100%',marginTop:'-20px' }}
          >
              <div
                style={{
                  fontSize: '18px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: '10px',
                }}
              >
                {t("futureCountingJobs")}
              </div>
            {futureCountings && futureCountings.length > 0 ? (
              <div style={{ width: '90%', margin: '0 auto', padding: '20px' }}>
                {futureCountings.map((counting, index) => (
                  <div
                    key={index}
                    style={{
                      marginBottom: '20px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        width: '200px',
                        marginRight: '15px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      <strong><label>{t('Counting Job Name')}</label></strong>
                      <br></br>
                      <label>{counting.name}</label>
                      <div style={{ fontSize: '11px', color: '#666' }}>
                        <strong style={{ fontSize: '11px' }}>{`${counting.startDate} - ${counting.finishDate}`}</strong>
                      </div>
                    </div>
                    <div style={{ flex: 1 }}>
                      <ProgressBar
                        completed={counting.percent}
                        bgColor="#76c76b"
                        height="15px"
                        labelAlignment="outside"
                        labelColor="#666"
                        baseBgColor="grey"
                        borderRadius="4px"
                        />
                      <div
                        style={{
                          fontSize: '11px',
                          color: '#666',
                          marginTop: '4px',
                        }}
                      >
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
            )}
          </MuiCard>
        </Col> */}
      </Row>
  
      {userInfo?.role !== 4 && (
        <Row gutter={[8,8]} style={{ marginTop: '-20px' }}>
          <MuiCard
            title={t('activeandwaitcount')}
            style={{ 
              marginTop: '-5px', 
              width: '99.5%',
              height: '100%', 
              margin: '0 auto',
            }}
          >
            <div
              style={{
                fontSize: '18px',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '10px',
              }}
            >
              {t("activeandwaitcount")}
            </div>
            <div style={{ 
              marginTop: '10px',
              marginBottom: '40px',
              marginLeft: '40px',
              marginRight: '40px',
            }}>
              {allCounting?.count !=0 ? (
                <AltisDataGrid
                  columns={countingColumns}
                  data={allCounting?.data}
                  total={allCounting?.count}
                  onChange={() => { }} 
                />
              ) : (
                <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
              )}
            </div>
          </MuiCard>
        </Row>
      )}


      <Row gutter={[16, 16]} style={{ display: 'flex', alignItems: 'stretch', marginTop: '20px' }}>
      {userInfo?.role !== 4 && (
  <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ marginBottom: '20px' }}>
    <MuiCard 
      title={t("assetbyCampus")} 
      style={{ 
        marginTop: '15px', 
        width: '100%', 
        height: '660px',
        margin: '0 auto' 
      }}
    >
      <div
        style={{
          fontSize: '18px',
          fontWeight: 'bold',
          textAlign: 'center',
          padding: '10px',
        }}
      >
        {t("assetbyCampus")}
      </div>
      <div 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          height: '100%' 
        }}
      >
        {assetData?.length > 0 ? (
          <div style={{ 
            width: '100%', 
            height: '100%', 
            maxWidth: '400px', 
            maxHeight: '400px', 
            margin: '0 auto' 
          }}>
            <ResponsivePie
              data={assetData.map(item => ({
                id: item.type, 
                label: item.type, 
                value: item.Varlık_Sayısı, 
              }))}
              margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
              innerRadius={0}
              padAngle={1}
              cornerRadius={3}
              colors={{ scheme: 'nivo' }} 
              borderWidth={1}
              borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
              arcLinkLabelsSkipAngle={10}
              arcLinkLabelsTextOffset={1} 
              arcLinkLabelsTextColor="#333333"
              arcLinkLabelsDiagonalLength={10}
              arcLinkLabelsStraightLength={6}
              arcLinkLabelsThickness={2}
              arcLinkLabelsColor={{ from: 'color' }}
              arcLabelsSkipAngle={10}
              arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
              legends={[{
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: -5,
                translateY: 56,
                itemsSpacing: 10,
                itemWidth: 60,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'top-to-bottom',
                symbolSize: 9,
                symbolShape: 'circle',
                effects: [{
                  on: 'hover',
                  style: {
                    itemTextColor: '#000',
                  },
                }],
              }]}
              theme={{
                legends: {
                  text: {
                    fontSize: 9, 
                  },
                },
              }}
            />
          </div>
        ) : (
          <p style={{ textAlign: 'center', marginTop: '20px' }}>{t("noData")}</p>
        )}
      </div>
    </MuiCard>
  </Col>
      )}


  {userInfo?.role !== 4 && (
    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
      <MuiCard 
        title={t("locationDetails")} 
        style={{ 
          marginTop: '-5px', 
          width: '100%',
          height: '660px', 
          margin: '0 auto',
        }}
      >
        <div
          style={{
            fontSize: '18px',
            fontWeight: 'bold',
            textAlign: 'center',
            padding: '10px',
          }}
        >
          {t("locationDetails")}
        </div>
        <div style={{ 
          marginTop: '10px',
          marginBottom: '40px',
          marginLeft: '40px',
          marginRight: '40px',
        }}>
        {assetLocationDetail?.data?.length !=0 ? (
          <AltisDataGrid
            columns={locationColumns}
            data={assetLocationDetail?.data} 
            total={assetLocationDetail?.count}
            onChange={() => {}}
          />
        ) : (
          <p style={{ textAlign: 'center', marginTop: '330px' }}>{t("noData")}</p>
        )}
        </div>
      </MuiCard>
    </Col>
  )}
      </Row>



      <Modal
        title={t('assetDetails')}
        open={isModalVisible}
        onCancel={handleModalClose}
        width={1000}
        footer={null}
      >
        <AltisDataGrid
          columns={assetModalColumns}
          data={selectedAssets?.data}
          total={selectedAssets?.count}
          onChange={(e) => selectedLocationId && getAssetsByLocationId(selectedLocationId, e)}
        />
      </Modal>
    </>
  );

};

export default Dashboard;
