import { DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, UnorderedListOutlined, UploadOutlined, InboxOutlined, LeftOutlined, RightOutlined, CloseOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Image, Modal, notification, Pagination, Popconfirm, Row, Select, SelectProps, Space, Spin, Tooltip, Upload, Input, Table, message, Collapse } from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useRef, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { IInventory } from '../../../interfaces/IItemData';
import { IPrinterData } from '../../../interfaces/IPrinterData';
import { ICompanyData } from '../../../interfaces/ICompanyData';
import { ITemplatesName } from '../../../interfaces/ITemplateName';
import form from 'antd/lib/form';
import i18n from '../../../i18n';
import { IInventoryStatusData } from '../../../interfaces/IInventoryStatusData';
import JSZip from 'jszip';
import * as XLSX from 'xlsx';
import { API_URL } from '../../../utils/Constants';
import { ScreenLockPortraitOutlined } from '@material-ui/icons';
import { BulkUpdateModal } from './ModalClass/BulkUpdateModal';
import { IFilterOptions } from '../../../interfaces/IFilterOptions';

const InventoryManagement: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [inventoryData, setInventoryData] = useState<IInventory>();
  const [templateData, setTemplateData] = useState<ITemplatesName[]>();
  const [printerData, setPrinterData] = useState<IPrinterData[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleExport, setIsModalVisibleExport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState([]);
  const [isModalImage, setIsModalImage] = useState(false);
  const [isModalMovement, setIsModalMovement] = useState(false);
  const imagesPerPage = 1
  const [currentPage, setCurrentPage] = useState(1);
  const [movementData, setMovementData] = useState(null);
  const [detailData, setDetailData] = useState(null);
  const [isModalDetail, setIsModalDetail] = useState(false);
  const [inventoryId, setInventoryId] = useState(null);
  const [isModalVisibleImport, setIsModalVisibleImport] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [reader, setReader] = useState<string[][]>([]);
  const [selectedDropdown, setSelectedDropdown] = useState<string[]>([]); 
  const [isModalVisibleBulk, setIsModalVisibleBulk] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState({
      category: [],
      status: [],
      campus: [],
      locations: [],
      brand: [],
      supplier: [],
      type: [],
      department: []
  });

  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ brand: [], department: [], status: [], locations: [], category: [], supplier: [], campus: [], type: [],countReport:[] });
 

  const [grids, setGrids] = useState([
    {
      data: [],
      count: 0,
      columns: [],
    },
  ]);

  const [correctValues, setCorrectValues] = useState<any[]>([
    {
      id : 0,
      columnName : "Kampüs Adı",
      columnCorrectName : "Kampüs Adı",
      selectedValue:"Kampüs Adı",
      columnKey: "campusName"
    },
    {
      id : 1,
      columnName : "Konum Adı",
      columnCorrectName : "Konum Adı",
      selectedValue:"Konum Adı",
      columnKey: "locationName"
    },
    {
      id : 2,
      columnName : "Departman Adı",
      columnCorrectName : "Bölüm Adı",
      selectedValue:"Departman Adı",
      columnKey: "departmentName"
    },
    {
      id : 3,
      columnName : "Varlık Kategorisi",
      columnCorrectName :  "Varlık Kategorisi",
      selectedValue:"Varlık Kategorisi",
      columnKey: "categoryName"
    },
    {
      id : 4,
      columnName : "Varlık Tipi",
      columnCorrectName :  "Varlık Tipi",
      selectedValue:"Varlık Tipi",
      columnKey: "typeName"
    },
    {
      id : 5,
      columnName : "Tedarikçi Adı",
      columnCorrectName :  "Tedarikçi Adı",
      selectedValue:"Tedarikçi Adı",
      columnKey: "supplierName"
    },
    {
      id : 6,
      columnName :"Durum",
      columnCorrectName :  "Durum",
      selectedValue:"Durum",
      columnKey:  "inventoryStatusName"
    },  
    {
      id : 7,
      columnName : "Marka",
      columnCorrectName :  "Marka",
      selectedValue:"Marka",
      columnKey: "brandName"
    },
    {
      id : 8,
      columnName :"Envanter Model Adı",
      columnCorrectName :  "Envanter Model Adı",
      selectedValue:"Envanter Model Adı",
      columnKey: "inventoryName"
    },
    {
      id : 9,
      columnName : "Seri Numarası",
      columnCorrectName :  "Seri Numarası",
      selectedValue:"Seri Numarası",
      columnKey: "serialNo",
      optional:true
    },
    {
      id : 10,
      columnName :"Referans Numarası",
      columnCorrectName :  "Referans Numarası",
      selectedValue:"Referans Numarası",
      columnKey: "referanceNo",
      optional:true
    },
  ])
  
  const dataGridRef = useRef<any>(null);
  // useEffect(() => {
  //     InventoryFilter();
  //     const intervalId = setInterval(() => {
  //         if (dataGridRef.current) {
  //             dataGridRef.current.clearData();
  //         }
  //     }, 1000);
  //     return () => clearInterval(intervalId);
  // }, [])



  const pageData: IPageData = {
    title: t('item-management'),
    loaded: true,
  };

  const showModalBulk = () => {
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if(distinctCompanyIds.length > 0){
    setIsModalVisibleBulk(true);
    }else if(distinctCompanyIds.length === 0){
      setIsModalVisibleBulk(false);
      toast.error(t('anyInventory'))
    }else{
      setIsModalVisibleBulk(false);
      toast.error(t('sameCompany'))
    }
  };

  const handleCancelBulk = () => {
    setIsModalVisibleBulk(false);
  };

  useEffect(()=>{
    InventoryFilter();
  },[selectedFilters])


  useEffect(() => {
    onSetPage(pageData);
    InventoryReportFilter();
  }, [i18n.language]);

  const paginatedImages = () => {
    if (!Array.isArray(imageSrc)) {
      return []; // Return an empty array if imageSrc is not an array
    }
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = Math.min(startIndex + imagesPerPage, imageSrc.length);
    return imageSrc.slice(startIndex, endIndex);
  };

  const totalPages = Math.ceil((imageSrc?.length || 0) / imagesPerPage);

  const generatePagination = () => {
    const pages = [];
    const showPages = 3; 

    if (totalPages <= showPages * 2 + 1) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      for (let i = 1; i <= showPages; i++) {
        pages.push(i);
      }
      if (currentPage > showPages + 2) {
        pages.push('...');
      }
      const startPage = Math.max(1, totalPages - showPages + 1);
      for (let i = startPage; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  const ImagePreview = async (id) => {
    HTTPService.GET(`/Inventory/pictureByInventory?inventoryId=${id}`).then(res => {
      if (res.status == 200) {

        setImageSrc(res.data);
        setIsModalImage(true);
      }
      else {
        console.error("asdas");
      }
    })
      .catch((error) => {
        console.error('Error fetching images:', error);
      })
  };


  //   const MovementPreview = async (id) => {
  //     if (typeof id !== 'number') {
  //         console.error('Invalid ID:', id);
  //         return;
  //     }
  //     try {
  //         const response = await HTTPService.POST(`/InventoryTracking/inventoryTracking?`);
  //         console.log('API Response:', response.data);
  //         if (!Array.isArray(response.data)) {
  //             console.error('Expected an array but received:', response.data);
  //             return;
  //         }
  //         setMovementData(response.data); 
  //         setIsModalMovement(true); 
  //     } catch (error) {
  //         toast.error(t('errorFetchingData'));
  //     }
  // };

  const MovementPreview = (e?) => {
    var inventoryIdTmp = e?.inventoryId;

    if (inventoryIdTmp === null || inventoryIdTmp === undefined) {
      inventoryIdTmp = inventoryId
    } else {
      setInventoryId(inventoryIdTmp)
    }

    const body = {
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      inventoryId: inventoryIdTmp,
      updatedDate: e?.updatedDate,
      inventoryTracking: e?.inventoryTracking,
      oldRecord: e?.oldRecord,
      newRecord: e?.newRecord
    }


    HTTPService.POST(`/InventoryTracking/InventoryTracking`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setMovementData(response.data);
        setIsModalMovement(true);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DetailPreview = async (id) => {
    if (typeof id !== 'number') {
      console.error('Invalid ID:', id);
      return;
    }
    try {
      const response = await HTTPService.GET(`/Inventory/inventoryDetails?id=${id}`);
      setDetailData(response.data);
      setIsModalDetail(true);
    } catch (error) {
      toast.error(t('errorFetchingData'));
    }
  };


  const PDFPriview = async (values) => {
    setIsLoading(true)
    try {
      const selectedPrintsDetails = inventoryData.data.filter(item => item.isSelected);
      const printInventoryBarcode = selectedPrintsDetails.map(item => item.barcode);

      const body = {
        templateId: values.selectTemplate,
        barcode: printInventoryBarcode
      };

      HTTPService.POSTblob(`/Inventory/pdfpreview`, body)
      .then((response) => {
        if(response.status === 200){

          toast.success(t('pdfSuccess'));
        }else if (response.status === 452){
          toast.error(t('licenceExpiredDate'))
        }
        else if (response.status === 453){
          toast.error(t('licenceNotfound'))
        }
        else {
          toast.error(t(response.data))
        }
      })
      .catch((err) => {
        toast.error(t('error'));
      });
    } catch (error) {
      console.error('Error in PDF preview:', error);
    }
    finally {
      setIsLoading(false);
      setIsModalVisibleExport(false);
    }
  };


  const AllTemplateName = (values) => {
    let companyIdToUse = values;
    HTTPService.GET(`/Template/allTemplateName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: ITemplatesName[] = response.data;
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        setTemplateData(tmp);
      })
      .catch((error) => {
        console.error('Şablon çekme hatası:', error);
      });
  };


  const AllPrinterName = (values) => {
    let companyIdToUse = values;
    HTTPService.GET(`/Printer/allPrinterName?companyId=${companyIdToUse}`)
      .then((response) => {
        if (!response.data) {
          return;
        }
        let tmp: IPrinterData[] = response.data;
        tmp.map((elem, index) => {
          elem['key'] = index;
        });
        setPrinterData(tmp);
      })
      .catch((error) => {
        console.error('Cihaz çekme hatası:', error);
      });
  };


  const handleSelectAll = (e) => {
    var details = { ...inventoryData };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
      const selectedIds = details.data.map((row) => row.inventoryId);
      setSelectedIds(selectedIds);
    } else {
      details.data.map((row) => (row.isSelected = false));
      setSelectedIds([]);
    }
    
    setInventoryData(details);
  };

  const handleRowSelect = (e, index) => {
    const selectedInventoryId = inventoryData.data[index].inventoryId;
    console.log('Selected Inventory ID:', selectedInventoryId);

    var newObject = { ...inventoryData };
    
    newObject.data[index].isSelected = e.target.checked;
    setInventoryData(newObject);

    if (e.target.checked) {
      setSelectedIds((prev) => [...prev, selectedInventoryId]); 
    } else {
      setSelectedIds((prev) => prev.filter((id) => id !== selectedInventoryId));
    }
  };


  const dropdownItemStatus: IDropdownItem[] = inventoryData?.statusData
    ? inventoryData.statusData.map((elem) => ({
      id: elem.inventoryStatusName,
      name: elem.inventoryStatusName,
    }))
    : [];

  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            inventoryData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>

      ),
    },
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'input'
    }] : []),
    { title: t('inventoryName'),titleAlign:'center',align:'left',isSorting:true, key: 'inventoryName', placeHolder: t('inventoryName'), inputType: 'input' },
    { title: t('barcode'),    isSorting:true, key: 'barcode', placeHolder: t('barcode'), inputType: 'input' },
    { title: t('item_type'),    isSorting:true, key: 'typeName', placeHolder: t('item_type'), inputType: 'input', width: 500 },
    { title: t('item_group'),   isSorting:true, key: 'categoryName', placeHolder: t('item_group'), inputType: 'input', width: 500 },
    { title: t('location'),   isSorting:true, key: 'locationName', placeHolder: t('location'), inputType: 'input' },
    { title: t('inventory_status_name')   ,isSorting:true, key: 'inventoryStatusName', placeHolder: t('inventory_status_name'), inputType: 'dropdown', dropdownItem: dropdownItemStatus, },
    { title: t('isPrinted'),    isSorting:true,key: 'isPrinted', placeHolder: t('isPrinted'), inputType: 'checkbox' },
    ...(userInfo.role === UserRole.Personel || userInfo.role === UserRole.Operator ? [{
      title: t('debbit'),    isSorting:true,key: 'debbit', placeHolder: t('debbit'), inputType: 'checkbox'
    }] : []),
    ...(userInfo.role === UserRole.Personel || userInfo.role === UserRole.Operator ? [{
      title: t('responsible'),   isSorting:true, key: 'responsible', placeHolder: t('responsible'), inputType: 'checkbox'
    }] : []),
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: 'operations',
      isSorting:true,
      width: 900,
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space>
            <Popconfirm
              title={t('are_you_sure_inventory')}
              onConfirm={() => DeleteInventory(record.inventoryId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')} placement="top">
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  style={{ display: userInfo.role === UserRole.Personel ? 'none' : 'inline-block', width: '60px', borderRadius: '70px' }}
                  onClick={(e) => e.stopPropagation()}
                />
              </Tooltip>
            </Popconfirm>
            <Tooltip title={t('update')} placement="top">
              <Button
                size='small'
                icon={<EditOutlined />}
                style={{ display: userInfo.role === UserRole.Personel ? 'none' : 'inline-block', width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  const inventoryId = record.inventoryId;
                  window.location.replace(`/admin/update-inventory/${inventoryId}`);
                }}
              />
            </Tooltip>
            <Tooltip title={t('preview')} placement="top">
              <Button
                size='small'
                icon={<EyeOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  ImagePreview(record.inventoryId);
                }}
              />
            </Tooltip>
            <Tooltip title={t('details')} placement="top">
              <Button
                size='small'
                icon={<UnorderedListOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  DetailPreview(record.inventoryId);
                }}
              />
            </Tooltip>
            <Tooltip title={t('movements')} placement="top">
              <Button
                size='small'
                icon={<HistoryOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  MovementPreview({ inventoryId: record.inventoryId })
                }}
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('Debit') },
    { id: 1, name: t('Responsible') },
    { id: 2, name: t('Campus') },
    { id: 3, name: t('Location') },
    { id: 4, name: t('InventoryStatus') },
    { id: 5, name: t('Department') }
  ];


  const columns1: IAltisDataGridColumns[] = [
    {
      title: t('date'),
      key: 'updatedDate',
      placeHolder: t('date'),
      inputType: 'date',
      render: (text: string) => {
        if (!text) return '';


        const [date, time] = text.split('T');
        const parts = date.split('-');
        const day = parts[2];
        const month = parts[1];
        const year = parts[0];
        const [hours, minutes] = time.split(':');

        return `${day}.${month}.${year} ${hours}:${minutes}`;;
      }
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('movementType')}</div>,
      key: t('inventoryTracking'),
      placeHolder: t('movementType'),
      inputType: 'multiplecheckbox',
      dropdownItem: dropdownItemType,
      width: 500,
      render: (value) => {
        return <div style={{ textAlign: 'center' }}>{t(value)}</div>;
      }
    },
    {
      title: t('oldRecord'),
      key: 'oldRecord',
      placeHolder: t('oldRecord'),
      inputType: 'input'
    },
    {
      title: t('newRecord'),
      key: 'newRecord',
      placeHolder: t('newRecord'),
      inputType: 'input'
    },
  ];

  const columns2: IAltisDataGridColumns[] = [
    {
      title: t('responsible'),
      key: 'inventoryResponsibleTBL',
      placeHolder: t('responsible'),
      inputType: 'operations',
      render: (record) => {

        const responsibleInfo = record[0];

        if (responsibleInfo && responsibleInfo.responsibleUserTBL) {
          const user = responsibleInfo.responsibleUserTBL;
          return `${user.firstName} ${user.lastName}`;
        }
        return t('noResponsible');
      }
    },
    {
      title: t('debit'),
      key: 'debtUser',
      placeHolder: t('debit'),
      inputType: 'operations',
      render: (record) => {

        const debitUser = record;

        if (debitUser) {
          return `${debitUser.firstName} ${debitUser.lastName}`;
        }
        return t('noDebit');
      }
    },
    {
      title: t('serial_no'),
      key: 'serialNo',
      placeHolder: t('serial_no'),
      inputType: 'input'
    },
    {
      title: t('Referance No'),
      key: 'referanceNo',
      placeHolder: t('Referance No'),
      inputType: 'input'
    },
    //  {
    //    title: t('debit'), 
    //    key: 'debtUserId', 
    //    placeHolder: t('debit'),
    //    inputType: 'operations',
    //    render: (record) => {
    //      console.log(record); 

    //      const responsibleInfo = record[1];     
    //      console.log(responsibleInfo);

    //      if (responsibleInfo && responsibleInfo.debtUserId) { 
    //          const user = responsibleInfo.debtUserId; 
    //          console.log(user)
    //          return `${user.firstName} ${user.lastName}`; 
    //      }
    //      return 'No responsible user found'; 
    //    }
    //  },
  ];

  const columns3: IAltisDataGridColumns[] = [
    {
      title: t('excel_validation_errors'),
      key: 'errors',
      width: 600,
      render: (errors, record) => (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
          {errors.map((error, index) => {
            if (error.invalid[0] === "" && error.correct.length === 0) return null;
            
            const validValues = error.correct.filter(value => value !== null);
            
            return (
              <div key={index} style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <Input
                    style={{ width: '250px' }}
                    defaultValue={error.invalid[0] || t('empty_value')}
                    onChange={(e) => handleValueCorrection(error.field, e.target.value, record.row)}
                  />
                  <Select
                    style={{ width: '300px' }}
                    placeholder={t('select_valid_value')} 
                    options={validValues.map(value => ({
                      label: value,
                      value: value
                    }))}
                    onChange={(value) => {
                      const input = document.querySelector(`input[data-field="${error.field}"][data-row="${record.row}"]`) as HTMLInputElement;
                      if (input) {
                        input.value = value;
                      }
                      handleValueCorrection(error.field, value, record.row);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ),
      placeHolder: '',
      inputType: 'operations'
    }
  ];

  const formatErrorData = (invalidRows) => {
    return invalidRows.map(row => ({
      key: row.row.toString(),
      row: row.row,
      errors: row.errors
    }));
  };

  const handleConfirm = () => {
    excelDownload();
    setVisible(false);
  };

  const handleCancelExcel = () => {
    setVisible(false);
  };

  const InventoryFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      barcode: e?.barcode,
      departmentName: e?.departmentName,
      inventoryName: e?.inventoryName,
      inventoryStatusName: e?.inventoryStatusName,
      typeName: e?.typeName,
      debtFirstName: e?.debtFirstName,
      debtLastName: e?.debtLastName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      itemName: e?.itemName,
      itemGroup: e?.itemGroup,
      locationName: e?.locationName,
      categoryName: e?.categoryName,
      debbit: e?.debbit,
      responsible: e?.responsible,
      isPrinted: e?.isPrinted,
      brand: selectedFilters?.brand,
      department:selectedFilters?.department,
      location: selectedFilters?.locations,
      campus: selectedFilters?.campus,
      status: selectedFilters?.status,
      category:selectedFilters?.category,
      supplier:selectedFilters?.supplier,
      type: selectedFilters?.type
    };
    
    HTTPService.POST(`/Inventory/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        //console.log(response.data)
        setInventoryData(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const clearFilters = () => {
    const initialFilters = {
      category: [],
      status: [],
      campus: [],
      locations: [],
      brand: [],
      supplier: [],
      type: [],
      department: []
    };
    
    setSelectedFilters(initialFilters);

   

  };

  const DeleteInventory = (id) => {
    HTTPService.DELETE(`/Inventory/deleteInventory?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
        InventoryFilter();
      } else {
        toast.error(t(response.data));
      }
    });
  };

  const handleDownload = () => {
    const currentLanguage =i18n.language;
    HTTPService.GETBlob(`/Inventory/inventoryExcelDownload?language=${currentLanguage}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
      } else if(response.status === 452){
        toast.error(t('licenceExpiredDate'))
        }
        else if (response.status === 453){
          toast.error(t('licenceNotfound'))
        }
      else {
        toast.error(t(response.data));
      }
    });
  };

  const excelDownload = () => {
    const selectedRows = inventoryData.data.filter(row => row.isSelected);
   // console.log(inventoryData.data)
    //console.log(selectedRows)
    if (selectedRows.length === 0) {
        toast.error(t('NoRows'));
        return;
    }


    const selectedData = selectedRows.map(row => ({
      inventoryName: row.inventoryName,
      barcode: row.barcode,
      typeName: row.typeName,
      categoryName: row.categoryName,
      locationName: row.locationName,
      departmentName: row.departmentName,
      supplierName: row.supplierName,
      brandName: row.brandName,
    }));

    //console.log(selectedData)

    HTTPService.POSTblobExcel('/Report/ExcelDownload', selectedData)
      .then((response) => {
        if(response.status === 200){
        toast.success(t('excelSuccess'));
        }else if (response.status === 452){
          toast.error(t('licenceExpiredDate'))
        }
        else if (response.status === 453){
          toast.error(t('licenceNotfound'))
        }
        else {
          toast.error(t(response.data))
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(t('error'));
      });
  };



  const PrintData = (values) => {
    setIsLoading(true); // Start loading

    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);
    const printInventoryBarcode = selectedInventory.map((item) => item.barcode);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    var body = {
      printerId: values.selectPrinter,
      companyId: distinctCompanyIds[0],
      templateId: values.selectTemplate,
      printsBarcode: printInventoryBarcode
    };


    HTTPService.POST(`/Inventory/PrintInventory`, body)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
        } else if(response.status === 452){
          toast.error(t(response.data))
        }else if(response.status === 453){
          toast.error(t(response.data))
        }
        else {
          toast.error(t('printError')); // api timeouta düşüyor geçici olarak response.data kaldırılmıştır.
        }
      })
      .catch((response) => {
        console.error(response);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
        setIsModalVisible(false); // Hide modal
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  }

  const handleCancelExport = () => {
    setIsModalVisibleExport(false);
  }

  const handleCancelImage = () => {
    setIsModalImage(false);
  }

  const handleCancelMovement = () => {
    setIsModalMovement(false);
  }

  const handleCancelDetail = () => {
    setIsModalDetail(false);
  }

  const handleCancelImport = () => {
    setIsModalVisibleImport(false);
  }

  const showModal = () => {
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);

    // const companyIds = selectedInventory.((item) => item.companyId);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if (distinctCompanyIds.length > 1) {
      setIsModalVisible(false);
      toast.error(t('sameCompany'))
    } else if (selectedInventory.length === 0) {
      setIsModalVisible(false);
      toast.error(t('anyInventory'))
    }
    else {
      setIsModalVisible(true);
    }
    AllTemplateName(distinctCompanyIds[0]);
    AllPrinterName(distinctCompanyIds[0]);
  };

  const showModalExport = () => {
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);

    // const companyIds = selectedInventory.((item) => item.companyId);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if (distinctCompanyIds.length > 1) {
      setIsModalVisibleExport(false);
      toast.error(t('sameCompany'))
    } else if (selectedInventory.length === 0) {
      setIsModalVisibleExport(false);
      toast.error(t('anyInventory'))
    }
    else {
      setIsModalVisibleExport(true);
    }
    AllTemplateName(distinctCompanyIds[0]);
  };

  const showModalImport = () => {
    setIsModalVisibleImport(true);
  };

  // const handleDropdownChange = (value, rowIndex, columnName) => {
  //   const updatedData = [...grids.data];
  //   updatedData[rowIndex][columnName] = value;
  //   setGrids((prevGrids) => ({
  //     ...prevGrids,
  //     data: updatedData,
  //   }));
  // };

  const handleDropdownChange = (value, id) => {
    if (value === 'Seçiniz') {
      return; 
    }
    //console.log(value)
  
    const selectedValues = correctValues.map((item) => item.selectedValue);
    //console.log(selectedValues)

    if (selectedValues.includes(value)) {
      toast.error(t('excelDropdownError'));
      return; 
    }
  
    setSelectedDropdown(prev => {
      return [...prev, value];
    });

  
    const newObj = [];
    correctValues.forEach((item) => {
      if (item.id === id) {
        item.selectedValue = value; 
      }
      newObj.push({ ...item });
    });
  
    setCorrectValues(newObj);

    // setCorrectValues((prevCorrectValues) => {
    //   return prevCorrectValues.map((item) => 
    //     item.id === id ? { ...item, selectedValue: value } : item
    //   );
    // });
    
    //console.log(correctValues)
  };
  
  //console.log(selectedDropdown)



  const sendToAPI = (e) => {
    
    replaceExcel(); 
  
  
    const jsonData = reader.slice(1).map(row => {
      const jsonObject: { [key: string]: any } = {};
  
      reader[0].forEach((header, index) => {
        jsonObject[header] = row[index];
      });
    
      //console.log(jsonObject);
      return jsonObject;
    });
  
    //console.log(jsonData);
  
    const isMatch = jsonData.every(item => 
      correctValues.every(a => {
        if (a.optional) return true; 
        
        return a.columnKey in item;
      })
    );
    
    // console.log(isMatch);  
  
    if (isMatch) {
      HTTPService.POST(`/Inventory/multipleInventories?companyId=${userInfo?.companyId}`, jsonData)
        .then((response) => {
          if (response.status === 200) {
            setIsModalVisibleImport(false)
            InventoryFilter();
          } else {
            toast.error(t(response.data));
          }
        })
        .catch((error) => {
          console.error("API request failed:", error);
          toast.error("API request failed");
        });
    } else {
      notification.error({
        message: t('excelColumnMismatchError'),
        description: t('excelColumnMismatchError'),
      });
    }
  };
  
  

  const handleExcelUpload = (info) => {
    const { status, originFileObj } = info.file;
  
    if (status === 'error') {
      console.error('Upload failed');
      return;
    }
  
    try {
      if (originFileObj) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
  
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
  
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }) as string[][];

          setReader(jsonData);

          // console.log(reader)

          if (jsonData.length > 0) {

            const data = correctValues.map((row, index) => ({
              key: index,
              excelVerisi: row.columnName,
              dogruDeger: row.columnName,
            }));
            
            const firstRow = jsonData[0];
  
            const columnHeaders = ["Doğru Değer","Excel Verisi"];

            const columns = columnHeaders.map((header, index) => ({
              title: header,
              key: header,
              render: (text, record, rowIndex) => {
                // console.log(record)
                if (header === "Excel Verisi") {
                  const matchedValue = firstRow.find((value) => value.toLowerCase() === record.dogruDeger.toLowerCase());
                  const defaultValue = matchedValue != null ? matchedValue  :  "Seçiniz";
                  if (!selectedDropdown.includes(defaultValue)) {
                    setSelectedDropdown((prev) => [defaultValue]);
                  }
                  // console.log(selectedDropdown);
                  return (
                    <Select
                      defaultValue={defaultValue}
                      style={{ width: '100%' }}
                      onChange={(value) => handleDropdownChange(value || defaultValue, record.key)}
                    >
                      {firstRow.map((option, index) => (
                        <Select.Option key={index} value={option}>
                          {option}
                        </Select.Option>
                      ))}
                    </Select>
                  );
                } else {
                  return (
                    <Input
                    defaultValue={correctValues[rowIndex].columnName} 
                    style={{ width: '100%' }}
                    readOnly
                    />
                  );
                }
              },
            }));
  
            
            setGrids([
              {
                data: data,
                count: jsonData.length - 1,
                columns: columns,
              },
            ]);
  
          } else {
            console.error('Excel file is empty');
          }
        };
  
        reader.onerror = () => {
          console.error('Error reading the file');
        };
  
        reader.readAsBinaryString(originFileObj);
      }
    } catch (error) {
      console.error('Error processing Excel file:', error);
    }
  };
  
  const replaceExcel = () => {
    const currentHeaders = reader[0];  // İlk satırdaki başlıkları al
  
    // Header'ları, correctValues'taki columnKey ile eşleştir
    const updatedHeaders = currentHeaders?.map(header => {
      // header'ı correctValues içindeki selectedValue ile eşleştirip columnKey'i al
      const matchingItem = correctValues.find(item => item.selectedValue === header);
      return matchingItem ? matchingItem.columnKey : header;  // Eşleşme varsa columnKey, yoksa header'ı bırak
    });
  
    // updatedHeaders ile reader[0]'ı güncelle
    reader[0] = updatedHeaders;
  
    // JSON objelerini oluştur
    const jsonData = reader.slice(1).map(row => {
      const jsonObject: { [key: string]: any } = {};
  
      // Satırdaki her değeri updatedHeaders ile eşleştirip jsonObject'e ekle
      row.forEach((value, index) => {
        const columnKey = updatedHeaders[index];
        jsonObject[columnKey] = value;
      });
  
      // correctValues içindeki columnKey'lere göre eksik alanları doldur
      correctValues.forEach((item) => {
        const columnKey = item.columnKey;
        // Eğer jsonObject'te o key yoksa veya değeri boşsa, varsayılan değerini ata
        if (!jsonObject.hasOwnProperty(columnKey) || jsonObject[columnKey] === "") {
          jsonObject[columnKey] = item.columnName || "Seçiniz";  // "Seçiniz" varsayılan değeri
        }
      });
  
      // Güncellenmiş jsonObject'i döndür
      return jsonObject;
    });
  
    // console.log('Updated JSON Data:', jsonData);
    setReader(reader);  // reader'ı güncelle
  };
  
  const handleValueCorrection = (field: string, newValue: string, rowNumber: number) => {
    // console.log(`Row ${rowNumber}, Field ${field} corrected to: ${newValue}`);
  };

  const handleDelete = () => {  
    const selectedInventory = inventoryData.data.filter((item) => item.isSelected === true);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));

    if(distinctCompanyIds.length > 0){

    HTTPService.DELETE(`/Inventory/multipleDeleteInventories`,selectedIds).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        InventoryFilter();
      } else {
        toast.error(t(response.data));
      }
    });
  }else if(distinctCompanyIds.length === 0){
    toast.error(t('anyInventory'))
  }
  else{
    console.log("Please select a company")
  }
};


const handleMenuClick = (key) => {
  if (activeKey.includes(key)) {
    setActiveKey([]);
    setIsCollapseOpen(false);
  } else {
    setActiveKey([key]);
    setIsCollapseOpen(true);
  }
};


const handleCollapseChange = (keys) => {
  const updatedKey = Array.isArray(keys) ? keys[keys.length - 1] : keys;
  setActiveKey(updatedKey ? [updatedKey] : []);
};

const InventoryReportFilter = (e?) => {
  const body = {
    category: e?.category,
    campus: e?.campus,
    type: e?.type
  };

  HTTPService.POST(`Report/postfilters`, body).then((response) => {
    
    if (response.status === 200) {
      console.log(response.data)
      setFilterOptions(response.data);
    } else if (response.status === 400){
      toast.error(t(response.data));
    }
    else {
      toast.error(t(response.data));
    }
  });
};

const handleFilterGroupChange = (filterKey, values) => {
  setSelectedFilters((prevFilters) => ({
    ...prevFilters,
    [filterKey]: values,
  }));
};


const handleFilterChange = (filterType: keyof typeof selectedFilters, id: number) => {
  const newSelectedFilters = {
    ...selectedFilters,
    [filterType]: selectedFilters[filterType].includes(id)
      ? selectedFilters[filterType].filter(item => item !== id)
      : [...selectedFilters[filterType], id]
  };

  setSelectedFilters(newSelectedFilters);

  if (filterType === 'category') {
    InventoryReportFilter({ category: newSelectedFilters.category });
  }

  if (filterType === 'campus') {
    InventoryReportFilter({ campus: newSelectedFilters.campus });
  }

};

  return (
    <>

      <Row gutter={16} align="middle" style={{ marginBottom: '20px' }}>

        <Col
          xs={24}
          sm={userInfo?.role === UserRole.AltisAdmin ? 8 : 12}
          md={userInfo?.role === UserRole.AltisAdmin ? 8 : 12}
          lg={userInfo?.role === UserRole.AltisAdmin ? 6 : 8}
          xl={userInfo?.role === UserRole.AltisAdmin ? 6 : 8}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'nowrap' }}>
            <Button
              type='primary'
              shape='round'
              size='middle'
              onClick={showModal}
              hidden={userInfo.role === UserRole.Personel}
              style={{
                flex: '1 1 auto',
                minWidth: '120px',
                marginRight: '8px',
                borderRadius:'5px'
              }}
            >
              {t('print')}
            </Button>

            <Button
              type='primary'
              shape='round'
              size='middle'
              onClick={showModalExport}
              hidden={userInfo.role === UserRole.Personel}
              style={{
                flex: '1 1 auto',
                minWidth: '120px',
                marginRight: '8px',
                borderRadius:'5px'
              }}
            >
              {t('pdf_label')}
            </Button>


            <Button
              type='primary'
              shape='round'
              size='middle'
              onClick={showModalImport}
              hidden={userInfo.role === UserRole.Personel}
              style={{
                flex: '1 1 auto',
                minWidth: '120px',
                marginRight: '8px',
                borderRadius:'5px'
              }}
            >
              {t('excel_file_asset')}
            </Button>

            <Popconfirm
              title={`${selectedIds.length} ${t('excel_download_message')}`}
              visible={visible}
              onConfirm={handleConfirm}
              onCancel={handleCancelExcel}
              okText="Evet"
              cancelText="Hayır"
            >
              <Button
                type='primary'
                shape='round'
                style={{
                  height: 40,
                  marginRight: '8px',
                  flex: '1 1 auto',
                  minWidth: '120px',
                  borderRadius: '5px'
                }}
                size='middle'
                onClick={() => setVisible(true)}
                hidden={userInfo.role === UserRole.Personel}
              >
                {t('report_export')}
              </Button>
            </Popconfirm>


            <Button
              type='primary'
              shape='round'
              style={{
                height: 40,
                marginRight: '8px',
                flex: '1 1 auto',
                minWidth: '120px',
                borderRadius: '10px',
              }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-inventory')}
              hidden={userInfo.role === UserRole.Personel}
            >
              {t('add_new_item')}
            </Button>

            <Button
              type='primary'
              shape='round'
              style={{
                height: 40,
                borderRadius: '10px',
                marginRight: '8px',
                flex: '1 1 auto',
                minWidth: '120px',
              }}
              size='middle'
              onClick={() => window.location.replace('/admin/add-inventory-quick')}
              hidden={userInfo.role === UserRole.Personel}
            >
              {t('add_new_item_speed')}
            </Button>
                <BulkUpdateModal
                isModalVisibleBulk={isModalVisibleBulk}
                handleCancelBulk={handleCancelBulk}
                inventoryId={selectedIds}                
                t={t}
                />
          </div>

        </Col>
      </Row>

      <Modal
        title={t('Template Printing')}
        visible={isModalVisible}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <div style={{ padding: '20px' }}>
          <Spin spinning={isLoading}>
            <Form onFinish={PrintData}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_template')}
                    name='selectTemplate'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_template'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }} // Adjusted margin
                  >
                    <Select
                      key='selectTemplateUpload'
                      style={{ width: '100%' }} // Ensure full width
                    >
                      {templateData?.map((item) => (
                        <Select.Option key={item.templateId} value={item.templateId}>
                          {item.templateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_printer')}
                    name='selectPrinter'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_printer'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }} 
                  >
                    <Select
                      key='selectPrinterUpload'
                      className='custom-select'
                      style={{ width: '100%' }}
                    >
                      {printerData?.map((item) => (
                        <Select.Option key={item.printerId} value={item.printerId}>
                          {item.printerName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='primary'
                    htmlType="submit"
                    shape='round'
                    size='middle'
                    style={{ width: 'auto',borderRadius: '10px', }}
                  >
                    {t('print')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>


      <Modal
        title={t('PDF Export')}
        visible={isModalVisibleExport}
        onCancel={handleCancelExport}
        width={800}
        footer={null}
      >
        <div style={{ padding: '20px' }}>
          <Spin spinning={isLoading}>
            <Form onFinish={PDFPriview}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Form.Item
                    label={t('select_template')}
                    name='selectTemplate'
                    rules={[
                      {
                        required: true,
                        message: t('please_select_template'),
                      },
                    ]}
                    style={{ marginBottom: '16px' }}
                  >
                    <Select
                      key='selectTemplateUpload'
                      style={{ width: '100%' }}
                    >
                      {templateData?.map((item) => (
                        <Select.Option key={item.templateId} value={item.templateId}>
                          {item.templateName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type='primary'
                    htmlType="submit"
                    shape='round'
                    size='middle'
                    style={{ width: 'auto',borderRadius: '10px' }}
                  >
                    {t('PDF Export')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>


      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('image_preview')}</span>}
        visible={isModalImage}
        onCancel={handleCancelImage}
        footer={null}
      >
        {imageSrc.length > 0 ? (
          <><div style={{ textAlign: 'center' }}>
            {paginatedImages().map((image, index) => (
              <Image
                key={image.pictureGuid}
                src={`${API_URL}/images/${image.pictureGuid}`}
                alt={`Resim ${index}`}
                width={350}
                height={350}
                preview={{
                  src: `${API_URL}/images/${image.pictureGuid}`,
                }}
                style={{ borderRadius: '4px' }}
              />
            ))}
          </div>

            {totalPages > 1 && (
              <Pagination
                current={currentPage}
                pageSize={imagesPerPage}
                total={imageSrc.length}
                onChange={page => setCurrentPage(page)}
                style={{ textAlign: 'center', marginTop: '16px' }}
                itemRender={(page, type, originalElement) => {
                  if (type === 'page') {
                    const pages = generatePagination();
                    if (pages.includes(page) || page.toString() === '...') {
                      return <span style={{ padding: '0 8px', cursor: 'pointer', fontWeight: currentPage === page ? 'bold' : 'normal' }}>{page}</span>;
                    }
                    return originalElement;
                  }
                  return originalElement;
                }}
              />
            )}
          </>
        ) : (
          <p style={{ textAlign: 'center' }}>{t('noImage')}</p>
        )}
      </Modal>

      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('details')}</span>}
        visible={isModalDetail}
        onCancel={handleCancelDetail}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          {detailData ? (
            <>
              <AltisDataGrid
                data={detailData}
                total={detailData?.count}
                columns={columns2}
                onChange={DetailPreview}
              />
            </>
          ) : (
            <p>{t('loading')}</p>
          )}
        </div>
      </Modal>

      <Modal
        title={<span style={{ fontWeight: 'bold' }}>{t('movementTrace')}</span>}
        visible={isModalMovement}
        onCancel={handleCancelMovement}
        footer={null}
      >
        <div style={{ textAlign: 'center' }}>
          {movementData ? (
            <>
              <AltisDataGrid
                data={movementData?.data}
                total={movementData?.count}
                columns={columns1}
                onChange={(e) => {
                  MovementPreview({
                    ...e,
                    inventoryId: inventoryId,
                  });
                }}
              />
            </>
          ) : (
            <p>{t('loading')}</p>
          )}
        </div>
      </Modal>

      <Modal
        title={t('excel_file_upload')}
        visible={isModalVisibleImport}
        onCancel={handleCancelImport}
        width={800}
        footer={null}
      >
        <div style={{ padding: '20px' }}>
          <Spin spinning={isLoading}>
            <Form>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24}>
                <Button
                      type='primary'
                      htmlType="submit"
                      shape='round'
                      size='middle'
                      onClick={handleDownload}
                      style={{ width: '100%',marginTop:'-45px',borderRadius: '10px'}}
                    >
                      {t('example_template_download')}
                  </Button>
                  <Upload.Dragger
                  name= "file"
                  multiple={false}
                  accept=".xlsx,.xls"
                  onChange={(info) => {
                    const { status } = info.file;
                    if(status === 'done'){ 
                      handleExcelUpload(info);
                      notification.warning({
                        message: t('file_required_value'),
                        description: t('file_upload_description'),
                      });

                    }
                    else if(status === 'uploading'){ 

                    }
                  }}
                  customRequest= {({ onSuccess })=> {
                    onSuccess("ok");
                    return true;
                  }}
                >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">{t('click_or_drag_file')}</p>
                    <p className="ant-upload-hint">{t('excel_file_upload')}</p>
                  </Upload.Dragger>
                  </Col>


                <Col xs={24} style={{ marginTop: '55px', height: '300px' }}>
                  <div 
                    style={{ 
                      border: '1px solid black',
                      borderRadius: '8px',
                      padding: '16px',
                      backgroundColor: 'white',
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                  >
                    <div style={{ flex: 1, overflowY: 'auto', marginBottom: '16px' }}>
                    <Table
                        dataSource={grids[0]?.data}  // Veriler
                        columns={grids[0]?.columns}  // Kolonlar
                        pagination={false}  // Sayfalama kullanmıyoruz
                        rowKey="key"  // Satırlara key eklememiz gerekebilir
                      />
                    </div>
                  </div>
                </Col>

                <Col xs={24} style={{ marginTop: '20px', textAlign: 'right' }}>
                  <Button
                      type='primary'
                      htmlType="submit"
                      shape='round'
                      size='middle'
                      style={{ width: 'auto',marginLeft:'10px',borderRadius: '10px'}}
                      onClick={sendToAPI}
                    >
                      {t('excel_allow')}
                    </Button>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </Modal>


      <Col xs={24} sm={10} md={24} lg={24} xl={24} style={{ paddingLeft: '8px' }}>
        <div style={{ display: 'flex', gap: '20px',overflowX: 'auto', paddingBottom: '20px', width: '100%'}}>
          <div style={{ marginBottom: '20px', minWidth: '250px' }}>
            <Form.Item>
              <strong><span>{t('category')} ({selectedFilters.category.length})</span></strong>
              <div className="checkbox-group-container" style={{flex: '1 1 250px'}}>
                <Checkbox.Group
                  value={selectedFilters.category}
                  onChange={(values) => {
                    handleFilterGroupChange('category', values);
                  }}
                >
                  <div>
                    {filterOptions.category.sort((a, b) => a.categoryName.localeCompare(b.categoryName)).map((option) => (
                      <div key={option.categoryId}>
                        <Checkbox value={option.categoryId}>{option.categoryName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('item_type')} ({selectedFilters.type.length})</span></strong>
              <div className="checkbox-group-container"
               style={{
                width: '250px', 
                height: '200px',
                overflowY: 'auto', 
                border: '1px solid #ddd',
                padding: '10px',
                boxSizing: 'border-box',
                flex: '1 1 250px'
              }}>
                <Checkbox.Group
                  value={selectedFilters.type}
                  onChange={(values) => handleFilterGroupChange('type', values)}
                >
                  <div>
                    {filterOptions.type.sort((a, b) => a.typeName.localeCompare(b.typeName))
                      .filter((option) => selectedFilters.category.length === 0 || selectedFilters.category.includes(option.categoryId))
                      .map((option) => (
                        <div key={option.typeId}>
                          <Checkbox value={option.typeId}>{option.typeName}</Checkbox>
                        </div>
                      ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Inventory Status Panel */}
          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('InventoryStatus')} ({selectedFilters.status.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <div>
                </div>

                <Checkbox.Group
                  value={selectedFilters.status}
                  onChange={(values) => handleFilterGroupChange('status', values)}
                >
                  <div>
                    {filterOptions.status.sort((a, b) => a.inventoryStatusName.localeCompare(b.inventoryStatusName)).map((option) => (
                      <div key={option.inventoryStatusId}>
                        <Checkbox value={option.inventoryStatusId}>{option.inventoryStatusName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Campus Panel */}
          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('campus')} ({selectedFilters.campus.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <Checkbox.Group
                  value={selectedFilters.campus}
                  onChange={(values) => handleFilterGroupChange('campus', values)}
                >
                  <div>
                    {filterOptions.campus.sort((a, b) => a.campusName.localeCompare(b.campusName)).map((option) => (
                      <div key={option.campusId}>
                        <Checkbox value={option.campusId}>{option.campusName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Location Panel */}
          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('location')} ({selectedFilters.locations.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <div>
                </div>

                <Checkbox.Group
                  value={selectedFilters.locations}
                  onChange={(values) => handleFilterGroupChange('locations', values)}
                >
                  <div>
                    {filterOptions.locations.sort((a, b) => a.locationName.localeCompare(b.locationName))
                      .filter((option) => !selectedFilters.campus.length || selectedFilters.campus.includes(option.campusId))
                      .map((option) => (
                        <div key={option.locationId}>
                          <Checkbox value={option.locationId}>{option.locationName}</Checkbox>
                        </div>
                      ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Department Panel */}
          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('Department')} ({selectedFilters.department.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <div>
                </div>

                <Checkbox.Group
                  value={selectedFilters.department}
                  onChange={(values) => handleFilterGroupChange('department', values)}
                >
                  <div>
                    {filterOptions.department.sort((a, b) => a.departmentName.localeCompare(b.departmentName)).map((option) => (
                      <div key={option.departmentId}>
                        <Checkbox value={option.departmentId}>{option.departmentName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Brand Panel */}
          <div style={{ marginBottom: '20px', minWidth: '250px'}}>
            <Form.Item>
            <strong><span>{t('brand')} ({selectedFilters.brand.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <div>
                </div>

                <Checkbox.Group
                  value={selectedFilters.brand}
                  onChange={(values) => handleFilterGroupChange('brand', values)}
                >
                  <div>
                    {filterOptions.brand.sort((a, b) => a.brandName.localeCompare(b.brandName)).map((option) => (
                      <div key={option.brandId}>
                        <Checkbox value={option.brandId}>{option.brandName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>

          {/* Supplier Panel */}
          <div style={{ marginBottom: '20px', minWidth: '200px'}}>
            <Form.Item>
            <strong><span>{t('supplier')} ({selectedFilters.supplier.length})</span></strong>
              <div className="checkbox-group-container"
                style={{
                  width: '250px', 
                  height: '200px',
                  overflowY: 'auto', 
                  border: '1px solid #ddd',
                  padding: '10px',
                  boxSizing: 'border-box',
                  flex: '1 1 250px'
                }}>
                <div>
                </div>

                <Checkbox.Group
                  value={selectedFilters.supplier}
                  onChange={(values) => handleFilterGroupChange('supplier', values)}
                >
                  <div>
                    {filterOptions.supplier.sort((a, b) => a.supplierName.localeCompare(b.supplierName)).map((option) => (
                      <div key={option.supplierId}>
                        <Checkbox value={option.supplierId}>{option.supplierName}</Checkbox>
                      </div>
                    ))}
                  </div>
                </Checkbox.Group>
              </div>
            </Form.Item>
          </div>
        </div>
      </Col>

      <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '15px',flexWrap: 'wrap', }}>
                    
        <Button
          type="primary"
          style={{
            width: '150px',
            height: '40px',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '10px',
            padding: '0 15px',
            borderRadius: '5px',
            
          }}
          onClick={clearFilters}
        >
          <CloseOutlined style={{marginTop:'5px'}}/>
          <span>{t('noFilter')}</span>
        </Button>

        <Button
          type="primary"
          shape="round"
          onClick={showModalBulk}
          style={{
            width: '150px',
            height: '40px',
            border: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '10px',
            padding: '0 15px',
            borderRadius: '5px',
          }}
          size="middle"
          hidden={userInfo.role === UserRole.Personel}
          >
          {t('bulk_update')}
          </Button>

            
          <Popconfirm
                title={t('bulk_delete_message')}
                onConfirm={() => handleDelete()} 
                okText="Evet"
                cancelText="Hayır"
            >
                <Button
                    type="primary"
                    shape="round"
                    style={{
                      width: '150px',
                      height: '40px',
                      border: 'none',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: '10px',
                      padding: '0 15px',
                      borderRadius: '5px',
                    }}
                    size="middle"
                    hidden={userInfo.role === UserRole.Personel}
                >
                    {t('bulk_delete')}
                </Button>
            </Popconfirm>

      </div>

 

      <Row style={{ marginTop: '15px' }}>
        <Col span={24}>
          <AltisDataGrid
            ref={dataGridRef}
            data={inventoryData?.data}
            total={inventoryData?.count}
            columns={columns}
            onChange={InventoryFilter}
          />
        </Col>
      </Row>
    </>
  );
};

export default InventoryManagement;