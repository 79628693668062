import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, Row, Select } from 'antd';
import HTTPService from '../../utils/makerequest';
import { LockOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { IPageProps } from '../../interfaces/page-data';
import toast from 'react-hot-toast';
import { StateLoader } from '../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../interfaces/authentication';
import { ICompany } from '../../interfaces/ICompany';
import { useTranslation } from 'react-i18next';


const AddUser: React.FunctionComponent<IPageProps> = () => {
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error('Failed to fetch company data', error);
    }
  };

  const Register = (value) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = value.selectedCompany;
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newUser = {
      companyId: companyIdToUse,
      userEmail: value.userEmail,
      firstName: value.firstName,
      lastName: value.lastName,
      role: value.role,
      personelStatus: value.personelStatus,
    };
    HTTPService.POST(`/User/registerUser`, newUser)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.replace('/admin/user-settings');
        } else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        console.error('User registration failed: ', error);
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('register_user')}</h3>
      <Form layout='vertical' onFinish={Register}>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='selectedCompany'
            label={t('Select company')}
            rules={[{ required: true, message: t('please_select_company') }]}>
            <Select showSearch placeholder={t('company_name')}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          name='userEmail'
          label={t('email')}
          rules={[
            { required: true, message: t('please_input_email') },
            { pattern: /^[^\s@]+@[^\s@]+$/, message: t('email_invalid') },
          ]}>
          <Input prefix={<MailOutlined />} placeholder={t('email')} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='firstName'
          label={t('firstName')}
          rules={[
            { required: true, message: t('please_input_firstName') },
            {
              pattern: /^(?!\s)(?!.*\s$).+$/,
              message: t('no_leading_or_trailing_spaces'),
            }
          ]}>
          <Input prefix={<UserOutlined />} placeholder={t('firstName')} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='lastName'
          label={t('lastName')}
          rules={[
            { required: true, message: t('please_input_lastName') },
            {
              pattern: /^(?!\s)(?!.*\s$).+$/,
              message: t('no_leading_or_trailing_spaces'),
            }
          ]}>
          <Input prefix={<UserOutlined />} placeholder={t('lastName')} style={{ borderRadius: 0 }} />
        </Form.Item>
        <Form.Item
          name='role'
          label={t('role')}
          rules={[{ required: true, message: t('please_select_role') }]}>
          <Select showSearch placeholder={t('role')}>
            {userInfo?.role === UserRole.AltisAdmin && (
              <Select.Option value={4}>{t('AltisAdmin')}</Select.Option>
            )}
            <Select.Option value={0}>{t('Sayman')}</Select.Option>
            <Select.Option value={1}>{t('Personel')}</Select.Option>
            <Select.Option value={2}>{t('Operator')}</Select.Option>
            <Select.Option value={3}>{t('Admin')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='personelStatus'
          label={t('Personel Status')}
          rules={[{ required: true, message: t('please_select_personel_status') }]}>
          <Select showSearch placeholder={t('Personel Status')}>

            <Select.Option value={0}>{t('Active_Personnel')}</Select.Option>
            <Select.Option value={1}>{t('Allowed_Personnel')}</Select.Option>
            <Select.Option value={2}>{t('Resigner_Personnel')}</Select.Option>
            <Select.Option value={3}>{t('Dismissed_Personnel')}</Select.Option>
            <Select.Option value={4}>{t('Retired_Personnel')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: '5px' }}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddUser;
