import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Select, Tooltip } from "antd";
import { CloudServerOutlined, HomeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { ILicenceData } from "../../../interfaces/ILicenceData";
import { get } from "http";
import moment from 'moment';
import { userInfo } from "os";
import { UserRole } from "../../../interfaces/authentication";
import { styleText } from "util";

const AddCustomer: React.FunctionComponent<IPageProps> = () => {
  const { Option } = Select;
  const { t } = useTranslation();

  const [licenceData, setLicenceData] = useState<ILicenceData[]>();
  const [platformData,setPlatformData] = useState<any>();
  const [isChecked, setIsChecked] = useState(false);

  // useEffect(() => {
  //   GetLicence();
  // })

  useEffect(()=>{
    GetPlatform();
  })

  const cities = [
    'Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Amasya', 'Ankara', 'Antalya',
    'Artvin', 'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu',
    'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır',
    'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun',
    'Gümüşhane', 'Hakkâri', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
    'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya',
    'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş',
    'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop',
    'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
    'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale',
    'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük',
    'Kilis', 'Osmaniye', 'Düzce'
  ];

  const CompanyRegister = (values) => {
    const newGroup = {
      companyName: values.companyName,
      companyAddress: values.companyAddress, 
      companyCity:values.companyCity,
      packageId: values.selectLicence,
      licenceStartDate:values.licencestartDate,
      licenceEndDate:values.licencefinishDate,
      isLocal:values.isLocal,
      baseUrl:values.baseUrl,
      responsibleUserTBL: {
        userEmail: values.responsibleEmail,
        userPassword: values.responsibleUserPassword, 
        firstName: values.responsibleUserFirstname,
        lastName: values.responsibleUserLastname,
        telephone:values.responsibleTelephone,
      }
    };

    //console.log(newGroup)
    

    HTTPService.POST(`/Company/registerCompany`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-customer';
          if (currentUrl.includes(redirectUrl)) {
              window.location.replace('admin/customer-management');
          }
        }else if(response.status ===450){
          toast.error(t(response.data))
        }
         else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        toast.error(t('register_failure'));
      });
  };

  const GetLicence = () => {   
    HTTPService.GET(`/LicencePackages/AllLicence`)
      .then((response) => {
        if (response.status === 200) {
       
          setLicenceData(response.data);
        } else {
          toast.error(t(response.data)); 
        }
      })
      .catch((error) => {
        toast.error(t('register_failure'));
      });
  };

  const GetPlatform = () =>{
    HTTPService.GET(`/Other/getPlatform`)
    .then((response) => {
      if (response.status === 200) {
     
        setPlatformData(response.data);
      } else {
        toast.error(t(response.data)); 
      }
    })
    .catch((error) => {
      toast.error(t('register_failure'));
    });
  }

  const handleCancel = () => {
    window.location.replace('/admin/customer-management/');
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('register_customer')}</h3>
      <Form onFinish={CompanyRegister} layout='vertical'>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name='companyName'
              label={t('company_name')}
              rules={[{ required: true, message: t('please_input', { field: t('company_name') }), whitespace: true }]}
            >
              <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder={t('company_name')} />
            </Form.Item>
          </Col>
        </Row>

        {platformData =='cloud' && UserRole.AltisAdmin  && (  
        <Row gutter={8}>
          <Col xs={20} sm={2}>
            <Form.Item style={{ marginTop: '20px', marginLeft: '5px' }} name="isLocal" valuePropName="checked">
              <div style={{ position: 'relative' }}>
                <Tooltip title={t('islocal')}>
                  <Checkbox
                    style={{ transform: 'scale(1.5)' }}
                    onChange={handleCheckboxChange}
                  />
                </Tooltip>
              </div>
            </Form.Item>
          </Col>
          <Col xs={20} sm={12}>
            <Form.Item
              name="baseUrl"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('isLocal')) {
                      if (value && value.trim()) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t('please_input', { field: t('baseUrl') })));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              style={{
                marginTop: '10px',
                marginLeft: '-60px',
              }}
            >
              <Input
                prefix={<CloudServerOutlined />}
                style={{
                  borderRadius: 0,
                  textAlign: 'right',
                  width: 'calc(169% + 60px)',
                  paddingLeft: '8px',
                }}
                placeholder={t('baseUrl')}
                disabled={!isChecked}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    

        <Form.Item
          name='companyAddress'
          label={t('address')}
          rules={[{ required: true, message: t('please_input', { field: t('address') }), whitespace: true }]}
        >
          <Row gutter={16}>
            <Col span={16}>
              <Input
                prefix={<HomeOutlined />}
                style={{ borderRadius: 0 }}
                placeholder={t('address')}
              />
            </Col>
            <Col span={8}>
              <Form.Item
                name='companyCity'
                rules={[{ required: true, message: t('please_select', { field: t('city') }) }]}
              >
                <Select
                  showSearch
                  placeholder={t('select_city')}
                  optionFilterProp='children'
                  filterOption={(input, option) =>
                    option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {cities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
        label={t('responsible_firstname')}
        name='responsibleUserFirstname'
        rules={[
          { 
            required: true, 
            message: t('please_input', { field: t('responsible_firstname') }), 
            whitespace: true 
          },
          {
            pattern: /^(?!\s)(?!.*\s$).+$/,
            message: t('no_leading_or_trailing_spaces'),
          }
        ]}
      >
        <Input 
          prefix={<UserOutlined />} 
          style={{ borderRadius: 0 }} 
          placeholder={t('responsible_firstname')} 
        />
      </Form.Item>

        <Form.Item
          label={t('responsible_lastname')}
          name='responsibleUserLastname'
          rules={[{ required: true, message: t('please_input', { field: t('responsible_lastname') }), whitespace: true },
            {
              pattern: /^(?!\s)(?!.*\s$).+$/,
              message: t('no_leading_or_trailing_spaces'),
            }
          ]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder={t('responsible_lastname')} />
        </Form.Item>

        <Form.Item
          name='responsibleTelephone'
          label={t('responsible_telephone')}
          rules={[
            { required: true, message: t('please_input', { field: t('responsible_telephone') }) },
            { pattern: /^[0-9]*$/, message: t('only_numbers') },
          ]}
        >
          <Input
            prefix={<PhoneOutlined />}
            style={{ borderRadius: 0 }}
            placeholder='+90 512 6897 2541'
          />
        </Form.Item>

        <Form.Item
          name='responsibleEmail'
          label={t('responsible_email')}
          rules={[
            { required: true, message: t('please_input', { field: t('responsible_email') }), whitespace: true },
            { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: t('valid_email') },
          ]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} placeholder='example@gmail.com' />
        </Form.Item>

        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={t('select_licence')}
            name="selectLicence"
            rules={[
              {
                required: true,
                message: t('please_selectLicence'), 
              },
            ]}
            style={{ marginBottom: '16px' }} 
          >
            <Select
              key="select_licence"
              style={{ width: '100%' }} 
              placeholder={t('please_selectLicence')} 
              onClick={GetLicence}
            >
              {licenceData?.map((item) => (
                <Select.Option key={item.packageId} value={item.packageId}>
                  {item.packageName} 
                </Select.Option>
              ))}
            </Select>
          </Form.Item>


          <Form.Item
                    name='licencestartDate'
                    label={t('Licence Start Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseStartLicenceDate'),
                        },
                    ]}
                >
                        <DatePicker
                            style={{ width: '100%', borderRadius: 0 }}
                            placeholder={t('Licence Start Date')}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            onChange={(date) => {
                              if (date) {
                                const adjustedDate = date.add(3, 'hours'); 
                              } else {
                                //console.log('Tarih Seçilmedi');
                              }
                            }}
                        />
                
                </Form.Item>

                <Form.Item
                    name='licencefinishDate'
                    label={t('Licence Finish Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseLicenceFinish'),
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t('Licence Finish Date')}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                        onChange={(date) => {
                          if (date) {
                            const adjustedDate = date.add(3, 'hours'); 
                          } else {
                            //console.log('Tarih Seçilmedi');
                          }
                        }}
                    />
                </Form.Item>
          
      </Col>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: '5px' }}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddCustomer;
