import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { useTranslation } from 'react-i18next'; 
import { IPageProps } from "../../../interfaces/page-data";
import { ICloudConnector } from "../../../interfaces/ICloudConnector";

const EditCloudConnector: React.FunctionComponent<IPageProps> = () => {
  const { connectorId } = useParams<any>();
  const [cloudConnectorData, setCloudConnectorData] = useState<ICloudConnector>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (connectorId != null && connectorId != 0) {
      getCloudConnectorData(connectorId);
    }
  }, []);

  const fetchData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };

  const getCloudConnectorData = (cloudConnectorId: number) => {
    HTTPService.GET(`/CloudConnector/ById?cloudConnectorId=${cloudConnectorId}`)
      .then((res) => {
        if (res.status === 200) {
          setCloudConnectorData(res.data);
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching cloud connector data:", error);
      });
  };

  const updateCloudConnector = (formData) => {
    const updatedCloudConnector = {
      cloudConnectorId: connectorId,
      cloudConnectorName: formData.cloudConnectorName,
      companyId: formData.companyId || userInfo?.companyId,
    };

    HTTPService.PUT(`/cloudConnector/update`, updatedCloudConnector)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('cloud_connector_successfully') || t('cloud_connector_register'));
            window.location.replace("/admin/cloud-connector");
        }else if(res.status !=450){
          toast.error(t(res.data))
        }
      })
      .catch((error) => {
        toast.error(t('cloud_connector_successfully_error'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/cloud-connector/");
  };


  return (
    <Form
      layout="vertical"
      onFinish={updateCloudConnector}
      fields={[
        { name: ["cloudConnectorName"], value:  cloudConnectorData?.cloudConnectorName},
        { name: ["companyId"], value:cloudConnectorData?.companyId },
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{connectorId != 0 ? t('update_cloud_connector') : t('create_cloud_connector')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ width: '100%' }} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="cloudConnectorName"
              label={t('cloud_connector_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_cloud_connector_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ width: '100%',borderRadius: '0' }} placeholder={t('cloud_connector_name')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={24} sm={12} md={6}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: '5px' }}>
                {connectorId != 0 ? t('update') : t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default EditCloudConnector;
