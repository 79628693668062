import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Checkbox, Form, Table, Input, DatePicker, Tooltip, Select, ConfigProvider } from "antd";
import { useTranslation } from 'react-i18next';
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import '../CountingManagement/AddCounting.css';
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ICampusData } from "../../../interfaces/ICampusData";
import { ILocationData } from "../../../interfaces/ILocationData";
import { DeleteOutlined, TableOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import { ICountingData, ICountingJobLocation, ICountingJobResponsible, IUser } from "../../../interfaces/ICountingData";
import moment, { locales } from "moment";
import { IAllCampusWithLocation } from "../../../interfaces/IAllCampusWithLocation";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { ICompanyData } from "../../../interfaces/ICompanyData";


const CountingAdd: React.FunctionComponent<IPageProps> = () => {

    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();
    const { t } = useTranslation();

    //const [countingData, setCountingData] = useState<ICountingData>({}); // sayım bilgisi ve lokasyon bilgisi(campus bilgisi)
    const [countingJobLocationTBL, setCountingJobLocationTBL] = useState<any[]>([]);
    const [countingJobResponsibleTBL, setCountingJobResponsibleTBL] = useState<any[]>([]);
    const [companyData, setCompanyData] = useState<ICompanyData[]>([]);

    const [selectedCampus, setSelectedCampus] = useState<ICampusData[]>([]); // seçilen kampüsler
    const [selectedLocation, setSelectedLocation] = useState<ILocationData[]>([]); // seçilen lokasyonlar
    const [selectedUser, setSelectedUser] = useState<IUser[]>([]); // seçilen userlar
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);

    const [userData, setUserData] = useState([]);
    const [filteredUserData, setFilteredUserData] = useState([]); // üstte tablodaki userlar
    const [allCampusWithLocation, setAllCampusWithLocation] = useState<IAllCampusWithLocation[]>([]); // üstte tablodaki lokasyon ve kampüs

    const [form] = Form.useForm();
    const [searchText,setSearchText] = useState('')
    const [searchTextLocation,setSearchTextLocation] = useState('');

    const handleCancel = () => {
        window.location.replace("/admin/counting-management/");
    };

    useEffect(() => {
        fetchCompanyData();
        if(userInfo?.companyId){
            fetchUser(userInfo?.companyId)
            getCampusWithLocation(userInfo?.companyId)
        }
    }, []);

    const fetchCompanyData = async () => {
        try {
            const result = await HTTPService.GETCompanyNames();
            setCompanyData(result);
        } catch (error) {
            console.error("Şirket verilerini alırken bir hata oluştu:", error);
        }
    };


    const handleAddToTable = () => {
        selectedLocation.forEach(row => {
            const newSaveObj = {
                countingJobId: 0,
                locationId: row.locationId,
                locationTBL: row,
                campusTBL: row.campusTBL, 
                locationName: row.locationName,
                countingJobResponsibleTBL: [],
            };
    
            selectedUser.forEach(user => {
                const newCountingJobResponsible = {
                    countingJobId: 0,
                    responsibleUserId: user.userId,
                    locationId: row.locationId,
                    isNotification: false,
                    responsibleUserTBL: user,
                    locationTBL: row,
                };
                newSaveObj.countingJobResponsibleTBL.push(newCountingJobResponsible);
            });
    
            setCountingJobLocationTBL(prevState => [...prevState, newSaveObj]);
            setCountingJobResponsibleTBL(prevState => [...prevState, ...newSaveObj.countingJobResponsibleTBL]);
        });
    
        //setSelectedLocation([]); // temizleme işlemi
        setSelectedUser([]);
    };
    
    const handleDeleteRow = (countingJobLocationId: number, campusId: number, locationId: number) => {
        // Lokasyonları filtrelerken, sadece doğru locationId'ye sahip olanları sil
        const updatedLocationTBL = countingJobLocationTBL.filter(
            item => item.locationTBL.locationId !== locationId // locationId'ye göre filtreleme yapıyoruz
        );
        setCountingJobLocationTBL(updatedLocationTBL);
        
        // İlgili lokasyonun responsible verilerini filtrele
        const updatedResponsibleTBL = countingJobResponsibleTBL.filter(
            responsible => responsible.locationTBL.locationId !== locationId // locationId'ye göre filtreleme yapıyoruz
        );
        setCountingJobResponsibleTBL(updatedResponsibleTBL);
    };
    
    

    const handleCampusChange = (values: CheckboxValueType[]) => {
        let tmp: ILocationData[] = [];
        
        // console.log(values)
        if (values.includes('selectAllCampus')) {
            // allCampusWithLocation.forEach(row => {
            //     row.locationTBL.forEach(location => {
            //         tmp.push({
            //             ...location,
            //             campus: {
            //                 campusId: row.campusId,
            //                 campusName: row.campusName
            //             }
            //         });
            //     });
            // });
            // setSelectedLocation(tmp);
            // setSelectedCampus(allCampusWithLocation); 
        } else {
            const selected = allCampusWithLocation?.filter(row =>
                values.includes(row.campusId)
            );

            setSelectedCampus(selected);
    
            // selected.forEach(row => {
            //     row.locationTBL.forEach(location => {
            //         if (values.includes(location.locationId)) {
            //             const updatedLocation = {
            //                 ...location,
            //                 campus: {
            //                     campusId: row.campusId,
            //                     campusName: row.campusName
            //                 }
            //             };
            //             tmp.push(updatedLocation);
            //         }
            //     });
            // });
    
            // setSelectedLocation(tmp);
        }
    };
    
    
    const handleLocationChange = (values: CheckboxValueType[]) => {
        let tmp: ILocationData[] = [];
    
        if (values.includes('selectAll')) {
            // const allLocations = allCampusWithLocation.flatMap(row => row.locationTBL);
    
            // allLocations.forEach(location => {
            //     tmp.push({
            //         ...location,
            //         isSelected: true, 
            //     });
            // });
    
            // setSelectedLocation(tmp); 
        } else {
            allCampusWithLocation.filter(row =>
                row.locationTBL.some(location => values.includes(location.locationId))
            ).map(row => {
                row.locationTBL.forEach(location => {
                    if (values.includes(location.locationId)) {
                        tmp.push({
                            locationId:location.locationId,
                            campusId:row.campusId,
                            locationName:location.locationName,
                            campusTBL:row,
                        });
                    }
                });
            });
    
            setSelectedLocation(tmp); 
        }
    };
    
    const handleUserChange = (values: any[]) => {
        // selectedUsers dizisini kullanıcı verileriyle güncelle
    const selectedUsers = userData.filter(user => values.includes(user.userId));
    setSelectedUser(selectedUsers);
    
    };
    
    
    
    const fetchUser = async (companyId: number) => {
        try {
            const result = await HTTPService.GETUserNames(companyId);
            setUserData(result);
            setFilteredUserData(result); // Başlangıçta tüm kullanıcıları filteredUserData'ya da set ediyoruz
        } catch (error) {
            console.error(error);
            toast.error(t('error'));
        }
    };

    const getCampusWithLocation = (id:number) => {
        HTTPService.GET(`/Campus/allCampusWithLocationByCompany?companyId=${id}`)
            .then(response => {
                if (!response.data) {
                    return;
                }
                setAllCampusWithLocation(response.data);
            })
            .catch(error => {
                console.error('Error fetching pie data:', error);
            });
    };

    const registerCounting = async (values) => {
        let companyIdToUse;
        if(userInfo?.role == UserRole.Admin){
            companyIdToUse = userInfo?.companyId
        }else{
            companyIdToUse=values.selectedCompany
        }
        try {
            // Location ve User ID'lerini gruplayarak hazırlama
            const locationUserIdsToSend = countingJobLocationTBL.reduce((acc, location) => {
                const locationId = location.locationTBL.locationId;  // Her lokasyon için ID'yi alıyoruz
    
                // İlk defa karşılaşılan locationId için array başlatıyoruz
                if (!acc[locationId]) {
                    acc[locationId] = [];
                }
    
                // Eğer countingJobResponsibleTBL dizisi varsa, her bir responsibleUserId'yi ekliyoruz
                if (Array.isArray(location.countingJobResponsibleTBL)) {
                    location.countingJobResponsibleTBL.forEach(responsible => {
                        if (!acc[locationId].includes(responsible.responsibleUserId)) {
                            acc[locationId].push(responsible.responsibleUserId);
                        }
                    });
                }
    
                // Seçilen kullanıcıları ekliyoruz (selectedUser)
                selectedUser.forEach(user => {
                    if (!acc[locationId].includes(user.userId)) {
                        acc[locationId].push(user.userId);
                    }
                });
    
                return acc;
            }, {});
    
            const addHours = (date,hours)=>new Date(date.getTime() + hours*60*60*1000)
            // Counting Job verisi
            const countingJobTBL = {
                companyId:companyIdToUse,
                countingJobId: 0, 
                countingJobName: form.getFieldValue("countingJobName"),
                startDate: addHours(new Date(form.getFieldValue("startDate")), 3).toISOString(),
                finishDate: addHours(new Date(form.getFieldValue("finishDate")), 3).toISOString(),
            };
    
            // Gönderilecek veri yapısını oluşturuyoruz
            const dataToSend = {
                countingJobTBL: countingJobTBL,
                locationUserId: locationUserIdsToSend, 
            };

            // console.log(dataToSend)
    
            const res = await HTTPService.POST('/CountingJob/registerCounting', dataToSend);
    
            if (res.status === 200) {
                toast.success(t('countjobSuccess')); 
                window.location.replace("/admin/counting-management"); 
            }else if(res.status === 450){
                toast.error(t(res.data))
            }else if(res.status ===452){
                toast.error(t(res.data))
            }else if(res.status ===453){
                toast.error(t(res.data))
            }
             else {
                toast.error(t('error'));  
            }
        } catch (error) {
            console.error("Error during updateCounting:", error);
            toast.error(t('counting_update_failed'));  
        }
    };
    
    const columns = [
        {
            title: t('Campus'),
            //dataIndex: 'campusName',
            key: 'campus',
            render: (order) => <div>{order?.locationTBL?.campusTBL?.campusName}</div>,
        },
        {
            title: t('Location'),
            //dataIndex: 'locations',
            key: 'locations',
            render: (order) => <div>{order?.locationTBL?.locationName}</div>,
        },
        {
            title: t('responsible'),
            //dataIndex: 'users',
            key: 'displayName',
            render: (order) => {

                const responsibleUsers = order?.countingJobResponsibleTBL?.map(responsible => 
                     `${responsible.responsibleUserTBL.firstName} ${responsible.responsibleUserTBL.lastName}`
                ).join(", ");

                // const responsibleUsers = countingData?.countingJobResponsibleTBL?.filter(row => row.locationId == order.locationId)
                // .map(responsible => {
                //         return `${responsible.responsibleUserTBL.firstName} ${responsible.responsibleUserTBL.lastName}`
                // }
                //     )
                // .join(", ");

                return <div>{responsibleUsers}</div>;
            },
        },
        {
            title: t('operations'),
            key: 'operations',
            render: (_: any, record: any, index: number) => {
                // console.log(record);
    
                return (
                    <Button
                        onClick={() => handleDeleteRow(record.countingJobLocationId, record.campusTBL.campusId,record.locationId)}
                        icon={<DeleteOutlined />}
                    >
                    </Button>
                );
            }
        }
    ];

    const handleCompanyChange = (value: number) => {
        setSelectedCompany(value);
        getCampusWithLocation(value)
        fetchUser(value || userInfo?.companyId);
    };

    const GetLocationWidget = () => {
        let checkbox: any[] = [];
    
        // Seçilen kampüslerdeki lokasyonları filtrele
        allCampusWithLocation
            .filter(filterRow => selectedCampus?.some(x => x.campusId === filterRow.campusId)) // Seçili kampüsleri al
            .map((mapRow) => {
                // Lokasyonları arama metnine göre filtrele
                const filteredLocations = mapRow.locationTBL
                    .filter(location => location.locationName.toLowerCase().includes(searchTextLocation)) // Arama text'ine göre filtreleme
                    .filter(row => countingJobLocationTBL.filter(x => x.locationId === row.locationId).length == 0); // Seçili olmayan lokasyonlar
    
                // Filtrelenen lokasyonları checkbox olarak ekle
                filteredLocations.forEach((location: any) => {
                    checkbox.push(
                        <div key={location.locationId}>
                            <Checkbox
                                value={location.locationId}
                                checked={selectedLocation.some(selLoc => selLoc.locationId === location.locationId && selLoc.isSelected)}
                                onChange={() => {
                                    const updatedSelected = selectedLocation.some(selLoc => selLoc.locationId === location.locationId)
                                        ? selectedLocation.filter(selLoc => selLoc.locationId !== location.locationId)
                                        : [...selectedLocation, location];
                                    handleLocationChange(updatedSelected.map(loc => loc.locationId)); // Seçim yapıldığında güncelle
                                }}
                            >
                                {location.locationName}
                            </Checkbox>
                        </div>
                    );
                });
            });
    
        return checkbox; // Filtrelenmiş lokasyonları döndür
    };
    
    
    
    return (
        <Card style={{ padding: "50px" }}>
            <h3>{t('addCounting')}</h3>
            <Form
                form={form}
                onFinish={registerCounting}
                layout="vertical"
            >
                 {userInfo?.role === UserRole.AltisAdmin ? (
                    <Form.Item
                        name='selectedCompany'
                        label={t('Select company')}
                        rules={[{ required: true, message: t('please_select_a_company') }]}
                    >
                        <Select
                            showSearch
                            placeholder={t('company_name')}
                            style={{ marginBottom: '6px' }}
                            onChange={handleCompanyChange}
                        >
                            {companyData.map((company) => (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                ) : null}

                <Form.Item
                    name="countingJobName"
                    label={t('Counting Job Name')}
                    rules={[{ required: true, message: t('inputCounting'), whitespace: true }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        style={{ borderRadius: 0 }}
                        placeholder={t('Counting Job Name')}
                    />
                </Form.Item>


                <Form.Item
                    name='startDate'
                    label={t('Counting Start Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseCounting'),
                        },
                    ]}
                >
                        <DatePicker
                            style={{ width: '100%', borderRadius: 0 }}
                            placeholder={t('Counting Start Date')}
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                        />
                
                </Form.Item>

                <Form.Item
                    name='finishDate'
                    label={t('Counting Finish Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseCountingFinish'),
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t('Counting Finish Date')}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                    />
                </Form.Item>


                <Form.Item>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                        <Form.Item label={t('campus')}>
                        <Input
                            placeholder={t('search_campus')}
                                    style={{
                                        marginBottom: '10px',
                                        borderRadius: '0px',
                                        marginTop: '-25px',
                                    }}
                                    allowClear
                                    onChange={(e) => {
                                        const searchText = e.target.value.toLowerCase();
                                        setSearchText(searchText); 
                                    }}
                                    className="custom-search-input"
                        />

                            <div className="checkbox-group-container">
                                <Checkbox.Group
                                    value={selectedCampus.map(campus => campus.campusId)}
                                    onChange={handleCampusChange}
                                >

                                    <div key="selectAllCampus" style={{ marginLeft: '7px' }}>
                                        <p
                                            onClick={() => {
                                                if (selectedCampus.length === allCampusWithLocation.length) {
                                                    handleCampusChange([]);
                                                } else {
                                                    const allCampusIds = allCampusWithLocation.map(row => row.campusId);
                                                    handleCampusChange(allCampusIds); 
                                                }
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                padding: '5px',
                                                display: 'inline-block',
                                                marginLeft: '-2px',
                                                userSelect: 'none',
                                                marginTop: '5px', 
                                            }}
                                        >
                                            {t('selectAll')}
                                        </p>
                                    </div>

                                    
                                    {allCampusWithLocation
                                        .filter(row =>
                                            row.campusName.toLowerCase().includes(searchText)
                                            && row.locationTBL.filter(location =>
                                                countingJobLocationTBL?.some(x => x.locationId === location.locationId)
                                            ).length !== row.locationTBL.length 
                                        )
                                        .map((row: any, index: number) => (
                                            <div key={`${row.campusId}-${index}`}>
                                                <Checkbox value={row.campusId}>{row.campusName}</Checkbox>
                                            </div>
                                        ))}
                                </Checkbox.Group>
                            </div>
                        </Form.Item>

                        </Col>

                        <Col span={8}>

                        <Form.Item label={t('location')}>
                            <Input
                                placeholder={t('search_location')}
                                style={{
                                    marginBottom: '10px',
                                    borderRadius: '0px',
                                    marginTop: '-25px',
                                }}
                                allowClear
                                onChange={(e) => {
                                    const searchText = e.target.value.toLowerCase();
                                    setSearchTextLocation(searchText); // searchTextLocation'ı güncelledik
                                }}
                                className="custom-search-input"
                            />
                                <div className="checkbox-group-container">
                                    <div>
                                        <p
                                            onClick={() => {
                                                const selectedCampusIds = selectedCampus.map(campus => campus.campusId);

                                                // Seçili kampüslerin bağlı olduğu tüm lokasyonları al
                                                const totalLocationIds = allCampusWithLocation
                                                    .filter(campus => selectedCampusIds.includes(campus.campusId))
                                                    .flatMap(campus => campus.locationTBL.map(location => location.locationId));

                                                // Seçili olan lokasyonları (already selected) çıkart
                                                const selectedLocationIds = countingJobLocationTBL.map(loc => loc.locationId);

                                                // Tüm lokasyonlardan sadece seçili olmayanları al
                                                const unselectedLocationIds = totalLocationIds.filter(locationId => !selectedLocationIds.includes(locationId));

                                                // Eğer tüm lokasyonlar zaten seçildiyse, hiçbir şey yapma (tümünü kaldır)
                                                if (selectedLocation.length === totalLocationIds.length) {
                                                    handleLocationChange([]); // Tümünü kaldır
                                                } else {
                                                    // Seçili olmayanları seç
                                                    handleLocationChange(unselectedLocationIds); // Sadece seçilmeyenleri ekle
                                                }
                                            }}
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                padding: '5px',
                                                display: 'inline-block',
                                                marginLeft: '-2px',
                                                userSelect: 'none',
                                                marginTop: '5px',
                                            }}
                                        >
                                            {t('selectAll')}
                                        </p>
                                    </div>

                                    <Checkbox.Group value={selectedLocation.map(loc => loc.locationId)} onChange={handleLocationChange}>
                                        <div>
                                            {GetLocationWidget()}
                                        </div>
                                    </Checkbox.Group>
                                </div>
                        </Form.Item>

                         </Col>
                        <Col span={8}>
                            <Form.Item label={t('responsible')}>
                            <Input
                                placeholder={t('search_responsible')}
                                                    style={{
                                                        marginBottom: '10px',
                                                        borderRadius: '0px',
                                                        marginTop: '-25px',
                                                    }}
                                                    allowClear
                                                    onChange={(e) => {
                                                        const searchText = e.target.value.toLowerCase();
                                                        if (!searchText) {
                                                            setFilteredUserData(userData);
                                                        } else {
                                                            const filtered = userData.filter(user =>
                                                                user.displayName.toLowerCase().includes(searchText)
                                                            );
                                                            setFilteredUserData(filtered);
                                                        }
                                                    }}
                                                    className="custom-search-input"
                                />
                                    <div className="checkbox-group-container">
                                            <>
                                                <Checkbox.Group
                                                    value={selectedUser.map(user => user.userId)}
                                                    onChange={handleUserChange}
                                                >
                                                    <div key="all" style={{ marginLeft: '7px' }}>
                                                        <p
                                                            onClick={() => {
                                                                if (selectedUser.length === userData.length) {
                                                                    handleUserChange([]);
                                                                } else {
                                                                    const allUserIds = userData.map(row => row.userId);
                                                                    handleUserChange(allUserIds);
                                                                }
                                                            }}
                                                            style={{
                                                                cursor: 'pointer',
                                                                fontWeight: 'bold',
                                                                padding: '5px',
                                                                display: 'inline-block',
                                                                marginLeft: '-10px',
                                                                userSelect: 'none',
                                                                marginTop: '5px',
                                                            }}
                                                        >
                                                            {t('selectAll')}
                                                        </p>
                                                    </div>

                                                    {filteredUserData.map(user => (
                                                        <div key={user.userId}>
                                                            {selectedLocation.length>0 && (
                                                                <Checkbox value={user.userId}>{user.displayName}</Checkbox>
                                                            )}
                                                        </div>
                                                    ))}
                                                </Checkbox.Group>
                                            </>
                                    </div>
                            </Form.Item>
                        </Col>

                      
                    </Row>
                </Form.Item>

                <Col xs={8} sm={4}>
                    <Tooltip title={t('addTable')}>
                        <Button
                            block
                            onClick={handleAddToTable}
                            type="primary"
                            style={{
                                borderRadius: 8,
                                width: '140px',
                                height: '45px',
                                border: 'none',
                                boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                                transition: 'all 0.3s ease'
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = 'translateY(-2px)';
                                e.currentTarget.style.boxShadow = '0 6px 20px rgba(0,0,0,0.25)';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = 'translateY(0)';
                                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0,0,0,0.2)';
                            }}
                        >
                            <TableOutlined style={{ fontSize: '18px' }} />
                            <span>{t('addTable')}</span>
                        </Button>
                    </Tooltip>
                </Col>
                <br />
                <Form.Item>
                    <Table
                        dataSource={countingJobLocationTBL}
                        columns={columns} 
                        pagination={false}
                        key={"key"}
                    />
                </Form.Item>

                <Form.Item>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                onClick={handleCancel}
                                type="primary"
                                style={{ borderRadius: '5px' }}
                            >
                                {t('cancel')}
                            </Button>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                htmlType="submit"
                                type="primary"
                                style={{ borderRadius: '5px' }}
                            >
                                {t('save')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CountingAdd;
