import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, SelectProps, Switch } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { ICampusData } from "../../../interfaces/ICampusData";
import { useParams } from "react-router-dom";
import { IPageProps } from "../../../interfaces/page-data";
import { IPackageData } from "../../../interfaces/IPackageData";

const UpdatePackage: React.FC<IPageProps> = ({}) => {
  const { packageId } = useParams<any>();
  const [licenceData, setlicenceData] = useState<IPackageData | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    getLicenceData(packageId);
  }, []);

  const getLicenceData = (packageId) => {
    HTTPService.GET(`/LicencePackages/ById?packageId=${packageId}`)
      .then((res) => {
        if (res.status === 200) {
          const data: IPackageData = res.data;
          setlicenceData(data);
        } else {
          toast.error(res.error.message);
        }
      })
      .catch((error) => {
        console.error('Error fetching licence data:', error);
        toast.error('Failed to load licence data.');
      });
  };

  const PackageUpdate = async (values) => {
    try {
      const updatedLicence = {
        packageId,       
        packageName: values.packageName,
        assetLimit: values.assetLimit,
        userLimit: values.userLimit,
        featureRecords: values.featureRecords,
        extraFeatures: values.extraFeatures,
        isPdf: values.isPdf,
        isExcel: values.isExcel,
        isReporting: values.isReporting,
        isBarcode: values.isBarcode,
        isNfc: values.isNfc,
        isRfid: values.isRfid
      };
      const res = await HTTPService.PUT(`/LicencePackages/updateLicence`, updatedLicence);
      if (res.status === 200) {
          window.location.replace("/admin/package-management");
      }else if(res.status===450){
        toast.error(t(res.data))
      }
      else {
        toast.error(t(res.data));
      }
    } catch (error) {
      toast.error(t('error_updating_licence'));
    }
  }
 
  const handleCancel = () => {
    window.location.replace('/admin/package-management');
  }

  return (
    <Form
          layout="vertical"
          onFinish={PackageUpdate}
          fields={[
            { name: ["packageName"], value: licenceData?.packageName},
            { name: ["assetLimit"], value:  licenceData?.assetLimit},
            { name: ["userLimit"], value: licenceData?.userLimit},
            { name: ["featureRecords"], value:  licenceData?.featureRecords},
            { name: ["isPdf"], value: licenceData?.isPdf},
            { name: ["isExcel"], value:  licenceData?.isExcel},
            { name: ["isReporting"], value: licenceData?.isReporting},
            { name: ["isBarcode"], value:  licenceData?.isBarcode},
            { name: ["isNfc"], value: licenceData?.isNfc},
            { name: ["isRfid"], value:  licenceData?.isRfid},
            { name: ["extraFeatures"], value:  licenceData?.extraFeatures},
          ]}
        >
    <Card style={{ padding: "50px" }}>
      <h3>{t('update-package')}</h3>
      
        

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="packageName"
              label={t('packageName')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_package_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('packageName')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              name="assetLimit"
              label={t('assetLimit')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_asset_limit'),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('assetLimit')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              name="userLimit"
              label={t('userLimit')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_user_limit'),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('userLimit')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="featureRecords"
              label={t('featureRecords')}
              rules={[
                {
                  required: true,
                  message: t('please_input_feature_records'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('featureRecords')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="extraFeatures"
                      label={t('extralFeatures')}
                      rules={[
                        {
                          required: true,
                          message: t('please_input_feature_extra'),
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder={t('extralFeatures')}
                        maxLength={50}
                        style={{ width: '100%', borderRadius: 0 }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
        

        <Row gutter={0}>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isPdf"
              label={t('isPdf')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isExcel"
              label={t('isExcel')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isReporting"
              label={t('isReporting')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isBarcode"
              label={t('isBarcode')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isNfc"
              label={t('isNfc')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isRfid"
              label={t('isRfid')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      
    </Card>
    </Form>
  );
};

export default UpdatePackage;
