import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import AltisDataGrid from "../../../AltisComponent/AltisDataGrid";
import { IPrint } from '../../../interfaces/IPrintData';
import { Button, Image, Modal, Pagination, Popconfirm, Space, Table, Tooltip } from "antd";
import { DeleteOutlined, EyeOutlined, PrinterOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { useTranslation } from 'react-i18next';
import moment from "moment";

const PrintsJobsAPI: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const [printList, setPrintList] = useState<IPrint>({});
  const [isModalImage, setIsModalImage] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  const imagesPerPage = 1
  const [currentPage, setCurrentPage] = useState(1);
  const [modalData, setModalData] = useState('');

  const pageData: IPageData = {
    title: t('PrintsJobs'),
    loaded: true
  };

  const paginatedImages = () => {
    const startIndex = (currentPage - 1) * imagesPerPage;
    const endIndex = startIndex + imagesPerPage;
    return imageSrc?.labels?.slice(startIndex, endIndex) || [];
  };

  const totalPages = Math.ceil((imageSrc?.labels?.length || 0) / imagesPerPage);

  const generatePagination = () => {
    const pages = [];
    const showPages = 3; // Her iki tarafta gösterilecek sayfa sayısı

    if (totalPages <= showPages * 2 + 1) {
      // Toplam sayfa sayısı azsa tüm sayfaları göster
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      // İlk 3 sayfa ve son 3 sayfa gösterilecek
      for (let i = 1; i <= showPages; i++) {
        pages.push(i);
      }
      if (currentPage > showPages + 2) {
        pages.push('...');
      }
      const startPage = Math.max(1, totalPages - showPages + 1);
      for (let i = startPage; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    return pages;
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    onSetPage(pageData);
    TagFilter();
  }, []);

  const TagFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    var body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      printerId: e?.printerId,
      printerName: e?.printerName,
      companyId: e?.companyId,
      companyName: e?.companyName,
      templateId: e?.templateId,
      templateName: e?.templateName,
      inventoryId: e?.inventoryId,
      inventoryName: e?.inventoryName,
      printDate : moment(e?.printDate),
      printDate_Start: e?.printDate_Start === null || e?.printDate_Start === "" ? null : new Date(Date.parse(e?.printDate_Start)),
      printDate_Finish: e?.printDate_Finish === null || e?.printDate_Finish === "" ? null : new Date(Date.parse(e?.printDate_Finish)),
      isExcel: false,
    };

    HTTPService.POST(`/PrintJob/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        setPrintList(response.data)
        // console.log(response.data)
      }else if(response.status ===450){
        toast.error(t(response.data))
      } 
      else {
        toast.error(response.data)
      }
    });
  };

  
  const DetailsColumn = [
    {
      title: t('key'),
      key: 'Key',
      dataIndex: 'Key',
    },
    {
      title:  t('value'),
      key: 'Value',
      dataIndex: 'Value',
    },
  ];

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const tagDataColumns: IAltisDataGridColumns[] = [
    ...(user.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      isSorting:true,
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    {
      title: t('template_name'),
      key: 'templateName',
      isSorting:true,
      placeHolder: t('template_name'),
      inputType: 'input',
    },
    {
       title: 'Barcode',
       key: 'barcode',
       placeHolder: 'Barcode',
       inputType: 'operations',
       width: 1000,
       render: (order,record) => {
         const barcodes = record.barcode ? record.barcode.split(',') : [];
        //  console.log(barcodes)
         if (barcodes.length > 1) {
           return (
             <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
               {barcodes[0]}...
             </div>
           );
         }
         return barcodes; 
       },
    },
    {
      title: t('printed_error_date'),
      key: 'printederrorDate',
      placeHolder: t('printed_error_date'),
      inputType: 'operations',
      width: 1000,
      render: (text) => moment(text).format('DD-MM-YYYY HH:mm'),
    },
    {
      title: t('Printer Name'),
      key: 'printerName',
      placeHolder: t('Printer Name'),
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          
          <Button size='small' icon={<EyeOutlined />} style={{display: user.role === UserRole.Sayman ? 'none' : 'block',marginLeft:40}}    onClick={() => {
                setModalData(record.printJobDetails);
                setIsModalVisible(true); 
              }} 
            >
              {record.printerName}
          </Button>
        </div>
      ),
    },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: 'operations',
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
          <Popconfirm
              title={t('are_you_sure_printJob')}
              onConfirm={() => DeletePrintsJobs(record.printJobId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')}>
              <Button size='small' icon={<DeleteOutlined />} style={{width: '60px',borderRadius:'70px' }} onClick={(e) => e.stopPropagation()}>
              </Button>
              </Tooltip>

            </Popconfirm>
            
          <Tooltip title={t('preview')}>

          <Button
            size='small'
            icon={<EyeOutlined />}
            style={{ width: '80px',borderRadius:'70px'  }}
            onClick={() => {
              ImagePriview(record.printJobId);
            }}>
          </Button>

          </Tooltip>
       
          {!record.jobStatus && (
            <Tooltip title={t('Reprint')}>
            <Button
              size='small'
              style={{width: '60px',borderRadius:'70px'}}
              icon={<PrinterOutlined />}
              onClick={() => {
                PrintData(record.printJobId)
              }}>
            </Button>
            </Tooltip>
        
          )}
          </Space>
        
        </div>
      ),
    },
  ];

  const DeletePrintsJobs = (id) => {
    HTTPService.DELETE(`/PrintJob/deletePrintJob?Id=${id}`).then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      } else {
        toast.error(t(response.data));
      }
    });
  };

  const ImagePriview = (id) => {
    HTTPService.POST(`/PrintJob/Preview?printJobId=${id}&type=${"image"}`, '')
      .then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          setImageSrc(tmp);
          setIsModalImage(true);
        }
        else{
          toast.error(t(response.data))
        }
      })
      .catch((error) => {
        console.error('There was an error fetching the image!', error);
      });
  };

  const PrintData = (id: number) => {
    HTTPService.POST(`/PrintJob/Reprint?printJobId=${id}`, "").then((response) => {
      if (response.status === 200) {
        toast.success(t(response.data));
        window.location.reload();
      }else if(response.status === 400) {
        toast.error(t(response.data));
      }
       else {
        toast.error(t('error450'));
      }
    }).catch((response) => {
      console.error(response);
    })
  };

  const handleImage = () => {
    setIsModalImage(false);
    setImageSrc('');
  };


  return (
    <>
      <AltisDataGrid data={printList?.data} total={printList?.count} columns={tagDataColumns} onChange={TagFilter}/>

<Modal
  title={<span style={{ fontWeight:'bold' }}>{t('image_preview')}</span>}
  visible={isModalImage}
  onCancel={handleImage}
  footer={null}
  style={{ maxWidth: '90%', maxHeight: '90%' }} // Modal boyutlarını sınırla
>
  <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 130px)' }}>
    {paginatedImages().length > 0 && (
      <Image
        src={`data:image/png;base64,${paginatedImages()[0].imageBase64}`}
        style={{ width: '100%', height: 'auto' }}
        alt='Preview'
      />
    )}
    {totalPages > 1 && (
    <Pagination
    current={currentPage}
    pageSize={imagesPerPage}
    total={imageSrc?.labels?.length}
    onChange={page => setCurrentPage(page)}
    style={{ textAlign: 'center', marginTop: '16px' }}
    itemRender={(page, type, originalElement) => {
      if (type === 'page') {
        return (
          <span
            style={{
              padding: '0 8px',
              cursor: 'pointer',
              fontWeight: currentPage === page ? 'bold' : 'normal',
              display: 'inline-block',
              width: '20px', 
              textAlign: 'center',
              whiteSpace: 'nowrap' 
            }}
          >
            {page}
          </span>
        );
      }
      return originalElement;
    }}
  />
  
    )}
  </div>
</Modal>
      <Modal
        title={<span style={{ color: 'red'}}>{t('details')}</span>}
        visible={isModalVisible}
        bodyStyle={{ width: 510, height: 350, overflowY: 'auto' }} 
        footer={null}
        onCancel={handleCancel}
      >
       <div>
          {modalData ? modalData.split(',').map((item, index) => (
            <p key={index} style={{ margin: 0 }}>
              {item.trim()}
            </p>
          )) : (
            <p>{t('noDetails')}</p>
          )}
        </div>
      </Modal>
    </>
  );
}

export default PrintsJobsAPI;
