import React, { useEffect, useState } from 'react';
import { Modal, Form, Tooltip, Row, Col, Checkbox, Select, Button } from 'antd';
import { PlusOutlined, SlackCircleFilled } from '@ant-design/icons';
import AddCampus from '../../CampusManagement/AddCampus';
import AddLocation from '../../LocationManagement/AddLocation';
import AddDepartment from '../../DepartmentManagement/AddDepartment';
import { IDepartmentData } from '../../../../interfaces/IDepartmentData';
import AddCategory from '../../CategoryManagement/AddCategory';
import AddType from '../../TypeManagement/AddType';
import AddSupplier from '../../SupplierManagement/AddSupplier';
import AddBrand from '../../BrandManagement/AddBrand';
import { IBrandData } from '../../../../interfaces/IBrandData';
import AddInventoryStatus from '../../InventoryStatus/AddInventoryStatus';
import { IInventoryStatusData } from '../../../../interfaces/IInventoryStatusData';
import { ISupplierData } from '../../../../interfaces/ISupplierData';
import { ITypeData } from '../../../../interfaces/ITypeData';
import { ICategoryData } from '../../../../interfaces/ICategoryData';
import HTTPService from '../../../../utils/makerequest';
import { userInfo } from 'os';
import { IAuthentication } from '../../../../interfaces/authentication';
import { StateLoader } from '../../../../redux/StateLoader';
import { all } from 'axios';
import { IMultipleData } from '../../../../interfaces/IMultipleData';
import { Http } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { ICampusData } from '../../../../interfaces/ICampusData';
import { ILocationData } from '../../../../interfaces/ILocationData';

export const BulkUpdateModal = ({
  isModalVisibleBulk,
  handleCancelBulk,
  inventoryId,
  t,
}) => {
  const [isModalVisibleCampus, setIsModalVisibleCampus] = useState(false);
  const [isModalVisibleLocation, setIsModalVisibleLocation] = useState(false);
  const [isModalVisibleDepartment, setIsModalVisibleDepartment] = useState(false);
  const [isModalVisibleCategory, setIsModalVisibleCategory] = useState(false);
  const [isModalTypeVisible, setIsModalTypeVisible] = useState(false);
  const [isModalTypeSupplier, setIsModalTypeSupplier] = useState(false);
  const [isModalBrandVisible, setIsModalBrandVisible] = useState(false);
  const [isModalStatusVisible, setIsModalStatusVisible] = useState(false);
  const [statusData, setStatusData] = useState<IMultipleData>();
  const [selectedCampusId, setSelectedCampusId] = useState(null);
  const [selectedCategoryId,setSelectedCategoryId] = useState(null);

  const [enabledFields, setEnabledFields] = useState({
    campus: false,
    location: false,
    department:false,
    category: false,
    type: false,
    brand:false,
    supplier:false,
    status:false,
    debit:false,
    responsible:false
  });

  const handleOpenCampusModal = () => setIsModalVisibleCampus(true);
  const handleCancelCampus = () => setIsModalVisibleCampus(false);
  const handleOpenLocationModal = () => setIsModalVisibleLocation(true);
  const handleCancelLocation = () => setIsModalVisibleLocation(false);
  const handleOpenDepartmentModal = () => setIsModalVisibleDepartment(true);
  const handleCancelDepartment = () => setIsModalVisibleDepartment(false);
  const handleOpenCategoryModal = () => setIsModalVisibleCategory(true);
  const handleCancelCategory = () => setIsModalVisibleCategory(false);
  const handleOpenTypeModal = () => setIsModalTypeVisible(true);
  const handleCancelType = () => setIsModalTypeVisible(false);
  const handleOpenSupplierModal = () => setIsModalTypeSupplier(true);
  const handleCancelSupplier = () => setIsModalTypeSupplier(false);
  const handleOpenBrandModal = () => setIsModalBrandVisible(true);
  const handleCancelBrand = () => setIsModalBrandVisible(false);
  const handleOpenStatusModal = () => setIsModalStatusVisible(true);
  const handleCancelStatus = () => setIsModalStatusVisible(false);
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [form] = Form.useForm();
  
  const toggleFieldEnabled = (field) => (checked) => {
    setEnabledFields((prev) => ({
      ...prev,
      [field]: checked,
    }));
  };

  const handleCampusChange = (value: number) => {
    setSelectedCampusId(value);
  };
 
  useEffect(() => {
    AllData(userInfo.companyId);
  },[])

  // const savedNewStatus = (status: IInventoryStatusData) => {
  //   setStatusData(prevEnabledFields => ({
  //     ...prevEnabledFields,
  //     status: status.inventoryStatusId,  
  //   }));c
  // };

  const savedCampusObj = (campus:ICampusData) => {
    var tmp = {...statusData};
    tmp.campus.push(campus)
    setStatusData(tmp)
  };

  const savedLocationObj = (location:ILocationData)=>{
    var tmp ={...statusData}
    tmp.location.push(location)
    setStatusData(tmp)
  }

  const savedDepartmentObj = (department:IDepartmentData)=>{
    var tmp ={...statusData}
    tmp.department.push(department)
    setStatusData(tmp)
  }

  const savedTypeObj = (type:ITypeData)=>{
    var tmp ={...statusData}
    tmp.type.push(type)
    setStatusData(tmp)
  }

  
  const savedCategoryObj = (category:ICategoryData)=>{
    var tmp ={...statusData}
    tmp.category.push(category)
    setStatusData(tmp)
  }

  const savedSupplierObj = (supplier:ISupplierData)=>{
    var tmp ={...statusData}
    tmp.supplier.push(supplier)
    setStatusData(tmp)
  }

  const savedBrandObj = (brand:IBrandData)=>{
    var tmp ={...statusData}
    tmp.brand.push(brand)
    setStatusData(tmp)
  }

  
  const savedStatusObj = (status:IInventoryStatusData)=>{
    var tmp ={...statusData}
    tmp.status.push(status)
    setStatusData(tmp)
  }



   const AllData = (companyId:number) => {
    HTTPService.GET(`/Multiple/multipleData?companyId=${companyId}`)
      .then((response) => {
        if(response.status === 200){
          // console.log("response.data",response.data)
          handleCancelBulk()
          setStatusData(response.data)
        }else{
          setStatusData(null)
        }
      })
      .catch((error) => {
        console.error('Veri çekme hatası:', error);
      });
  };


  const MultipleUpdate = (companyId:number,values) => {
    const body = {
      inventoryModel: {
        locationId: values.locationId,
        departmentId: values.departmentId,
        typeId: values.typeId,
        supplierId: values.supplierId,
        brandId: values.brandId,
        inventoryStatusId: values.inventoryStatusId,
        debtUserId: values.debtUserId,
      },
      inventoryId: inventoryId,
      responsibleUserList: values.responsibleUserList,
    };
    // console.log("body",body)
    HTTPService.PUT(`/Inventory/multipleUpdateInventories?companyId=${companyId}`,body)
      .then((response) => {
        if(response.status === 200){
          // console.log("response.data",response.data)
          toast.success(t('bulk_update_successfully'))
          isModalVisibleBulk(false)
        }else if (response.status === 400){
          toast.error(t(response.data))
        }
        else {
            toast.error(t('bulk_update_error'))
        }
      })
      .catch((error) => {
        console.error('Güncelleme hatası:', error);
      });
  };

  return (
    <>
      <Modal
        title={t('bulk_update')}
        visible={isModalVisibleBulk}
        onCancel={handleCancelBulk}
        okText={t('save')}
        cancelText={t('cancel')}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              MultipleUpdate(userInfo?.companyId, values);
              handleCancelBulk();
            })
            .catch((errorInfo) => {
              console.error("Validation failed:", errorInfo);
            });
        }}
        bodyStyle={{
          maxHeight: '70vh',
          overflowY: 'auto', 
        }}
      >
        <Form layout="vertical" form={form}>
          {/* Kampüs Alanı */}
          <Form.Item
            label={
              <Tooltip title={t('select_campus_description')}>
                {t('campus')}
              </Tooltip>
            }
          >
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox
                  onChange={(e) => {
                    toggleFieldEnabled('campus')(e.target.checked);
                    if (!e.target.checked) {
                      toggleFieldEnabled('location')(false); 
                      form.setFieldsValue({ campusId: null })
                      form.setFieldsValue({ locationId: null })
                    }
                  }}
                />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="campusId"
                  noStyle
                  rules={[{ required: enabledFields?.campus, message: t('select_campus') }]}
                >
                  <Select
                    placeholder={t('campus')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.campus}
                    onChange={(value) => {
                      setSelectedCampusId(value);
                      toggleFieldEnabled('location')(true); // Lokasyon alanını etkinleştir
                    }}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => enabledFields?.campus && handleOpenCampusModal()}
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.campus.map((item) => (
                      <Select.Option key={item.campusId} value={item.campusId}>
                        {item.campusName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Lokasyon Alanı */}
          <Form.Item
              label={t('location')}
            >
            <Row align="middle" gutter={8}>
              <Col flex="auto">
                <Form.Item
                  name="locationId"
                  noStyle
                  rules={[{ required: enabledFields?.location, message: t('select_location') }]}
                >
                  <Select
                    placeholder={t('location')}
                    style={{ width: '95%',marginLeft:'20px' }}
                    disabled={!enabledFields.location}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => enabledFields?.location && handleOpenLocationModal()}
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.location
                      .filter((item) => item.campusId === selectedCampusId)
                      .map((item) => (
                        <Select.Option key={item.locationId} value={item.locationId}>
                          {item.locationName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Departman Alanı */}
          <Form.Item label={t('department_name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                  onChange={(e) => {
                    toggleFieldEnabled('department')(e.target.checked);
                    if (!e.target.checked) {
                      toggleFieldEnabled('department')(false); 
                      form.setFieldsValue({ departmentId: null })
                    }
                  }}  />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="departmentId"
                  noStyle
                  rules={[{ required: enabledFields.department, message: t('select_department') }]}
                >
                     {/* {renderSelectWithIcon(t('department'), !enabledFields.department,statusData,'department',handleOpenDepartmentModal)}  */}
                    <Select
                    placeholder={t('department_name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.department}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={()=> enabledFields?.department && handleOpenDepartmentModal()}  
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.department.map(item => (
                      <Select.Option key={item.departmentId} value={item.departmentId}>
                        {item.departmentName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Kategori Alanı */}
          <Form.Item label={t('category')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                 onChange={(e) => {
                  toggleFieldEnabled('category')(e.target.checked);
                  if (!e.target.checked) {
                    toggleFieldEnabled('type')(false); 
                    form.setFieldsValue({ categoryId: null })
                    form.setFieldsValue({ typeId: null })
                  }
                }}/>
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="categoryId"
                  noStyle
                  rules={[{ required: enabledFields.category, message: t('select_category') }]}
                >
                    {/* {renderSelectWithIcon(t('category'), !enabledFields.category,statusData,'category',handleOpenCategoryModal)}  */}
                    <Select
                    placeholder={t('category')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.category}
                    onChange={(value) => {
                      setSelectedCategoryId(value);
                      toggleFieldEnabled('type')(true); 
                      form.setFieldsValue({ categoryId: null })
                    }}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={()=>enabledFields?.category && handleOpenCategoryModal()}  
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.category.map(item => (
                      <Select.Option key={item.categoryId} value={item.categoryId}>
                        {item.categoryName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Tip Alanı */}
          <Form.Item label={t('item-type')}>
            <Row align="middle" gutter={8}>
              <Col flex="auto">
                <Form.Item
                  name="typeId"
                  noStyle
                  rules={[{ required: enabledFields.type, message: t('select_type') }]}
                >
                  <Select
                    placeholder={t('item-type')}
                    style={{ width: '95%',marginLeft:'20px'}}
                    disabled={!enabledFields.type}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => enabledFields?.type && handleOpenTypeModal()}
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.type
                      .filter((item) => item.categoryId === selectedCategoryId)
                      .map((item) => (
                        <Select.Option key={item.typeId} value={item.typeId}>
                          {item.typeName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Tedarikçi Alanı */}
          <Form.Item label={t('supplier_name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                  onChange={(value) => {
                    toggleFieldEnabled('supplier')(true); 
                    form.setFieldsValue({ supplierId: null })
                  }} />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="supplierId"
                  noStyle
                  rules={[{ required: enabledFields.supplier, message: t('supplier_name') }]}
                >
                   {/* {renderSelectWithIcon(t('supplier'), !enabledFields.supplier,statusData,'supplier', handleOpenSupplierModal)}  */}
                    <Select
                    placeholder={t('supplier_name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.supplier}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={()=> enabledFields?.supplier && handleOpenSupplierModal()}  
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.supplier.map(item => (
                      <Select.Option key={item.supplierId} value={item.supplierId}>
                        {item.supplierName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Marka Alanı */}
          <Form.Item label={t('brand_name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                    onChange={(value) => {
                      toggleFieldEnabled('brand')(true); 
                      form.setFieldsValue({ brandId: null })
                    }} /> 
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="brandId"
                  noStyle
                  rules={[{ required: enabledFields.brand, message: t('brand_name') }]}
                >
                  {/* {renderSelectWithIcon(t('brand'), !enabledFields.brand,statusData,'brand', handleOpenBrandModal)}  */}
                  <Select
                    placeholder={t('brand_name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.brand}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={()=> enabledFields?.brand && handleOpenBrandModal()}  
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.brand.map(item => (
                      <Select.Option key={item.brandId} value={item.brandId}>
                        {item.brandName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          {/* Durum Alanı */}
          <Form.Item label={t('inventory_status_name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                   onChange={(value) => {
                    toggleFieldEnabled('status')(true); 
                    form.setFieldsValue({ inventoryStatusId: null })
                  }} />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="inventoryStatusId"
                  noStyle
                  rules={[{ required: enabledFields.status, message: t('inventory_status_name') }]}
                >
                  {/* {renderSelectWithIcon(t('status'), !enabledFields.status,statusData,'status', handleOpenStatusModal)}  */}
                  <Select
                    placeholder={t('inventory_status_name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.status}
                    suffixIcon={
                      <Button
                        type="link"
                        icon={<PlusOutlined />}
                        onClick={() => enabledFields?.status && handleOpenStatusModal()}
                        style={{ padding: 0 }}
                      />
                    }
                  >
                    {statusData?.status.map(item => (
                      <Select.Option key={item.inventoryStatusId} value={item.inventoryStatusId}>
                        {item.inventoryStatusName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>


          <Form.Item label={t('Debit Name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox 
                    onChange={(value) => {
                      toggleFieldEnabled('debit')(true); 
                      form.setFieldsValue({ debtUserId: null })
                    }} />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="debtUserId"
                  noStyle
                  rules={[{ required: enabledFields.debit, message: t('inventory_status_name') }]}
                >
                  <Select
                    placeholder={t('Debit Name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.debit}
                  >
                    {statusData?.users.map(item => (
                      <Select.Option key={item.userId} value={item.userId}>
                        {item.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item label={t('Responsible Name')}>
            <Row align="middle" gutter={8}>
              <Col>
                <Checkbox onChange={(e) => toggleFieldEnabled('responsible')(e.target.checked)}
                 />
              </Col>
              <Col flex="auto">
                <Form.Item
                  name="responsibleUserList"
                  noStyle
                  rules={[{ required: enabledFields.responsible, message: t('Responsible Name') }]}
                >
                  <Select
                    placeholder={t('Responsible Name')}
                    style={{ width: '100%' }}
                    disabled={!enabledFields.responsible}
                    mode="multiple" 
                  >
                    {statusData?.users.map(item => (
                      <Select.Option key={item.userId} value={item.userId}>
                        {item.displayName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>


        </Form>
      </Modal>

      {/* Campus Modal */}
      <Modal
        visible={isModalVisibleCampus}
        footer={null}
        onCancel={handleCancelCampus}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '30vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddCampus savedObj={savedCampusObj} cancelModal={handleCancelCampus} />
        </div>
      </Modal>

        <Modal
        visible={isModalVisibleLocation}
        footer={null}
        onCancel={handleCancelLocation}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddLocation savedObj={savedLocationObj} cancelModal={handleCancelLocation} />
        </div>
      </Modal>

      <Modal
        visible={isModalVisibleDepartment}
        footer={null}
        onCancel={handleCancelDepartment}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddDepartment cancelModal={handleCancelDepartment} savedObj={savedDepartmentObj} />;
        </div>
      </Modal>

      <Modal
        visible={isModalVisibleCategory}
        footer={null}
        onCancel={handleCancelCategory}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddCategory cancelModal={handleCancelCategory} savedObj={savedCategoryObj}   />;
        </div>
      </Modal>

      <Modal
        visible={isModalTypeVisible}
        footer={null}
        onCancel={handleCancelType}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddType cancelModal={handleCancelType} savedObj={savedTypeObj}   />;
        </div>
      </Modal>

      <Modal
        visible={isModalTypeSupplier}
        footer={null}
        onCancel={handleCancelSupplier}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddSupplier cancelModal={handleCancelSupplier} savedObj={savedSupplierObj} />;
        </div>
      </Modal>

      
      <Modal
        visible={isModalBrandVisible}
        footer={null}
        onCancel={handleCancelBrand}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddBrand cancelModal={handleCancelBrand} savedObj={savedBrandObj}  />;
        </div>
      </Modal>
      
      <Modal
        visible={isModalStatusVisible}
        footer={null}
        onCancel={handleCancelStatus}
        width={450}
        style={{ top: 20 }}
        bodyStyle={{ padding: '0px', display: 'flex', flexDirection: 'column', height: '35vh' }}
      >
        <div style={{ flex: 1 }}>
          <AddInventoryStatus cancelModal={handleCancelStatus} savedObj={savedStatusObj} />;
        </div>
      </Modal>

    </>
  );
};
