import { Button, Card, Col, Form, Input, Row, Select } from "antd";
import { IPageProps } from "../../interfaces/page-data";
import HTTPService from "../../utils/makerequest";
import { toast } from "react-hot-toast";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { IAuthentication, UserRole } from "../../interfaces/authentication";
import { StateLoader } from "../../redux/StateLoader";
import { IUserData } from "../../interfaces/IUserData";
import { ICompany } from "../../interfaces/ICompany";
import { t } from "i18next";


const UpdateUser: React.FunctionComponent<IPageProps> = () => {
  const { userId } = useParams<any>();
  const [userData, setUserData] = useState<IUserData>();
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    UserData(userId)
    fetchData();
  }, [userId]);


  const fetchData = async () => {
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  }
  const update = (e) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = e.companyId;
    } else {

      companyIdToUse = userInfo?.companyId;
    }
    const updatedUser = {
      userEmail: e.email,
      firstName: e.firstname,
      lastName: e.lastname,
      role: e.userRole,
      companyId: companyIdToUse,
      userId: userId,
      personelStatus:e.personelStatus,
    };
    HTTPService.PUT(`/User/updateUser?userId=${userId}`, updatedUser)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t(res.data))
          window.location.replace('/admin/user-settings')
        }
        else {
          toast.error(t(res.data));
        }
      })
      .catch(error => {
        toast.error('There was an error!', error);
      });

  }

  const UserData = (userId: number) => {
    HTTPService.GET(`/User/byId?userId=` + userId)
      .then((res) => {
        if (!res.data) {
          return;
        }
        let tmp: IUserData = res.data;

        setUserData(tmp);
        setSelectedCompany(tmp.companyId);
      })
      .catch((error) => {
        console.error("API çağrısında bir hata oluştu:", error);
      });
  }
  const handleCancel = () => {
    window.location.replace('/admin/user-settings/');
  };

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('Update User')}</h3>{
      }
      <Form layout='vertical' onFinish={update}
        fields={[
          {
            name: ['email'],
            value: userData?.userEmail,
          },
          {
            name: ['firstname'],
            value: userData?.firstName,
          },
          {
            name: ['lastname'],
            value: userData?.lastName,
          },
          {
            name: ['userRole'],
            value: userData?.role,
          },
          {
            name: ['personelStatus'],
            value: userData?.personelStatus,
          },
          {
            name: ['companyId'],
            value: userData?.companyId,
          },

        ]}
      >

        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('select_company'),
              },
            ]}
          >

            <Select
              showSearch placeholder={t('company_name')}
              value={selectedCompany}
              style={{ marginBottom: '20px' }}
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>

          </Form.Item>
        ) : null}
        <Form.Item name='email'
          label={t('email')}
          rules={[
            {
              required: true,
              message: t('please_input_email'),
              whitespace: true,
            },
            {
              pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: t('invalidEmail'),
            },
          ]}
        >
          <Input
            prefix={<MailOutlined />}
            placeholder='E-Mail'
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item name='firstname'
          label={t('firstName')}
          rules={[
            {
              required: true,
              message: t('please_input_firstname'),
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: t('firstname_invalid')
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={t('firstName')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item name='lastname'
          label={t('lastName')}
          rules={[
            {
              required: true,
              message: t('please_input_lastname'),
              whitespace: true,
            },
            {
              pattern: /^[^\s]+$/,
              message: t('lastname_invalid'),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={t('lastName')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        <Form.Item
          name='userRole'
          label={t('role')}
          rules={[
            {
              required: true,
              message: t('please_select_role'),
            },
          ]}>
          <Select showSearch placeholder={t('role')}>
            {userInfo?.role === UserRole.AltisAdmin ? (
              <Select.Option value={4}>{t('AltisAdmin')}</Select.Option>
            ) : null}
             <Select.Option value={0}>{t('Sayman')}</Select.Option>
            <Select.Option value={1}>{t('Personel')}</Select.Option>
            <Select.Option value={2}>{t('Operator')}</Select.Option>
            <Select.Option value={3}>{t('Admin')}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='personelStatus'
          label={t('Personel Status')}
          rules={[{ required: true, message: 'please_select_personel_status'}]}>
          <Select showSearch placeholder={t('Personel Status')}>
          <Select.Option value={0}>{t('Active_Personnel')}</Select.Option>
            <Select.Option value={1}>{t('Allowed_Personnel')}</Select.Option>
            <Select.Option value={2}>{t('Resigner_Personnel')}</Select.Option>
            <Select.Option value={3}>{t('Dismissed_Personnel')}</Select.Option>
            <Select.Option value={4}>{t('Retired_Personnel')}</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: '5px' }}>
              {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: '5px' }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
}
export default UpdateUser;