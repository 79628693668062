import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, Col, Form, Input, Row, Select, SelectProps, Switch } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next';
import { ICampusData } from "../../../interfaces/ICampusData";

const AddPackage: React.FC<ICampusData> = ({}) => {
  const { t } = useTranslation();
  const loader = new StateLoader();

  useEffect(() => {
  }, []);

  const PackageRegister = (values) => {
    
    const newGroup = {
      packageName: values.packageName,
      assetLimit: values.assetLimit,
      userLimit: values.userLimit,
      featureRecords: values.featureRecords,
      extraFeatures: values.extralFeatures,
      isPdf: values.isPdf || false,
      isExcel: values.isExcel || false,
      isReporting: values.isReporting || false,
      isBarcode: values.isBarcode || false,
      isNfc: values.isNfc || false,
      isRfid: values.isRfid || false,
    };
    HTTPService.POST(`/LicencePackages/registerLicence`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t('package_registered_successfully'));
          window.location.replace('/admin/package-management');
        } else if (response.status === 450) {
          toast.error(t(response.data));
        }
        else {
          console.error("Lisans kaydı başarısız.");
          toast.error(t(response.data));
        }

      })
      .catch((error) => {
        console.error("Lisans kaydı başarısız: ", error);
      });
  }
 
  const handleCancel = () => {
    window.location.replace('/admin/package-management');
  }

  return (
    <Card style={{ padding: "50px" }}>
      <h3>{t('add-package')}</h3>
      <Form onFinish={PackageRegister} layout="vertical"
      >
       

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="packageName"
              label={t('packageName')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_package_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('packageName')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              name="assetLimit"
              label={t('assetLimit')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_asset_limit'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('assetLimit')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              name="userLimit"
              label={t('userLimit')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_user_limit'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('userLimit')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="featureRecords"
              label={t('featureRecords')}
              rules={[
                {
                  required: true,
                  message: t('please_input_feature_records'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('featureRecords')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              name="extralFeatures"
              label={t('extralFeatures')}
              rules={[
                {
                  required: true,
                  message: t('please_input_feature_extra'),
                  whitespace: true,
                },
              ]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t('extralFeatures')}
                maxLength={50}
                style={{ width: '100%', borderRadius: 0 }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={0}>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isPdf"
              label={t('isPdf')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isExcel"
              label={t('isExcel')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isReporting"
              label={t('isReporting')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isBarcode"
              label={t('isBarcode')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isNfc"
              label={t('isNfc')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col xs={4} sm={4} md={4} lg={4} xl={4}>
            <Form.Item
              name="isRfid"
              label={t('isRfid')}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddPackage;
