import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Checkbox, Form, Table, Input, DatePicker, Tooltip, Select } from "antd";
import { useTranslation } from 'react-i18next';
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import '../CountingManagement/AddCounting.css';
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { ICampusData } from "../../../interfaces/ICampusData";
import { ILocationData } from "../../../interfaces/ILocationData";
import { DeleteOutlined, TableOutlined, UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import { ICountingData, ICountingJobLocation, ICountingJobResponsible, IUser } from "../../../interfaces/ICountingData";
import moment from "moment";
import { IAllCampusWithLocation } from "../../../interfaces/IAllCampusWithLocation";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";


const CountingUpdate: React.FunctionComponent<IPageProps> = () => {
    const { countingJobId } = useParams<any>();
    const { t } = useTranslation();
    const loader = new StateLoader();
    const userInfo: IAuthentication = loader.loadAuthState();

    const [countingData, setCountingData] = useState<ICountingData>({}); // sayım bilgisi ve lokasyon bilgisi(campus bilgisi)

    const [selectedCampus, setSelectedCampus] = useState<ICampusData[]>([]); // seçilen kampüsler
    const [selectedLocation, setSelectedLocation] = useState<ILocationData[]>([]); // seçilen lokasyonlar
    const [selectedUser, setSelectedUser] = useState<IUser[]>([]); // seçilen userlar

    const [userData, setUserData] = useState([]);  // üstte tablodaki userlar
    const [allCampusWithLocation, setAllCampusWithLocation] = useState<IAllCampusWithLocation[]>([]); // üstte tablodaki lokasyon ve kampüs
    const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
    const [companyData, setCompanyData] = useState<ICompanyData[]>([]);



    const [form] = Form.useForm();
    useEffect(() => {
        if (countingJobId) {
            getCountingData(countingJobId);
        }
    }, [countingJobId]);

    const handleCancel = () => {
        window.location.replace("/admin/counting-management/");
    };


    const handleAddToTable = () => {
        const updatedCountingData = { ...countingData };

        selectedLocation.forEach(row => {
            const newSaveObj: ICountingJobLocation = {
                countingJobId: countingJobId,
                locationId: row.locationId,
                locationTBL: row,
                campusTBL: row.campusTBL, 
                locationName: row.locationName,
                countingJobResponsibleTBL: [],
            };
            
            selectedUser.forEach(user => {
                var tmpUser = {...user};
                const newCountingJobResponsible: ICountingJobResponsible = {
                    countingJobId: countingJobId,
                    responsibleUserId: tmpUser.userId,
                    locationId: row.locationId,
                    isNotification: false,
                    responsibleUserTBL: tmpUser,
                    locationTBL: row,
                };
                newSaveObj.countingJobResponsibleTBL = [
                    ...newSaveObj.countingJobResponsibleTBL,
                    newCountingJobResponsible,
                ];

            });

             updatedCountingData.countingJobLocationTBL = [
                ...updatedCountingData.countingJobLocationTBL,
                newSaveObj,
            ];

            updatedCountingData.countingJobResponsibleTBL = [
                ...updatedCountingData.countingJobResponsibleTBL,
                ...newSaveObj.countingJobResponsibleTBL, 
            ];
        });
        
        setSelectedLocation([]);
        setSelectedUser([]);
        setCountingData(updatedCountingData);
    };
    

      
    const getCountingData = async (countingJobId: number) => {
        try {
            const res = await HTTPService.GET(`/CountingJob/ById?countingJobId=${countingJobId}`);

            if (res.status === 200 && res.data) {
                const countingData: ICountingData = res.data;
                
                countingData.countingJobLocationTBL.forEach(row => {
                    row.countingJobResponsibleTBL = countingData.countingJobResponsibleTBL.filter(x => x.locationId == row.locationId)
                });
                setCountingData(countingData);

                form.setFieldsValue({
                    companyId: countingData.companyId,
                    locationId: countingData.countingJobLocationTBL?.map(location => location?.locationTBL?.locationId),
                    responsibleUserId: countingData.countingJobResponsibleTBL?.map(responsible => responsible?.responsibleUserTBL?.userId),
                    countingJobName: countingData.countingJobName,
                    startDate: moment(countingData.startDate),
                    finishDate: moment(countingData.finishDate),
                });

                getCampusWithLocation(res.data.companyId);

                fetchUser(res.data.companyId);

            } else {
                toast.error(res.error?.message || t('error_fetching_data'));
            }
        } catch (error) {
            console.error("Error fetching counting data:", error);
            toast.error(t('error_fetching_data'));
        }
    };

    //buraya bak
    const handleDeleteRow = (countingJobLocationId: number, campusId: number, locationId: number) => {
    
        const updatedCountingData = { ...countingData };
    
        updatedCountingData.countingJobLocationTBL = updatedCountingData.countingJobLocationTBL.filter((item) => {
            
            const campusIdFromItem = item.locationTBL?.campusTBL?.campusId;
    
            const shouldDelete = campusIdFromItem === campusId && item.locationId === locationId;
    
            return !shouldDelete;
        });
    
        setCountingData(updatedCountingData);
    };
    

    const handleCampusChange = (values: CheckboxValueType[]) => {
        let tmp: ILocationData[] = [];
    
        if (values.includes('selectAllCampus')) {
            // allCampusWithLocation.forEach(row => {
            //     row.locationTBL.forEach(location => {
            //         tmp.push({
            //             ...location,
            //             campus: {
            //                 campusId: row.campusId,
            //                 campusName: row.campusName
            //             }
            //         });
            //     });
            // });
            // setSelectedLocation(tmp);
            // setSelectedCampus(allCampusWithLocation); 
        } else {
            const selected = allCampusWithLocation.filter(row =>
                values.includes(row.campusId)
            );
    
            setSelectedCampus(selected);
    
            // selected.forEach(row => {
            //     row.locationTBL.forEach(location => {
            //         if (values.includes(location.locationId)) {
            //             const updatedLocation = {
            //                 ...location,
            //                 campus: {
            //                     campusId: row.campusId,
            //                     campusName: row.campusName
            //                 }
            //             };
            //             tmp.push(updatedLocation);
            //         }
            //     });
            // });
    
            // setSelectedLocation(tmp);
        }
    };
    
    
    const handleLocationChange = (values: CheckboxValueType[]) => {
        let tmp: ILocationData[] = [];
    
        if (values.includes('selectAll')) {
            // const allLocations = allCampusWithLocation.flatMap(row => row.locationTBL);
    
            // allLocations.forEach(location => {
            //     tmp.push({
            //         ...location,
            //         isSelected: true, 
            //     });
            // });
    
            // setSelectedLocation(tmp); 
        } else {
            allCampusWithLocation.filter(row =>
                row.locationTBL.some(location => values.includes(location.locationId))
            ).map(row => {
                row.locationTBL.forEach(location => {
                    if (values.includes(location.locationId)) {
                        tmp.push({
                            locationId:location.locationId,
                            campusId:row.campusId,
                            locationName:location.locationName,
                            campusTBL:row,
                        });
                    }
                });
            });
    
            setSelectedLocation(tmp); 
        }
    };
    
    const handleUserChange = (values: any[]) => {
        // selectedUsers dizisini kullanıcı verileriyle güncelle
    const selectedUsers = userData.filter(user => values.includes(user.userId));
    setSelectedUser(selectedUsers);
    
    };
    
    
    
    const fetchUser = async (companyId: number) => {
        try {
            const result = await HTTPService.GETUserNames(companyId);
            setUserData(result);
        } catch (error) {
            console.error(error);
            toast.error(t('error'));
        }
    };

    const getCampusWithLocation = (id: number) => {
        HTTPService.GET(`/Campus/allCampusWithLocationByCompany?companyId=${id}`)
            .then(response => {
                if (!response.data) {
                    return;
                }

                setAllCampusWithLocation(response.data);
            })
            .catch(error => {
                console.error('Error fetching pie data:', error);
            });
    };

    const handleCompanyChange = (value: number) => {
        setSelectedCompany(value);
        getCampusWithLocation(value)
        fetchUser(value || userInfo?.companyId);
    };

    const updateCounting = async () => {
        try {

            const locationUserIdsToSend = countingData.countingJobLocationTBL.reduce((acc, location) => {
                const locationId = location.locationTBL.locationId;
            
                if (!acc[locationId]) {
                    acc[locationId] = [];
                }
            
                    if (Array.isArray(location.countingJobResponsibleTBL)) {
                            location.countingJobResponsibleTBL.forEach(responsible => {
                                if (!acc[locationId].includes(responsible.responsibleUserId)) {
                                    acc[locationId].push(responsible.responsibleUserId);
                                }
                            });
                    }

                    selectedUser.forEach(user => {
                        if (!acc[locationId].includes(user.userId)) {
                            acc[locationId].push(user.userId);
                        }
                    });

            
                return acc;
            }, {});
             
            
            const countingJobTBL = {
                countingJobId: countingJobId,
                companyId:userInfo?.companyId,
                countingJobName: form.getFieldValue("countingJobName"),
                startDate: form.getFieldValue("startDate")?.toISOString(),
                finishDate: form.getFieldValue("finishDate")?.toISOString(),
            };
    
            const dataToSend = {
                countingJobTBL: countingJobTBL,
                locationUserId: locationUserIdsToSend,
            };

            const res = await HTTPService.PUT('/CountingJob/updateCounting', dataToSend);
    
            if (res.status === 200) {
                toast.success(t('update_success'));
                window.location.replace("/admin/counting-management");
            } else if (res.status === 400) {
                toast.error(t(res.data));
            }else {
                toast.error(t('error'));
            }
        } catch (error) {
            console.error("Error during updateCounting:", error);
            toast.error(t('counting_update_failed'));
        }
    };

    const columns = [
        {
            title: t('Campus'),
            //dataIndex: 'campusName',
            key: 'campus',
            render: (order) => <div>{order?.locationTBL?.campusTBL?.campusName}</div>,
        },
        {
            title: t('Location'),
            //dataIndex: 'locations',
            key: 'locations',
            render: (order) => <div>{order?.locationTBL?.locationName}</div>,
        },
        {
            title: t('responsible'),
            //dataIndex: 'users',
            key: 'displayName',
            render: (order) => {

                const responsibleUsers = order?.countingJobResponsibleTBL?.map(responsible => 
                     `${responsible.responsibleUserTBL.firstName} ${responsible.responsibleUserTBL.lastName}`
                ).join(", ");

                // const responsibleUsers = countingData?.countingJobResponsibleTBL?.filter(row => row.locationId == order.locationId)
                // .map(responsible => {
                //         return `${responsible.responsibleUserTBL.firstName} ${responsible.responsibleUserTBL.lastName}`
                // }
                //     )
                // .join(", ");

                return <div>{responsibleUsers}</div>;
            },
        },
        {
            title: t('operations'),
            key: 'operations',
            render: (_: any, record: any, index: number) => {
                // record nesnesini konsola yazdır
        
                return (
                    <Button
                        onClick={() => handleDeleteRow(record.countingJobLocationId, record.locationTBL.campusTBL.campusId, record.locationId)}
                        icon={<DeleteOutlined />}
                    >
                    </Button>
                );
            }
        }        
    ];

    const GetLocationWidget = () => {
        let checkbox: any[] = [];
    
        // checkbox.push(
        //     <div key="selectAll">
        //         <Checkbox
        //             value={"selectAll"}
        //             checked={selectedLocation.length === allCampusWithLocation.flatMap(row => row.locationTBL).length}
        //             onChange={e => {
        //                 const allLocationIds = allCampusWithLocation
        //                     .filter(row => selectedCampus.some(x => x.campusId === row.campusId)) 
        //                     .flatMap(row => row.locationTBL.map(location => location.locationId));
    
        //                 if (e.target.checked) {
        //                     handleLocationChange(allLocationIds); 
        //                 } else {
        //                     handleLocationChange([]); 
        //                 }
        //             }}
        //         >
        //             {t('selectAll')}
        //         </Checkbox>
        //     </div>
        // );
    
        allCampusWithLocation
            .filter(filterRow => selectedCampus.some(x => x.campusId === filterRow.campusId))
            .map((mapRow) => {
                mapRow.locationTBL.filter(row => countingData.countingJobLocationTBL.filter(x => x.locationId === row.locationId).length == 0)
                .forEach((location: any) => {
                    checkbox.push(
                        <div key={location.locationId}>
                            <Checkbox
                                value={location.locationId}
                                checked={selectedLocation.some(selLoc => selLoc.locationId === location.locationId && selLoc.isSelected)}
                                onChange={() => {
                                    const updatedSelected = selectedLocation.some(selLoc => selLoc.locationId === location.locationId)
                                        ? selectedLocation.filter(selLoc => selLoc.locationId !== location.locationId)
                                        : [...selectedLocation, location]; 
                                    handleLocationChange(updatedSelected.map(loc => loc.locationId)); 
                                }}
                            >
                                {location.locationName}
                            </Checkbox>
                        </div>
                    );
                });
            });
    
        return checkbox;
    };
    
    
    return (
        <Card style={{ padding: "50px" }}>
            <h3>{t('Update Counting')}</h3>
            <Form
                form={form}
                onFinish={updateCounting}
                layout="vertical"
            >

                <Form.Item
                    name="countingJobName"
                    label={t('Counting Job Name')}
                    rules={[{ required: true, message: t('inputCounting'), whitespace: true }]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        style={{ borderRadius: 0 }}
                        placeholder={t('Counting Job Name')}
                    />
                </Form.Item>


                <Form.Item
                    name='startDate'
                    label={t('Counting Start Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseCounting'),
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t('Counting Start Date')}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                    />
                </Form.Item>

                <Form.Item
                    name='finishDate'
                    label={t('Counting Finish Date')}
                    rules={[
                        {
                            required: true,
                            message: t('pleaseCountingFinish'),
                        },
                    ]}
                >
                    <DatePicker
                        style={{ width: '100%', borderRadius: 0 }}
                        placeholder={t('Counting Finish Date')}
                        format="DD.MM.YYYY HH:mm"
                        showTime={{ format: 'HH:mm' }}
                    />
                </Form.Item>


                <Form.Item>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item label={t('campus')}>
                                <div className="checkbox-group-container">
                                    <Checkbox.Group
                                        value={selectedCampus.map(campus => campus.campusId)}
                                        onChange={handleCampusChange}
                                    >
                                            <div key="selectAllCampus" style={{ marginLeft: '7px' }}>
                                                <p
                                                    onClick={() => {
                                                        if (selectedCampus.length === allCampusWithLocation.length) {
                                                            handleCampusChange([]);
                                                        } else {
                                                            const allCampusIds = allCampusWithLocation.map(row => row.campusId);
                                                            handleCampusChange(allCampusIds); 
                                                        }
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                        padding: '5px',
                                                        display: 'inline-block',
                                                        marginLeft:'-2px',
                                                        userSelect: 'none', 
                                                        marginTop: '5px', 

                                                    }}
                                                >
                                                    {t('selectAll')}
                                                </p>
                                            </div>
                                        {/* <div key="selectAllCampus" style={{marginLeft:'7px'}}>
                                            <Checkbox
                                                value={"selectAllCampus"}
                                                onChange={e => {
                                                    if (e.target.checked) {
                                                        const allCampusIds = allCampusWithLocation.map(row => row.campusId); 
                                                        handleCampusChange(allCampusIds); 
                                                    } else {
                                                        handleCampusChange([]); 
                                                    }
                                                }}
                                            >
                                                {t('selectAll')}
                                            </Checkbox>
                                        </div> */}
                                        {
                                            allCampusWithLocation.map(mapRow => 
                                                  mapRow.locationTBL.filter(row =>  countingData.countingJobLocationTBL.some(x => x.locationId === row.locationId)).length != mapRow.locationTBL.length
                                            )
                                        }
                                        {allCampusWithLocation
                                        .filter(mapRow =>  
                                            mapRow.locationTBL.filter(row =>  countingData.countingJobLocationTBL.some(x => x.locationId === row.locationId)).length != mapRow.locationTBL.length
                                        )
                                        .map((row: any, index: number) => (
                                            <div key={`${row.campusId}-${index}`} className="checkbox-container">
                                                <Checkbox value={row.campusId}>{row.campusName}</Checkbox>
                                            </div>
                                        ))}
                                    </Checkbox.Group>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                        <Form.Item label={t('location')}>
                                    <div className="checkbox-group-container">
                                        <div>
                                        <p
                                            onClick={() => {
                                            const selectedCampusIds = selectedCampus.map(campus => campus.campusId);
                                            const totalLocationIds = allCampusWithLocation
                                            .filter(campus => selectedCampusIds.includes(campus.campusId)) 
                                            .flatMap(campus => campus.locationTBL.map(location => location.locationId));

                                            const countingLocationIds = countingData.countingJobLocationTBL.map(item => item.locationId);
                                            const filteredLocationIds = totalLocationIds.filter(locationId => !countingLocationIds.includes(locationId));

                                            if (selectedLocation.length === filteredLocationIds.length) {
                                                handleLocationChange([]); 
                                            } else {
                                                handleLocationChange(filteredLocationIds); 
                                            }
                                            }}
                                            style={{
                                            cursor: 'pointer',
                                            fontWeight: 'bold',
                                            padding: '5px',
                                            display: 'inline-block',
                                            marginLeft: '-2px',
                                            userSelect: 'none',
                                            marginTop: '5px',
                                            }}
                                        >
                                            {t('selectAll')}
                                        </p>
                                        </div>

                                        <Checkbox.Group value={selectedLocation.map(loc=>loc.locationId)} onChange={handleLocationChange}>
                                        <div className="checkbox-container">
                                            {GetLocationWidget()} 
                                        </div>
                                        </Checkbox.Group>
                                    </div>
                            </Form.Item>

                        </Col>

                        <Col span={8}>
                            <Form.Item label={t('responsible')}>
                            <div className="checkbox-group-container">
                                <Checkbox.Group
                                    value={selectedUser.map(user => user.userId)}
                                    onChange={handleUserChange}
                                >
                       
                       <div key="all" style={{ marginLeft: '7px' }}>
                                                <p
                                                    onClick={() => {
                                                        if (selectedUser.length === userData.length) {
                                                            handleUserChange([]);
                                                        } else {
                                                            const allUserIds = userData.map(row => row.userId);
                                                            handleUserChange(allUserIds); 
                                                        }
                                                    }}
                                                    style={{
                                                        cursor: 'pointer',
                                                        fontWeight: 'bold',
                                                        padding: '5px',
                                                        display: 'inline-block',
                                                        marginLeft:'-10px',
                                                        userSelect: 'none', 
                                                        marginTop: '5px', 

                                                    }}
                                                >
                                                    {t('selectAll')}
                                                </p>
                                            </div>

                                {userData.map(user => (
                                    <div key={user.userId}>
                                        <Checkbox value={user.userId}>{user.displayName}</Checkbox>
                                    </div>
                                ))}
                            </Checkbox.Group>
                        </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>

                <Col xs={8} sm={4}>
                    <Tooltip title={t('addTable')}>
                          <Button
                            block
                            onClick={handleAddToTable}
                            type="primary"
                            style={{
                                borderRadius: '5px',
                                width: '140px',
                                height: '45px',
                                border: 'none',
                                boxShadow: '0 4px 15px rgba(0,0,0,0.2)',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '8px',
                                transition: 'all 0.3s ease'
                            }}
                            onMouseOver={(e) => {
                                e.currentTarget.style.transform = 'translateY(-2px)';
                                e.currentTarget.style.boxShadow = '0 6px 20px rgba(0,0,0,0.25)';
                            }}
                            onMouseOut={(e) => {
                                e.currentTarget.style.transform = 'translateY(0)';
                                e.currentTarget.style.boxShadow = '0 4px 15px rgba(0,0,0,0.2)';
                            }}
                            >
                            <TableOutlined style={{ fontSize: '18px' }} />
                            <span>{t('addTable')}</span>
                            </Button>

                    </Tooltip>
                </Col>
                <br />
                <Form.Item>
                    <Table
                        dataSource={countingData.countingJobLocationTBL}
                        columns={columns} 
                        pagination={false}
                        key={"key"}
                    />
                </Form.Item>

                <Form.Item>
                    <Row gutter={[8, 8]} justify="end" align="middle">
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                onClick={handleCancel}
                                type="primary"
                                style={{ borderRadius: '5px' }}
                            >
                                {t('cancel')}
                            </Button>
                        </Col>
                        <Col xs={12} sm={4}>
                            <Button
                                block
                                htmlType="submit"
                                type="primary"
                                style={{ borderRadius: '5px' }}
                            >
                                {t('update')}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default CountingUpdate;
