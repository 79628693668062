import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import { ITypeData } from "../../../interfaces/ITypeData";
import i18n from "../../../i18n";

export interface IType {
  count?: number;
  data?: ITypeData[];
}

const TypeManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const [typeData, setTypeData] = useState<IType>({});
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('type_management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    TypeFilter();
  }, [i18n.language]);

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: t('tcp_client') },
    { id: 1, name: t('tcp_server') },
    { id: 2, name: t('web_socket_client') },
    { id: 3, name: t('web_service') },
    { id: 4, name: t('serial_port') },
    { id: 5, name: t('web_socket_server') }
  ];

  const columns: IAltisDataGridColumns[] = [
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'input'
    }] : []),
    { title: t('category_name'),  isSorting:true, key: 'categoryName', placeHolder: t('category_name'), inputType: "input" },
    { title: t('type_name'),  isSorting:true, key: 'typeName', placeHolder: t('type_name'), inputType: "input" },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure')} <br /></div>}
              onConfirm={() => DeleteType(record.typeId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
              <Tooltip title={t('delete')}>
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={(e) => { e.stopPropagation(); }}
                  style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
                >
                </Button>
              </Tooltip>
            </Popconfirm>

            <Tooltip title={t('update')}>
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => window.location.replace('/admin/update-type/' + record.typeId)}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              >
              </Button>
            </Tooltip>
          </Space>
        </div>
      ),
    }
  ];

  const TypeFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      typeName: e?.typeName,
      companyName: e?.companyName,
      categoryName:e?.categoryName
    };

    // console.log(body)

    HTTPService.POST(`/Type/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setTypeData(response.data);
      } else {
        toast.error(response.data);
      }
      const tmp: IType = response.data;
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setTypeData(tmp);
    });
  };

  const DeleteType = (Id) => {
    HTTPService.DELETE(`/Type/deleteType?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius: '5px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-type')}
          >
            {t('add_new_type')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className='table-container'>
            <AltisDataGrid data={typeData.data} total={typeData.count} columns={columns} onChange={TypeFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TypeManagement;
