
import Cookies from 'js-cookie';
import { TagAndTraceAuth } from '../utils/Constants';

export class StateLoader {

    loadAuthState() {
        try {
            let serializedState = Cookies.get(TagAndTraceAuth);
            if(serializedState != null)
            {
                return JSON.parse(serializedState);
            }
            else
            {
                return null;
            }
        }
        catch(ex)
        {
            console.log(ex)
        }



    }

    saveAuthState(state) {

        try {
            let serializedState = JSON.stringify(state);
            Cookies.set(TagAndTraceAuth, serializedState);

        }
        catch (err)
         {
            console.log(err);
        }
    }

    removeAuthLoader() {
        try{
 
            Cookies.remove(TagAndTraceAuth);
            window.location.replace('/public/sign-in/');
        }
        catch(ex){
            console.log(ex);
        }
    };

    setLanguage(state:string) {

        try {
            Cookies.set("Language", state);

        }
        catch (err)
            {
            console.log(err);
        }
    }

    getLanguage() {
        try {
            let serializedState = Cookies.get("Language");
            if(serializedState != null)
            {
                return serializedState;
            }
            else
            {
                return null;
            }
        }
        catch(ex)
        {
            console.log(ex)
        }
    }

}