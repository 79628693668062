import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Input, message, Popconfirm, Space, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import "../CompanyManagement/CustomerManagement.css";
import { t } from "i18next";
import i18n from "../../../i18n";

export interface ICompany {
  count?: number;
  data?: ICompanyData[];
}

const CustomerManagement: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const [companyData, setCompanyData] = useState<ICompany>({});
  const [inputValue, setInputValue] = useState('');
  const [visible, setVisible] = useState(false);


  const pageData: IPageData = {
    title: t('customer_management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    CompanyFilter()
  }, [i18n.language]);

  const columns: IAltisDataGridColumns[] = [
    {
      title: t('Customer Name'),
      key: 'companyName',
      placeHolder: t('Customer Name'),
      isSorting:true,
      inputType: "input",
    },
    {
      title: t('city'),
      key: 'companyCity',
      placeHolder: t('city'),
      isSorting:true,
      inputType: "input",
    },
    {
      title: t('firstName'),
      key: 'firstName',
      placeHolder: t('firstName'),
      isSorting:true,
      inputType: "input",
    },
    {
      title: t('lastName'),
      key: 'lastName',
      placeHolder: t('lastName'),
      isSorting:true,
      inputType: "input",
    },
    {
      title: t('telephone'),
      key: 'telephone',
      isSorting:true,
      placeHolder: t('telephone'),
      inputType: "input",
    },
    {
      title: t('email'),
      key: 'userEmail',
      isSorting:true,
      placeHolder: t('email'),
      inputType: "input",
    },
    {
      title: t('Operations'),
      key: 'operations',
      placeHolder: t('Operations'),
      inputType: "operations",

      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={
                <div>
                  {t('deleteConfirmation')} <strong>{record.companyName}</strong> ? <br />
                  {t('deleteConfirmation2')}
                  <Input
                    placeholder={t('deleteTypeCompany')}
                    onChange={(e) => setInputValue(e.target.value)}
                    style={{ marginTop: 10 }}
                  />
                </div>
              }
              okText={t('yes')}
              cancelText={t('no')}
              onCancel={() => setVisible(false)}
              onConfirm={() => {
                if (inputValue === record.companyName) {
                  DeleteCompany(record.companyId);
                } else {
                  // console.log("başarısız")
                }
              }}
            >
              <Tooltip title={t('delete')}>
                <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  style={{ width: '60px', borderRadius: '70px' }}
                  onClick={(e) => { e.stopPropagation(); setVisible(true); }}
                >
                </Button>
              </Tooltip>


            </Popconfirm>
            <Tooltip title={t('update')}>

              <Button
                size='small'
                icon={<EditOutlined />}
                style={{ width: '60px', borderRadius: '70px' }}
                onClick={() => {
                  const companyId = record.companyId;
                  window.location.replace('/admin/update-company/' + companyId);
                }
                }
              ></Button>
            </Tooltip>

          </Space>
        </div>
      ),
    },
  ];

  const CompanyFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';
    var body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      companyName: e?.companyName,
      companyCity: e?.companyCity,
      responsibleFirstname: e?.firstName,
      responsibleLastname: e?.lastName,
      responsibleTelephone: e?.telephone,
      responsibleEmail: e?.userEmail,
    };

    HTTPService.POST(`/Company/filters`, body).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status == 200) {
        setCompanyData(response.data)
        //console.log(response.data)
      } else {
        toast.error(t(response.data))
      }
      let tmp: ICompany = response.data;

      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setCompanyData(tmp);
    });
  };

  const DeleteCompany = (Id) => {
    HTTPService.DELETE(`/Company/deleteCompany?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        }
        else {
          toast.error(t('error450'));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">

          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius:'5px', marginBottom: 20 }}
            size='middle'
            onClick={() => window.location.replace('/admin/add-customer')}
          >
            {t('add_new_customer')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-container">
            <AltisDataGrid data={companyData.data} total={companyData.count} columns={columns} onChange={CompanyFilter} />
          </div>
        </div>

      </div>
    </>
  );
}
export default CustomerManagement;