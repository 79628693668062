import { Button, Card, Form, Input } from "antd";
import { toast } from "react-hot-toast";
import { LockOutlined } from "@ant-design/icons";
import { useParams } from 'react-router-dom';
import HTTPService from "../../../utils/makerequest";
import { IPageProps } from "../../../interfaces/page-data";
import { StateLoader } from "../../../redux/StateLoader";
import { IAuthentication } from "../../../interfaces/authentication";
import { t } from "i18next";

const PagePasswordChange: React.FunctionComponent<IPageProps> = () => {
    const { userId } = useParams<any>();

    const loader = new StateLoader();
    const authInfo: IAuthentication = loader.loadAuthState();

    const update = (values) => {
        if (values.retryNewPassword === values.newPassword) {
            HTTPService.PUT(`/User/PasswordChange?email=${authInfo.email}&oldPassword=${values.oldPassword}&newPassword=${values.newPassword}`, {})
                .then((res) => {
                    if (res.status === 200) {
                        toast.success(t(res.data));
                    } else if (res.status === 450) {
                        toast.error(t(res.data));
                    }  else {
                        toast.error(t(res.data));
                    }
                })
                .catch(error => {
                    toast.error(t('There was an error!'));
                });
        } else {
            toast.error(t('Your New Password Does Not Match!!!'));
        }
    };

    return (
        <Card style={{ padding: '50px' }}>
            <h3>{t('Reset Password')}</h3>
            <Form layout='vertical' onFinish={update}>
                <Form.Item
                    name='oldPassword'
                    label={t('Old Password')}
                    rules={[{ required: true, message: t('Please input your old password!') }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder={t('Old Password')}
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item
                    name='newPassword'
                    label={t('New Password')}
                    rules={[{ required: true, message: t('Please input your new password!') }]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder={t('New Password')}
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item
                    name='retryNewPassword'
                    label={t('Retry New Password')}
                    dependencies={['newPassword']}
                    hasFeedback
                    rules={[
                        { required: true, message: t('Please confirm your new password!') },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(t('The two passwords do not match!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        placeholder={t('Retry New Password')}
                        style={{ borderRadius: 0 }}
                    />
                </Form.Item>
                <Form.Item>
                    <div className='span d-flex justify-content-between'>
                        <span />
                        <Button htmlType="submit" type="primary" style={{ borderRadius: 0 }}>
                            {t('save')}
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Card>
    );
}

export default PagePasswordChange;
