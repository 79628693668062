
export interface IAuthentication {
    email: string;
    firstname: string;
    lastname: string;
    token:string;
    role:UserRole;
    companyId: number;
    licenceEndDates?:Date;
}
export enum UserRole {
    Sayman = 0,
    Personel = 1,
    Operator = 2,
    Admin=3,
    AltisAdmin = 4,
  }