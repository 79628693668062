import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { UserOutlined } from "@ant-design/icons";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IPageProps } from "../../../interfaces/page-data";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next'; // import useTranslation hook
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { ITypeData } from "../../../interfaces/ITypeData";

const AddType: React.FC<ITypeData> = ({ savedObj,cancelModal,companyId,categoryId,typeName})  => {
  const { t } = useTranslation(); 
  const [categoryData, setCategoryData] = useState<ICategoryData[] | undefined>(undefined);
  const [companyData, setCompanyData] = useState<ICompanyData[] | undefined>(undefined);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [selectedCategory, setSelectedCategory] = useState<number>();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const isCompanyIdAvailable = !!companyId;
  const isCategoryIdAvailable = !!categoryId;


  useEffect(() => {
    fetchCompanyData();
    fetchCategoryData();
  }, []);

  useEffect(() => {
    fetchCompanyData();
    if (companyId) {
      fetchCategoryByCompany(companyId);
    }
  }, [companyId]);

  // useEffect(() => {
  //   if (selectedCompany || userInfo.companyId ) {
  //     fetchCategoryByCompany(selectedCompany || userInfo.companyId );
  //   } else {
  //     setCategoryData([]);
  //   }
  // }, [selectedCompany]);

  useEffect(() => {
   if (selectedCompany || (userInfo.role !== UserRole.AltisAdmin && userInfo.companyId)) {
      const companyIdToUse = selectedCompany || userInfo.companyId;
      fetchCategoryByCompany(companyIdToUse);
    } else {
      setCategoryData([]);
    }
  }, [selectedCompany, userInfo.companyId, userInfo.role]);


  const fetchCompanyData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Şirket verilerini alırken bir hata oluştu:", error);
    }
  };

  const fetchCategoryData = async () => {
    try {
      const result = await HTTPService.GETCategoryNames();
      setCategoryData(result);
    } catch (error) {
      console.error("Kategori verilerini alırken bir hata oluştu:", error);
    }
  };

  const fetchCategoryByCompany = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCategoryByCompany(companyId);
      setCategoryData(result);
    } catch (error) {
      console.error("Kategori verilerini alırken bir hata oluştu:", error);
      setCategoryData([]);
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const TypeRegister = (values) => {
    let companyIdToUse;
    if (userInfo?.role === UserRole.AltisAdmin) {
      companyIdToUse = setSelectedCompany(values.companyId);
    } else {
      companyIdToUse = userInfo?.companyId;
    }
    const newGroup = {
      typeName: values.typeName || typeName,
      categoryId: values.categoryId || categoryId,
      companyId: companyIdToUse ||companyId ,
    };

    HTTPService.POST(`/Type/registerType`, newGroup)
      .then((response) => {
        if (response.status === 200) {
          const currentUrl = window.location.href;
          const redirectUrl = 'admin/add-type';
          if (currentUrl.includes(redirectUrl)) {
              window.location.replace('admin/type-management');
          }
          else
          {
            savedObj(response.data)
            cancelModal();
          }
        } 
        else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        console.error("Tip kaydı başarısız: ", error);
        toast.error("Type registration failed");
      });
  };

  const handleCategoryChange = (value:number)=>{
    setSelectedCategory(value)
  }


  const handleCancel = () => {
    const currentUrl = window.location.href;
    const addInventoryUrl = 'admin/add-inventory';
    const updateInventoryUrl = 'admin/update-inventory';
    const inventoryManage = 'admin/inventory-management';

    if (currentUrl.includes(addInventoryUrl)) {
        cancelModal();
    } else if (currentUrl.includes(updateInventoryUrl)) {
        cancelModal();
    }else if(currentUrl.includes(inventoryManage)){
      cancelModal();
    } else {
        window.location.replace("/admin/type-management/");
    }
  };


  return (
    <Card style={{ padding: "50px" }}>
      <h3>{t('register_type')}</h3>
      <Form onFinish={TypeRegister} layout="vertical" 
        fields={[
          { name: ["selectedCompany"], value: companyId },
          { name: ["selectedCategory"], value: categoryId },
        ]}
      >
        {!isCompanyIdAvailable&&userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            //name='selectedCompany'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('company_name')}
              style={{ marginBottom: '6px' }}
              onChange={(value) => setSelectedCompany(value)}
              filterOption={filterOption}
            >
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
      
        <Row gutter={16}>
          <Col span={24}>

          {!isCategoryIdAvailable &&(
             <Form.Item
             name='categoryId'
             label={t('item_group')}
             rules={[
               {
                 required: true,
                 message: t('please_select_a_item_group'),
               },
             ]}
           >
             <Select
               showSearch
               placeholder={t('item_group')}
               style={{ marginBottom: '6px' }}
               filterOption={filterOption}
               onChange={handleCategoryChange}
             >
               {categoryData?.map((category) => (
                 <Select.Option key={category.categoryId} value={category.categoryId}>
                   {category.categoryName}
                 </Select.Option>
               ))}
             </Select>
           </Form.Item>
          )}
           
            <Form.Item
            name="typeName"
            label={t('type_name')}
            rules={[
              {
                required: true,
                message: t('please_input_your_type_name'),
                whitespace: true,
              }
            ]}
          >
            <Input
              prefix={<UserOutlined />}
              style={{ borderRadius: 0, width: '100%' }}
              placeholder={t('type_name')}
              maxLength={50}
            />
          </Form.Item>
        </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button
                block
                onClick={handleCancel}
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button
                block
                htmlType="submit"
                type="primary"
                style={{ borderRadius: '5px' }}
              >
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddType;
