import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams} from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; 
import { ISupplierData } from "../../../interfaces/ISupplierData";
import form from "antd/lib/form";
import { ISupplier } from "./SupplierManagement";
import { IPageProps } from "../../../interfaces/page-data";

const UpdateSupplier: React.FunctionComponent<IPageProps> = () => {
  const { supplierId } = useParams<any>();
  const [supplierData, setSupplierData] = useState<ISupplierData | null>(null);
  const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation();

  useEffect(() => {
    fetchCompanyData();
    if (supplierId) {
      fetchSupplierData(supplierId);
    }
  }, [supplierId]);

  useEffect(() => {
    if (supplierId!=null) {
      fetchSupplierData(supplierId);
    } else if (supplierData?.supplierId) {
      fetchSupplierData(supplierData.supplierId);
    }
  }, [supplierId, supplierData?.supplierId]);


  const fetchCompanyData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchSupplierData = async (id: number) => {
    try {
      const res = await HTTPService.GET(`/Supplier/ById?supplierId=${id}`);
      if (res.status === 200) {
        setSupplierData(res.data);
      } else {
        toast.error(res.error.message);
      }
    } catch (error) {
      console.error("Error fetching supplier data:", error);
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const handleUpdateSupplier = async (formData: { supplierName: string; companyId?: string }) => {
    try {
      const updatedSupplier = {
        supplierId: supplierId,
        supplierName: formData.supplierName,
        companyId: formData.companyId || userInfo.companyId,
      };

      const res = await HTTPService.PUT(`/Supplier/updateSupplier`, updatedSupplier);
      if (res.status === 200) {
        toast.success(t('supplier_updated_successfully'));
          window.location.replace("/admin/supplier-management");
      }else if(res.status===450){
        toast.error(t(res.data))
      }
      else {
        toast.error(t(res.data));
      }
    } catch (error) {
      toast.error(t('error_updating_supplier'));
    }
  };

  const handleCancel = () => {
    window.location.replace("/admin/supplier-management/");
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleUpdateSupplier}
      fields={[
        { name: ["companyId"], value: supplierData?.companyId},
        { name: ["supplierName"], value:  supplierData?.supplierName},
      ]}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_supplier')}</h3>
        {userInfo?.role === UserRole.AltisAdmin && (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} style={{ marginBottom: '16px' }} filterOption={filterOption}>
              {companyData.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="supplierName"
          label={t('supplier_name')}
          rules={[
            {
              required: true,
              message: t('please_input_your_supplier_name'),
              whitespace: true,
            },
          ]}
        >
          <Input
            style={{ borderRadius: 0 }}
            placeholder={t('supplier_name')}
          />
        </Form.Item>

        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: '5px' }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateSupplier;
