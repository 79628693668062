import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import AltisDataGrid, { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import i18n from "../../../i18n";
import { ICloudConnector } from "../../../interfaces/ICloudConnector";

interface IFilterData {
  count?: number;
  data?: ICloudConnector[];
}

const CloudConnectorManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;

  const [cloudConnectList, setCloudConnectList] = useState<IFilterData>();
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('cloud_connector_management'), 
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    CloudConnectorFilter();
  }, [i18n.language]);

  const columns: IAltisDataGridColumns[] = [
    {
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'input'
    },
    { title: t('cloud_connector_name'),isSorting:true, key: 'cloudConnectorName', placeHolder: t('cloud_connector_name'), inputType: "input" },
    { title: t('cloud_connector_guid'),isSorting:true, key: 'connectorGuid', placeHolder: t('cloud_connector_guid'), inputType: "input" },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure_category')} <br /></div>}
              onConfirm={() => DeletecloudConnector(record.cloudConnectorId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
            <Tooltip title={t('delete')}>
              <Button
                  size='small'
                icon={<DeleteOutlined />}
                onClick={(e) => { e.stopPropagation(); }}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              >
              </Button>
            </Tooltip>
         
            </Popconfirm>

            <Tooltip title={t('update')}>
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => window.location.replace('/admin/edit-cloud-connector/' + record.cloudConnectorId)}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              >
              </Button>
            </Tooltip>
          </Space>
        </div>
      ),
    }
  ];

  const CloudConnectorFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';
    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      cloudConnectorName: e?.cloudConnectorName,
    };

    HTTPService.POST(`/CloudConnector/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setCloudConnectList(response.data);
      } else {
        toast.error(response.data);
      }
    });
  };

  const DeletecloudConnector = (Id) => {
    HTTPService.DELETE(`/cloudConnector/delete?cloudConnectorId=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else if(response.status != 450) {
          toast.error(t(response.data));
        }
      }
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-4 mb-3">
          <Button
            type='primary'
            shape='round'
            style={{ height: 40, borderRadius:'5px', marginBottom: 20, display: userInfo.role === UserRole.Sayman ? 'none' : 'block' }}
            size='middle'
            onClick={() => window.location.replace('/admin/edit-cloud-connector/0')}
          >
            {t('add_new_cloud_connector')}
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-container">
            {
              <AltisDataGrid data={cloudConnectList?.data} total={cloudConnectList?.count} columns={columns} onChange={CloudConnectorFilter} />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudConnectorManagement;
