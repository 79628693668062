import React, { useEffect, useState } from "react";
import { Card, Form, Input, Button, Select, Col, Row, DatePicker } from "antd";
import { UserOutlined, HomeOutlined, PhoneOutlined, MailOutlined, InboxOutlined } from "@ant-design/icons";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import HTTPService from "../../../utils/makerequest";
import toast from "react-hot-toast";
import { IAuthentication } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { useTranslation } from 'react-i18next';
import { IUser } from "../../../interfaces/IUser";
import i18n from "../../../i18n";
import { styleText } from "util";
import moment from "moment";


const { Option } = Select;

const CompanyManagement: React.FunctionComponent<IPageProps> = props => {
  const { onSetPage } = props;
  const [companyData, setCompanyData] = useState<ICompanyData>({});
  const [userData, setUserData] = useState<IUser[]>();
  const { t } = useTranslation();


  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();

  const pageData: IPageData = {
    title: t('company_management'),
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    getCompanyData();
    getUserData();
  }, [i18n.language]);

  const cities = [
    'Adana', 'Adıyaman', 'Afyonkarahisar', 'Ağrı', 'Amasya', 'Ankara', 'Antalya',
    'Artvin', 'Aydın', 'Balıkesir', 'Bilecik', 'Bingöl', 'Bitlis', 'Bolu',
    'Burdur', 'Bursa', 'Çanakkale', 'Çankırı', 'Çorum', 'Denizli', 'Diyarbakır',
    'Edirne', 'Elazığ', 'Erzincan', 'Erzurum', 'Eskişehir', 'Gaziantep', 'Giresun',
    'Gümüşhane', 'Hakkâri', 'Hatay', 'Isparta', 'Mersin', 'İstanbul', 'İzmir',
    'Kars', 'Kastamonu', 'Kayseri', 'Kırklareli', 'Kırşehir', 'Kocaeli', 'Konya',
    'Kütahya', 'Malatya', 'Manisa', 'Kahramanmaraş', 'Mardin', 'Muğla', 'Muş',
    'Nevşehir', 'Niğde', 'Ordu', 'Rize', 'Sakarya', 'Samsun', 'Siirt', 'Sinop',
    'Sivas', 'Tekirdağ', 'Tokat', 'Trabzon', 'Tunceli', 'Şanlıurfa', 'Uşak',
    'Van', 'Yozgat', 'Zonguldak', 'Aksaray', 'Bayburt', 'Karaman', 'Kırıkkale',
    'Batman', 'Şırnak', 'Bartın', 'Ardahan', 'Iğdır', 'Yalova', 'Karabük',
    'Kilis', 'Osmaniye', 'Düzce'
  ];

  const getUserData = () => {
    HTTPService.GET(`/User/allUserName?companyId=${userInfo?.companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if (res.status === 200) {
          setUserData(res.data);
        } else {
          toast.error(res.error.message)
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const getCompanyData = () => {
    HTTPService.GET(`/Company/byId?companyId=${userInfo?.companyId}`)
      .then((res) => {
        if (!res.data) {
          return;
        }
        if (res.status === 200) {
          //console.log(res.data)
          setCompanyData(res.data);
        } else {
          toast.error(res.error.message);
        }
        let tmp: ICompanyData = res.data;
        setCompanyData(tmp);
      })
      .catch((error) => {
        console.error("Error fetching company data:", error);
      });
  };

  const updateCompany = (values) => {
    const updatedCompanyData = {
      companyId: userInfo?.companyId,
      companyName: values.companyName,
      companyAddress: values.location,
      companyCity: values.city,
      responsibleUserId: values.responsibleUserId,
      companyCode:companyData?.companyCode || ''
    };

    HTTPService.PUT(`/Company/updateCompany`, updatedCompanyData)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('update_success'));
        } else if (res.status === 400){
          toast.error(t(res.data))
        }
        else {
          toast.error(t('update_failure'));
        }
      })
      .catch(error => {
        toast.error(t('update_failure'));
      });
  };

  return (
    <>
    <Card style={{ padding: '50px' }}>
      <Form
        layout="vertical"
        onFinish={updateCompany}
        fields={[
          {
            name: ['companyName'],
            value: companyData.companyName,
          },
          {
            name: ['location'],
            value: companyData.companyAddress,
          },
          {
            name: ['city'],
            value: companyData.companyCity,
          },
          {
            name: ['firstName'],
            value: companyData?.responsibleUserTBL?.firstName,
          },
          {
            name: ['responsibleUserId'],
            value: companyData.responsibleUserTBL?.userId,
          },
          {
            name: ['companyCode'],
            value: companyData?.companyCode,
          },
        ]}
      >
        <Form.Item
          label={t('company_name')}
          name="companyName"
          rules={[{ required: true, message: t('please_input', { field: t('company_name') }) }]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} placeholder="Company Name" />
        </Form.Item>


        <Row gutter={16}>
          <Col span={16}>
            <Form.Item
              label={t('address')}
              name="location"
              rules={[{ required: true, message: t('please_input', { field: t('address') }) }]}
            >
              <Input prefix={<HomeOutlined />} style={{ borderRadius: 0 }} placeholder={t('address')} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={t('location_city')}
              name="city"
              rules={[{ required: true, message: t('please_select', { field: t('location_city') }) }]}
            >
              <Select
                showSearch
                placeholder={t('select_city')}
                optionFilterProp='children'
                filterOption={(input, option) =>
                  option?.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {cities.map((city) => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name='responsibleUserId'
          label={t('username')}
          rules={[
            {
              required: true,
              message: t('pleaseSelectResponsible'),
            },
          ]}
        >

          <Select
            showSearch placeholder={t('username')}
            style={{ marginBottom: '20px' }}
          >
            {userData?.map((user) => (
              <Select.Option key={user.userId} value={user.userId}>
                {user.firstName} {user.lastName}
              </Select.Option>
            ))}
          </Select>

        </Form.Item>

        <Form.Item label={t('companyCode')} style={{marginTop:'-20px'}}>
        <div style={{ borderRadius: 0, backgroundColor: '#ffffff', fontWeight: 'bold', padding: '8px', border: '1px solid #ddd' }}>
          {companyData?.companyCode || t('companyCode')}
        </div>
      </Form.Item>


        {/* <Form.Item
          label={t('responsible_firstname')}
          name="responsibleFirstname"
          rules={[{ required: true, message: t('please_input', { field: t('responsible_firstname') }) }]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          label={t('responsible_lastname')}
          name="responsibleLastname"
          rules={[{ required: true, message: t('please_input', { field: t('responsible_lastname') }) }]}
        >
          <Input prefix={<UserOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          label={t('responsible_telephone')}
          name="responsibleTelephone"
          rules={[{ required: true, message: t('please_input', { field: t('responsible_telephone') }) }]}
        >
          <Input prefix={<PhoneOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item>

        <Form.Item
          label={t('responsible_email')}
          name="responsibleEmail"
          rules={[{ required: true, message: t('please_input', { field: t('responsible_email') }) }]}
        >
          <Input prefix={<MailOutlined />} style={{ borderRadius: 0 }} />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ borderRadius: '5px' }}>
            {t('update_information')}
          </Button>
        </Form.Item>
      </Form>
    </Card>

    <Card style={{ padding: '50px' }}>
    <div style={{ textAlign: 'left', fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}>
      {t('packageInfo')}
    </div>
    <Form
        layout="vertical"
        onFinish={updateCompany}
        fields={[
          {
            name: ['packageName'],
            value: companyData?.licencePackagesTBL?.packageName,
          },
          {
            name: ['licenceStartDate'],
            value: moment(companyData?.licenceStartDate),
          },
          {
            name: ['licenceEndDate'],
            value: moment(companyData.licenceEndDate),
          }
        ]}
      >

       <Form.Item label={t('licenceName')}>
        <div style={{ borderRadius: 0, backgroundColor: '#ffffff', fontWeight: 'bold', padding: '8px', border: '1px solid #ddd' }}>
          {companyData?.licencePackagesTBL?.packageName || t('licenceName')}
        </div>
      </Form.Item>


      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label={t('Licence Start Date')}>
            <div style={{ borderRadius: 0, backgroundColor: '#ffffff', fontWeight: 'bold', padding: '8px', border: '1px solid #ddd' }}>
              {companyData?.licenceStartDate ? moment(companyData?.licenceStartDate).format("DD.MM.YYYY HH:mm") : t('Licence Start Date')}
            </div>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item label={t('Licence Finish Date')}>
            <div style={{ borderRadius: 0, backgroundColor: '#ffffff', fontWeight: 'bold', padding: '8px', border: '1px solid #ddd' }}>
              {companyData?.licenceEndDate ? moment(companyData?.licenceEndDate).format("DD.MM.YYYY HH:mm") : t('Licence Finish Date')}
            </div>
          </Form.Item>
        </Col>
      </Row>

      </Form>

    </Card>
    </>

  );
};

export default CompanyManagement;
