import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Input, Row, Select, SelectProps } from "antd";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import HTTPService from "../../../utils/makerequest";
import { ICompanyData } from "../../../interfaces/ICompanyData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { ITypeData } from "../../../interfaces/ITypeData";
import { useTranslation } from 'react-i18next'; 
import { ICategoryData } from "../../../interfaces/ICategoryData";
import { IPageProps } from "../../../interfaces/page-data";

const UpdateType: React.FunctionComponent<IPageProps> = () => {
  const { typeId } = useParams<any>();
  const [typeData, setTypeData] = useState<ITypeData>();
  const [companyData, setCompanyData] = useState<ICompanyData[]>([]);
  const [categoryData, setCategoryData] = useState<ICategoryData[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState(typeData?.categoryId || "");
  const [form] = Form.useForm(); // Form instance
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  const { t } = useTranslation(); 

  useEffect(() => {
    fetchCompanyData();
    if (typeId) {
      fetchTypeData(typeId);
    }
  }, [typeId]);

  const fetchAllCategoryName = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCategoryByCompany(companyId);
      setCategoryData(result);
    } catch (error) {
      console.error(error);
      toast.error(t('error_message'));
    }
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  useEffect(() => {
    if (typeData) {
      form.setFieldsValue({
        typeName: typeData.typeName,
        companyId: typeData.categoryTBL.companyId,
        categoryId: typeData.categoryTBL.categoryId,
      });
      setSelectedCompany(typeData.categoryTBL.companyId);
      setSelectedCategory(typeData.categoryTBL.categoryId);
      fetchCategoryByCompany(typeData.categoryTBL.companyId);
    }
  }, [typeData, form]);

  useEffect(() => {
    if (selectedCompany != null) {
      fetchCategoryByCompany(selectedCompany);
    }
  }, [selectedCompany]);

  const fetchCompanyData = async () => {
    try {
      const result = await HTTPService.GETCompanyNames();
      setCompanyData(result);
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchTypeData = async (typeId: number) => {
    try {
      const res = await HTTPService.GET(`/Type/ById?typeId=${typeId}`);
      if (res.status === 200) {
        setTypeData(res.data);
        setSelectedCategory(res.data.categoryId)
      } else {
        toast.error(res.error.message);
      }
    } catch (error) {
      console.error("Error fetching type data:", error);
    }
  };

  const fetchCategoryByCompany = async (companyId: number) => {
    try {
      const result = await HTTPService.GETCategoryByCompany(companyId);
      setCategoryData(result);
    } catch (error) {
      console.error("Error fetching categories by company:", error);
      setCategoryData([]);
    }
  };

  const updateType = (formData: any) => {
    const updatedType = {
      typeId: typeId,
      typeName: formData.typeName,
      companyId: formData.companyId,
      categoryId: selectedCategory,
    };

    HTTPService.PUT(`/Type/updateType`, updatedType)
      .then((res) => {
        if (res.status === 200) {
          toast.success(t('type_updated_successfully'));
          window.location.replace("/admin/type-management");
        } else {
          toast.error(t(res.data));
        }
      })
      .catch((error) => {
        toast.error(t('error_updating_type'));
      });
  };

  const handleCancel = () => {
    window.location.replace("/admin/type-management/");
  };

  const { Option } = Select;

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={updateType}
    >
      <Card style={{ padding: "50px" }}>
        <h3>{t('update_type')}</h3>
        {userInfo?.role === UserRole.AltisAdmin ? (
          <Form.Item
            name='companyId'
            label={t('Select company')}
            rules={[
              {
                required: true,
                message: t('please_select_a_company'),
              },
            ]}
          >
            <Select
              showSearch
              placeholder={t('company_name')}
              style={{ marginBottom: '6px' }}
              onChange={(value) => {
                setSelectedCompany(value);
                setSelectedCategory("");
                fetchAllCategoryName(value)
                form.setFieldsValue({ companyId: value });
              }}
              filterOption={filterOption}
            >
              {companyData.map((company) => (
                <Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item
          //name='categoryId'
          label={t('category_name')}
          rules={[{ required: true, message: t('select_category_name') }]}
        >
          <Select
            placeholder={t('category_name')}
            value={selectedCategory}
            onChange={(value) => setSelectedCategory(value)}
            filterOption={filterOption}
          >
            {categoryData.map(row => (
              <Option key={row.categoryId} value={row.categoryId}>
                {row.categoryName}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="typeName"
              label={t('type_name')}
              rules={[
                {
                  required: true,
                  message: t('please_input_your_type_name'),
                  whitespace: true,
                },
              ]}
            >
              <Input style={{ borderRadius: 0, width: '100%' }} placeholder={t('type_name')} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row gutter={[8, 8]} justify="end" align="middle">
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type="primary" style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType="submit" type="primary" style={{ borderRadius: '5px' }}>
                {t('update')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Card>
    </Form>
  );
};

export default UpdateType;
