import { CloseOutlined, DeleteOutlined, EditOutlined, EyeOutlined, HistoryOutlined, UnorderedListOutlined} from '@ant-design/icons';
import { Button, Checkbox, Col, Collapse, ConfigProvider, DatePicker, Form, Image, Input, message, Modal, notification, Pagination, Popconfirm, Row, Select, SelectProps, Space, Spin, Table, Tag, Tooltip} from 'antd';
import HTTPService from '../../../utils/makerequest';
import { IPageData } from '../../../interfaces/page-data';
import { useEffect, useRef, useState } from 'react';
import { StateLoader } from '../../../redux/StateLoader';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import toast from 'react-hot-toast';
import { IAltisDataGridColumns, IDropdownItem } from '../../../AltisComponent/AltisDataGrid';
import AltisDataGrid from '../../../AltisComponent/AltisDataGrid';
import '../PrinterManagement/Device.css';
import { useTranslation } from 'react-i18next';
import { IInventory } from '../../../interfaces/IItemData';
import i18n from '../../../i18n';
import { IFilterOptions } from '../../../interfaces/IFilterOptions';
import moment from 'moment';
import trTR from 'antd/es/locale/tr_TR';
import { filter } from 'jszip';
import CategoryManagement from '../CategoryManagement/CategoryManagement';


const InventoryReportCount: React.FunctionComponent<any> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const userInfo: IAuthentication = loader.loadAuthState();
  // const [inventoryData, setInventoryData] = useState<IInventory>();
  const [filters, setFilters] = useState({  dateProcess: { startDate: null, endDate: null },dateProcessEnd: { startDate: null, endDate: null },countingDate: { startDate: null, endDate: null },completionDate: { startDate: null, endDate: null }});
  const [inventoryData, setInventoryData] = useState({ data: [], count: 0 });
  const [createDate, setCreateDate] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [inputValueInventory, setInputValueInventory] = useState('');
  const [barcodeValue, setBarcodeValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<number[]>([]);
  const { Panel } = Collapse;
  const dataGridRef = useRef<any>(null);
  const [selectedFilters, setSelectedFilters] = useState({
    countReport:[]
  });
  const [filterOptions, setFilterOptions] = useState<IFilterOptions>({ brand: [], department: [], status: [], locations: [], category: [], supplier: [], campus: [], type: [],countReport:[]});
  const [isAll, setIsAll] = useState(false);

  const pageData: IPageData = {
    title: t('countReport'),
    loaded: true,
  };


  useEffect(() => {
    onSetPage(pageData);
    InventoryCountFilter();
  }, [i18n.language]);


  // const handleSelectAll = (e) => {
  //   var details = { ...inventoryData };

  //   if (e.target.checked) {
  //     details.data.map((row) => (row.isSelected = true));
  //   } else {
  //     details.data.map((row) => (row.isSelected = false));
  //   }

  //   setInventoryData(details);
  // };


  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setIsAll(checked); 

    const updatedData = inventoryData.data.map((row) => ({
      ...row,
      isSelected: checked,
    }));
    
    setInventoryData({
      ...inventoryData,
      data: updatedData,
    });
  };


  const handleRowSelect = (e, index) => {
    var newObject = { ...inventoryData };
    newObject.data[index].isSelected = e.target.checked;
    setInventoryData(newObject);

    const selectedRows = newObject.data.filter(row => row.isSelected);
  };

  
  
  const excelDownload = () => {
    const selectedRows = inventoryData.data.filter(row => row.isSelected);
    
  
    if (selectedRows.length === 0 && !isAll) {
      toast.error(t('NoRows'));
      return;
    }
  
    const selectedData = selectedRows.map(row => ({
      barcode: row.barcode,
      inventoryName: row.inventoryName,
      companyId: row.companyId,
      companyName: row.companyName,
      itemName: row.itemName,
      countingStatus: t(row.countingStatus),
      countingJobName: row.countingJobName,
      startDate: row.startDate,
      finishDate: row.finishDate,
      countingDate: row.countingDate,
      completionDate: row.completionDate,
      categoryName: row.categoryName,
    }));

    if(isAll == true){
      HTTPService.POSTblobExcel(`/CountingJobInventory/DownloadAllTemplateExcel?isAll=${isAll}`, {})
      .then((response) => {
        if(response.status === 200){
        toast.success(t('excelSuccess'));
        }else if (response.status === 452){
          toast.error(t('licenceExpiredDate'))
        }
        else if (response.status === 453){
          toast.error(t('licenceNotfound'))
        }
        else {
          toast.error(t(response.data))
        }
        })
        .catch((err) => {
      toast.error(t('error'));
      });
    }
    else{
      HTTPService.POSTblobExcel('/CountingJobInventory/CountingExcelDownload', selectedData)
      .then((response) => {
        if(response.status === 200){
        toast.success(t('excelSuccess'));
        }else if (response.status === 452){
          toast.error(t('licenceExpiredDate'))
        }
        else if (response.status === 453){
          toast.error(t('licenceNotfound'))
        }
        else {
          toast.error(t(response.data))
        }
      })
      .catch((err) => {
        toast.error(t('error'));
      });
    }
  };
  

  // const excelDownload = () => {
  //   const selectedRows = inventoryData.data.filter(row => row.isSelected);
    

  //   if (selectedRows.length === 0) {
  //       toast.error(t('NoRows'));
  //       return;
  //   }

  //   const selectedData = selectedRows.map(row => ({
  //     barcode: row.barcode,
  //     inventoryName: row.inventoryName,
  //     companyId: row.companyId,
  //     companyName: row.companyName,
  //     itemName: row.itemName,
  //     countingStatus:t(row.countingStatus),
  //     countingJobName:row.countingJobName,
  //     startDate:row.startDate,
  //     finishDate:row.finishDate,
  //     countingDate:row.countingDate,
  //     completionDate:row.completionDate,
  //     categoryName:row.categoryName
  //   }));

  //   HTTPService.POSTblobExcel('/CountingJobInventory/CountingExcelDownload', selectedData)
  //     .then(() => {
  //       toast.success(t('excelSuccess'));
  //     })
  //     .catch((err) => {
  //       toast.error(t('error'));
  //     });
  // };

  const pdfDownload = () => {
    const selectedRows = inventoryData.data.filter(row => row.isSelected);

    if (selectedRows.length === 0) {
        toast.error(t('NoRows'));
        return;
    }


    const selectedData = selectedRows.map(row => ({
      barcode: row.barcode,
      inventoryName: row.inventoryName,
      companyId: row.companyId,
      companyName: row.companyName,
      itemName: row.itemName,
      countingStatus:t(row.countingStatus),
      countingJobName:row.countingJobName,
      startDate:row.startDate,
      finishDate:row.finishDate,
      countingDate:row.countingDate,
      completionDate:row.completionDate,
      categoryName:row.categoryName,
      countingJobResponsibleName:row.countingJobResponsibleName,
      campusName:row.campusName,
      locationName:row.locationName
      //brandName: row.brandName,
    }));
    HTTPService.POSTblob('/CountingJobInventory/CountingPdfDownload', selectedData)
    .then((response) => {
      if(response.status === 200){

        toast.success(t('pdfSuccess'));
      }else if (response.status === 452){
        toast.error(t('licenceExpiredDate'))
      }
      else if (response.status === 453){
        toast.error(t('licenceNotfound'))
      }
      else {
        toast.error(t(response.data))
      }
      })
      .catch((err) => {
        toast.error(t('error'));
      });
  };

  const downloadExcel = (isAll) => {
    let dataToSend;
  
    if (isAll) {
      dataToSend = [];
    } else {
      const selectedRows = inventoryData.data.filter((row) => row.isSelected);
  
      if (selectedRows.length === 0) {
        toast.error(t('NoRows'));
        return;
      }
  
      dataToSend = selectedRows.map((row) => ({
        barcode: row.barcode,
        inventoryName: row.inventoryName,
        companyId: row.companyId,
        companyName: row.companyName,
        itemName: row.itemName,
        countingStatus: t(row.countingStatus),
        countingJobName: row.countingJobName,
        startDate: row.startDate,
        finishDate: row.finishDate,
        countingDate: row.countingDate,
        completionDate: row.completionDate,
        categoryName: row.categoryName,
      }));
    }
  
    HTTPService.POSTblobExcel('/CountingJobInventory/DownloadAllTemplateExcel', { isAll, data: dataToSend })
    .then((response) => {
      if(response.status === 200){
      toast.success(t('excelSuccess'));
      }else if (response.status === 452){
        toast.error(t('licenceExpiredDate'))
      }
      else if (response.status === 453){
        toast.error(t('licenceNotfound'))
      }
      else {
        toast.error(t(response.data))
      }
      })
      .catch((err) => {
        toast.error(t('error'));
      });
  };
  


  const dropdownCountStatus: IDropdownItem[] = [
    { id: 0, name: t('Expired') },
    { id: 1, name: t('In_Progress') },
    { id: 2, name: t('Not_Started') },
    { id: 3, name: t('Completed') },
  ];


  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            inventoryData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
        </Checkbox>

      ),
    },
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      placeHolder: t('Company Name'),
      isSorting:true,
      inputType: 'nofilter'
    }] : []),
    { title: t('inventoryName'),align:'left',titleAlign:'center', isSorting:true, key: 'inventoryName', placeHolder: t('inventoryName'), inputType: 'nofilter' },
    { title: t('barcode'), isSorting:true, key: 'barcode', placeHolder: t('barcode'), inputType: 'nofilter' },
    { title: t('Counting Job Name'), isSorting:true, key: 'countingJobName', placeHolder: t('Counting Job Name"'), inputType: 'nofilter' },
    { title: t('Counting Start Date'),isSorting:true, key: 'startDate', placeHolder: t('Counting Start Date'), inputType: 'nofilter', width: 500 },
    { title: t('Counting Finish Date'), isSorting:true, key: 'finishDate', placeHolder: t('Counting Finish Date'), inputType: 'nofilter', width: 500 },
    { title: t('countingStatus'),isSorting:true, key: 'countingStatus', placeHolder: t('countingStatus'), inputType: 'multiplecheckbox', render: (value) => <div>{t(value)}</div>, dropdownItem: dropdownCountStatus, },
    { title: t('countingDate'), isSorting:true, key: 'countingDate', placeHolder: t('countingDate'), inputType: 'nofilter' },
    { title: t('completionDate'), isSorting:true, key: 'completionDate', placeHolder: t('completionDate'), inputType: 'nofilter' },
  ];

  //   HTTPService.DELETE(`/Inventory/deleteInventory?Id=${id}`).then((response) => {
  //     if (response.status === 200) {
  //       toast.success(t(response.data));
  //       InventoryFilter();
  //     } else {
  //       toast.error(t(response.data));
  //     }
  //   });
  // };

  const InventoryCountFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'ascend';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize ?? 10,
      page: e?.page ?? 1,
      barcode:barcodeValue|| '', 
      inventoryName:inputValueInventory || '',  
      countingStatus: selectedValues, 
      countingJobName: inputValue || '',
      count: e?.count || [],
      startDate_Start: filters.dateProcess?.startDate || null,
      startDate_Finish: filters.dateProcess?.endDate || null,
       finishDate_Start:  filters.dateProcessEnd?.startDate || null,
       finishDate_Finish: filters.dateProcessEnd?.endDate || null,
      countingStartDate: filters.countingDate?.startDate || null,
      countingEndDate: filters.countingDate?.endDate || null,
      completionStartDate: filters.completionDate?.startDate || null,
      completionEndDate:filters.completionDate?.endDate || null
    };

    InventoryPostCountFilter(body)
  };

  const handleSearch = () => {
    const filterData = {
      ...filters,
      pageSize: 10,
      page: 1,
      countingStatus:selectedValues,
      countingJobName:inputValue,
      count:selectedFilters.countReport,
      inventoryName:inputValueInventory,
      barcode:barcodeValue
    };
    InventoryCountFilter(filterData);
  };

  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputChangeInventory = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValueInventory(e.target.value);
  };


  const handleDateChange = (dates, type) => {
  
    if (!dates) {
      setFilters(prev => ({
        ...prev,
        [type]: { startDate: null, endDate: null }
      }));
      return;
    }
  
    const [startDate, endDate] = dates;

    const formattedStartDate = startDate ? moment(startDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
    const formattedEndDate = endDate ? moment(endDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : null;
  
    setFilters(prev => ({
      ...prev,
      [type]: {
        startDate:formattedStartDate,
        endDate:formattedEndDate
      }
    }));
  
  };
  

  const handleCheckboxChange = (value: number) => {
    setSelectedValues((prev) => {
      if (prev.includes(value)) {
        return prev.filter((v) => v !== value);
      } else {
        return [...prev, value];
      }
    })
  };
  



  const handleFilterChange = (filterType: keyof typeof selectedFilters, id: number) => {
    const newSelectedFilters = {
      ...selectedFilters,
      [filterType]: selectedFilters[filterType].includes(id)
        ? selectedFilters[filterType].filter(item => item !== id)
        : [...selectedFilters[filterType], id]
    };

    setSelectedFilters(newSelectedFilters);


    if (filterType === 'countReport') {
      InventoryCountFilter({ countingStatus: newSelectedFilters.countReport });
    }

  };

  const InventoryPostCountFilter =(object)=>{
    HTTPService.POST(`/CountingJobInventory/filtersCounting`, object).then((response) => {
      if (!response.data) {
        return;
      }
      if (response.status === 200) {
        console.log(response.data)
        setInventoryData(response.data);
      }  else if (response.status === 400){
        toast.error(t(response.data));
      }else {
        toast.error(response.data);
      }
    });
  }

  const clearFilters = () => {
    const filterData = {
      ...filters,
      pageSize: 10,
      page: 1,
    };

    setSelectedValues([])
    setInputValue('')
    setInputValueInventory('')
    setBarcodeValue('')
    setCreateDate([])

    InventoryPostCountFilter(filterData)
  };
  

  const handleBarcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const trim = e.target.value.trim()
    setBarcodeValue(trim);
  };


  return (
    <>
      <Row gutter={16} style={{ marginTop: '20px' }}>
        <Col xs={24} sm={8} md={6} lg={4} xl={4} style={{ paddingLeft: '8px' }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '5px',
            padding: '5px 10px',
            border: '1px solid #d9d9d9',
            borderRadius: '5px',
            backgroundColor: 'white',
            marginTop: '70px'
          }}>
            <span style={{
              flexGrow: 1,
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#333'
            }}>
              {t('noFilter')}
            </span>
            <CloseOutlined
              onClick={clearFilters}
              style={{ cursor: 'pointer', color: 'black', fontSize: '16px' }}
            />
          </div>
        <ConfigProvider locale={trTR}>
          <Collapse style={{ 
            width: '100%', 
            maxWidth: '220px', 
            marginTop: '20px', 
            border: 'none' 
          }}>

            <Collapse.Panel header={t('Counting Job Name')} key="1">

                  <div>
                    <Input
                      name='Counting Job Name'
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      placeholder={t('Counting Job Name')}
                    />
                  </div>

            </Collapse.Panel>

            <Collapse.Panel header={t('inventoryName')} key="2">
              <div>
                <Input
                  name='inventoryName'
                  type="text"
                  value={inputValueInventory}
                  onChange={handleInputChangeInventory}
                  placeholder={t('inventoryName')}
                />
              </div>

            </Collapse.Panel>

            <Collapse.Panel header={t('countingStatus')} key="3">
              <div>
                <Checkbox
                  value={0}
                  checked={selectedValues.includes(0)}
                  onChange={() => handleCheckboxChange(0)}
                  style={{ marginLeft: '8px' }}
                >
                  {t('Expired')}
                </Checkbox>
                <Checkbox
                  value={1}
                  checked={selectedValues.includes(1)}
                  onChange={() => handleCheckboxChange(1)}
                >
                  {t('In_Progress')}
                </Checkbox>
                <Checkbox
                  value={2}
                  checked={selectedValues.includes(2)}
                  onChange={() => handleCheckboxChange(2)}
                >
                  {t('Not_Started')}
                </Checkbox>
                <Checkbox
                  value={3}
                  checked={selectedValues.includes(3)}
                  onChange={() => handleCheckboxChange(3)}
                >
                  {t('Completed')}
                </Checkbox>
              </div>
            </Collapse.Panel>


            <Collapse.Panel header={t('barcode')} key="4">

              <div>
                <Input
                  name='barcode'
                  type="text"
                  value={barcodeValue}
                  onChange={handleBarcodeChange}
                  placeholder={t('barcode')}
                />
              </div>
            </Collapse.Panel>
            
            <Panel header={t('dateProcess')} key="10">
              <DatePicker.RangePicker onChange={(dates) => handleDateChange(dates, 'dateProcess')}  format={'DD/MM/YYYY HH:mm'} showTime style={{ width: '100%' }} />
            </Panel>

            <Panel header={t('dateProcessEnd')} key="11">
              <DatePicker.RangePicker onChange={(dates) => handleDateChange(dates, 'dateProcessEnd')} format={'DD/MM/YYYY HH:mm'} showTime style={{ width: '100%' }} />
            </Panel>

            <Panel header={t('countingDate')} key="12">
              <DatePicker.RangePicker onChange={(dates) => handleDateChange(dates, 'countingDate')} format={'DD/MM/YYYY HH:mm'} showTime style={{ width: '100%' }} />
            </Panel>

            <Panel header={t('completionDate')} key="13">
              <DatePicker.RangePicker onChange={(dates) => handleDateChange(dates, 'completionDate')} format={'DD/MM/YYYY HH:mm'} showTime style={{ width: '100%' }} />
            </Panel>

          </Collapse>
        </ConfigProvider>
          <Button type="primary" style={{ marginTop: '10px', width: '100%',maxWidth:'200px' }} onClick={InventoryCountFilter}>
            {t('search')}
          </Button>
        </Col>

        <Col xs={24} sm={16} md={18} lg={20} xl={20} style={{ marginTop: '10px', paddingLeft: '8px' }}>
          <Button
            type="primary"
            onClick={excelDownload}
            style={{
              width: '100%',
              marginRight: '10px',
              maxWidth: '200px',
              borderRadius:'5px'
            }}
          >
            {t('Excel')}
          </Button>
          <Button
            type="primary"
            onClick={pdfDownload}
            style={{
              width: '100%',
              maxWidth: '200px', 
              borderRadius:'5px'
            }}
          >
            {t('PDF')}
          </Button>
          <br></br><br></br>
          <AltisDataGrid
            ref={dataGridRef}
            columns={columns}
            onChange={InventoryCountFilter}
            data={inventoryData?.data}
            total={inventoryData?.count}
          />
        </Col>
      </Row>
    </>
  );



};

export default InventoryReportCount;
