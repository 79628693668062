import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Modal, Popconfirm, Row, Select, Space, Spin, Tooltip } from "antd";
import HTTPService from "../../../utils/makerequest";
import { IPageData, IPageProps } from "../../../interfaces/page-data";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import AltisDataGrid, { IDropdownItem } from "../../../AltisComponent/AltisDataGrid";
import { IAltisDataGridColumns } from "../../../AltisComponent/AltisDataGrid";
import "../CompanyManagement/CustomerManagement.css";
import { IApplicationData } from "../../../interfaces/IApplicationData";
import { IAuthentication, UserRole } from "../../../interfaces/authentication";
import { StateLoader } from "../../../redux/StateLoader";
import { useTranslation } from 'react-i18next'; // Ekleyin
import i18n from "../../../i18n";
import { ILocationData } from "../../../interfaces/ILocationData";
import { IPrinterData } from "../../../interfaces/IPrinterData";
import { ITemplatesName } from "../../../interfaces/ITemplateName";

export interface ILocation {
  count?: number;
  data?: ILocationData[];
}

const LocationManagement: React.FunctionComponent<IPageProps> = (props) => {
  const { onSetPage } = props;
  const { t } = useTranslation();
  const loader = new StateLoader();
  const [locationData, setLocationData] = useState<ILocation>({});
  const [templateData, setTemplateData] = useState<ITemplatesName[]>();
  const [printerData, setPrinterData] = useState<IPrinterData[]>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleExport, setIsModalVisibleExport] = useState(false);
  const userInfo: IAuthentication = loader.loadAuthState();
  const [isLoading, setIsLoading] = useState(false); 

  const pageData: IPageData = {
    title: t('locationManagement'), 
    loaded: true
  };

  useEffect(() => {
    onSetPage(pageData);
    LocationFilter();
  }, [i18n.language]);

  const dropdownItemType: IDropdownItem[] = [
    { id: 0, name: 'Tcp Client' },
    { id: 1, name: 'Tcp Server' },
    { id: 2, name: 'Web Socket Client' },
    { id: 3, name: 'Web Service' },
    { id: 4, name: 'Serial Port' },
    { id: 5, name: 'Web Socket Server' }
  ];

  const columns: IAltisDataGridColumns[] = [
    {
      title: (
        <Checkbox
          onChange={(e) => handleSelectAll(e)}
          checked={
            locationData?.data?.filter(
              (row) =>
                row.isSelected === false || row.isSelected === null || row.isSelected === undefined
            ).length === 0
          }
        />
      ),
      key: '',
      placeHolder: '',
      inputType: 'custom',
      width: 80,
      render: (text, record, index) => (
        <Checkbox
          onChange={(e) => handleRowSelect(e, index)}
          checked={record.isSelected}>
          </Checkbox>
          
      ),
    },
    ...(userInfo.role !== UserRole.Admin ? [{
      title: t('Company Name'),
      key: 'companyName',
      isSorting:true,
      placeHolder: t('Company Name'),
      inputType: 'input'
    }] : []),
    { title: t('campus'),isSorting:true, key: 'campusName', placeHolder: t('campus'), inputType: "input" },
    { title: t('location'),isSorting:true, key: 'locationName', placeHolder: t('location'), inputType: "input" },
    { title: t('locationNumber'),isSorting:true, key: 'barcode', placeHolder: t('barcode'), inputType: "input" },
    {
      title: t('operations'),
      key: 'operations',
      placeHolder: t('operations'),
      inputType: "operations",
      render: (order, record) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
          <Space size={10}>
            <Popconfirm
              title={<div>{t('are_you_sure_location')} <br /></div>}
              onConfirm={() => DeleteLocation(record.locationId)}
              okText={t('yes')}
              cancelText={t('no')}
            >
            <Tooltip title={t('delete')}>
              <Button
                  size='small'
                  icon={<DeleteOutlined />}
                  onClick={(e) => { e.stopPropagation(); }}
                  style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
                >
                </Button>
            </Tooltip>
         
            </Popconfirm>
            <Tooltip title={t('update')}>
              <Button
                size='small'
                icon={<EditOutlined />}
                onClick={() => window.location.replace('/admin/update-location/' + record.locationId)}
                style={{ display: userInfo.role === UserRole.Sayman ? 'none' : 'block',width: '60px',borderRadius:'70px' }}
              >
              </Button>
            </Tooltip>
          </Space>
        </div>
      ),
    }
  ];

  const AllTemplateName = (values) =>{
     let companyIdToUse=values;
     HTTPService.GET(`/Template/allTemplateName?companyId=${companyIdToUse}`)
       .then((response) => {
         if (!response.data) {
           return;
         }
         let tmp: ITemplatesName[] = response.data;
         tmp.map((elem, index) => {
           elem['key'] = index;
         });
         setTemplateData(tmp);
       })
       .catch((error) => {
         console.error('Şablon çekme hatası:', error);
       });
   };
  
  
    const AllPrinterName = (values) => {
      let companyIdToUse=values;
      HTTPService.GET(`/Printer/allPrinterName?companyId=${companyIdToUse}`)
        .then((response) => {
          if (!response.data) {
            return;
          }
          let tmp: IPrinterData[] = response.data;
          tmp.map((elem, index) => {
            elem['key'] = index;
          });
          setPrinterData(tmp);
        })
        .catch((error) => {
          console.error('Cihaz çekme hatası:', error);
        });
    };
  
    

   
  const handleSelectAll = (e) => {
    var details = { ...locationData };
    if (e.target.checked) {
      details.data.map((row) => (row.isSelected = true));
    } else {
      details.data.map((row) => (row.isSelected = false));
    }

    setLocationData(details);
  };

   const handleRowSelect = (e, index) => {
     var newObject = { ...locationData, };
     newObject.data[index].isSelected = e.target.checked;
     setLocationData(newObject);
   };

  const LocationFilter = (e?) => {
    const sortColumn = e?.sortColumn; 
    const sortOrder = e?.sortOrder || 'asc';

    const body = {
      sortColumn,
      sortOrder,
      pageSize: e?.pageSize == null ? 10 : e.pageSize,
      page: e?.page == null ? 1 : e.page,
      locationName: e?.locationName,
      companyName: e?.companyName,
      campusName:e?.campusName,
      barcode:e?.barcode
    };

    HTTPService.POST(`/Location/filters`, body).then((response) => {
      if (!response.data) return;
      if (response.status === 200) {
        setLocationData(response.data);
      } else {
        toast.error(response.data);
      }
      const tmp: ILocation = response.data;
      tmp.data?.map((elem, index) => {
        elem['key'] = index;
      });
      setLocationData(tmp);
    });
  };

  const DeleteLocation = (Id) => {
    HTTPService.DELETE(`/Location/deleteLocation?Id=${Id}`).then(
      (response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.reload();
        } else {
          toast.error(t(response.data));
        }
      }
    );
  };

  const handleCancelExport = ()=>{
    setIsModalVisibleExport(false);
  }

  const showModal = () => {
    const selectedInventory = locationData.data.filter((item) => item.isSelected === true);

  
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));
  
    if(distinctCompanyIds.length>1){
      setIsModalVisible(false);
      toast.error(t('sameCompany'))
    }else if(selectedInventory.length===0){
      setIsModalVisible(false);
      toast.error(t('anyLocation'))
    }
    else{
      setIsModalVisible(true);
    }
       AllTemplateName(distinctCompanyIds[0]); 
      AllPrinterName(distinctCompanyIds[0]); 
   };

   const PrintData = (values) => {
    setIsLoading(true)
    const selectedInventory = locationData.data.filter((item) => item.isSelected === true);

    const printLocationBarcode = selectedInventory.map((item) => item.barcode);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));
    //const printInventoryList = Array.from(new Set(selectedInventory.map(item => item.companyId)))
    var body = {
      printerId: values.selectPrinter,
      companyId:distinctCompanyIds[0],
      templateId:values.selectTemplate,
      printsBarcode: printLocationBarcode
    };
    HTTPService.POST(`/Inventory/PrintInventory`, body).then((response) => {
      if (response.status === 200) {
        window.location.reload();
        toast.success(t(response.data));
      }
      else if (response.status === 452){
        toast.error(t(response.data))
      }
      else if (response.status === 453){
        toast.error(t(response.data))
      }
      else {
        toast.error(t('printError'));
      }

    }).catch((response) => {

      console.error(response);
    }).finally(() => {
      setIsLoading(false); // Stop loading
      setIsModalVisible(false); // Hide modal
    })
  };

  const handleCancel = ()=>{
    setIsModalVisible(false);
  }

  const showModalExport = () => {
    const selectedInventory = locationData.data.filter((item) => item.isSelected === true);
  
    // const companyIds = selectedInventory.((item) => item.companyId);
    const distinctCompanyIds = Array.from(new Set(selectedInventory.map(item => item.companyId)));
  
    if(distinctCompanyIds.length>1){
      setIsModalVisibleExport(false);
      toast.error(t('sameCompany'))
    }else if(selectedInventory.length===0){
      setIsModalVisibleExport(false);
      toast.error(t('anyInventory'))
    }
    else{
      setIsModalVisibleExport(true);
    }
      AllTemplateName(distinctCompanyIds[0]); 
   };

   
  const PDFPriview = async (values) => {
    setIsLoading(true)
    try {
        const selectedPrintsDetails = locationData.data.filter(item => item.isSelected);
        const printInventoryBarcode = selectedPrintsDetails.map(item => item.barcode);

        const body = {
            templateId: values.selectTemplate,
            barcode: printInventoryBarcode
        };

        HTTPService.POSTblob(`/Inventory/pdfpreview`, body)
        .then((response) => {
          if(response.status === 200){
  
            toast.success(t('pdfSuccess'));
          }else if (response.status === 452){
            toast.error(t('licenceExpiredDate'))
          }
          else if (response.status === 453){
            toast.error(t('licenceNotfound'))
          }
          else {
            toast.error(t(response.data))
          }
        })
        .catch((err) => {
          toast.error(t('error'));
        });
    } catch (error) {
        console.error('Error in PDF preview:', error);
    }finally{
        setIsLoading(false);
        setIsModalVisibleExport(false);
      }
};


  return (
    <>
   <Row gutter={8} style={{ marginBottom: '20px' }}>
      <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'flex-start' }}>

        <Button
          type='primary'
          shape='round'
          size='middle'
          onClick={showModal}
          hidden={userInfo.role === UserRole.Personel}
          style={{ height: 40, borderRadius: '5px',width:'150px',marginRight:10 }} 
          >
          {t('print')}
        </Button>

        <Button
          type='primary'
          shape='round'
          size='middle'
          onClick={showModalExport}
          hidden={userInfo.role === UserRole.Personel}
          style={{ width: '150px', height: 40, borderRadius: '5px', marginRight: '8px' }}
          >
          {t('PDF Export')}
        </Button>


        <Button
          type='primary'
          shape='round'
          style={{ width: '150px', height: 40,borderRadius: '5px',marginRight: '8px' }}
          size='middle'
          onClick={() => window.location.replace('/admin/add-location')}
          >
          {t('add_new_location')}
        </Button>

      
      </Col>
    </Row>

    
<Modal
  title={t('PDF Export')}
  visible={isModalVisibleExport}
  onCancel={handleCancelExport}
  width={800}
  footer={null}
>
  <div style={{ padding: '20px' }}>
  <Spin spinning={isLoading}> 
    <Form onFinish={PDFPriview}>
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={t('select_template')}
            name='selectTemplate'
            rules={[
              {
                required: true,
                message: t('please_select_template'),
              },
            ]}
            style={{ marginBottom: '16px' }} // Adjusted margin
          >
            <Select
              key='selectTemplateUpload'
              style={{ width: '100%' }} // Ensure full width
            >
              {templateData?.map((item) => (
                <Select.Option key={item.templateId} value={item.templateId}>
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            type='primary'
            htmlType="submit"
            shape='round'
            size='middle'
            style={{ width: 'auto',borderRadius: '5px'}}
          >
            {t('PDF Export')}
          </Button>
        </Col>
      </Row>
    </Form>
    </Spin>
  </div>
</Modal>


      <Modal
  title={t('Template Printing')}
  visible={isModalVisible}
  onCancel={handleCancel}
  width={800}
  footer={null}
>
  <div style={{ padding: '20px' }}>
  <Spin spinning={isLoading}> 
    <Form onFinish={PrintData} >
      <Row gutter={16}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Form.Item
            label={t('select_template')}
            name='selectTemplate'
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: t('please_select_template'),
              },
            ]}
          >
            <Select
              key='selectTemplateUpload'
              style={{ width: '100%', marginRight: '10px' }}
            >
              {templateData?.map((item) => (
                <Select.Option key={item.templateId} value={item.templateId}>
                  {item.templateName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={23} sm={23} md={23} lg={24}>
          <Form.Item
            label={t('selectPrinter')}
            name='selectPrinter'
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: t('Please select printer'),
              },
            ]}
            style={{ marginLeft: 20 }} // Remove extra margin
          >
            <Select
              key='selectPrinterUpload'
              className='custom-select' // Apply custom class
            >
              {printerData?.map((item) => (
                <Select.Option key={item.printerId} value={item.printerId}>
                  {item.printerName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Button
          type='primary'
          htmlType="submit"
          shape='round'
          size='middle' 
          onClick={showModal}
          style={{ width: userInfo?.role === UserRole.AltisAdmin ? '20%' : '100%', marginLeft: '575px',borderRadius:'5px' }}
        >
          {t('print')}
        </Button>
      </Row>
    </Form>
  </Spin> 
  </div>
      </Modal>
      <div className="row">
        <div className="col-md-12">
          <div className="table-container">
            <AltisDataGrid data={locationData.data} total={locationData.count} columns={columns} onChange={LocationFilter} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationManagement;
