import { useEffect, useState } from 'react';
import { IPageProps } from '../../../interfaces/page-data';
import { IAuthentication, UserRole } from '../../../interfaces/authentication';
import { ICompany } from '../../../interfaces/ICompany';
import { StateLoader } from '../../../redux/StateLoader';
import HTTPService from '../../../utils/makerequest';
import toast from 'react-hot-toast';
import { Button, Card, Col, Form, Image, Input, Row, Select, SelectProps, Spin } from 'antd';
import { BarcodeOutlined, UserOutlined } from '@ant-design/icons';
import { t } from 'i18next';
import { ITemplatesData } from '../../../interfaces/ITemplatesData';

const AddTemplates: React.FunctionComponent<IPageProps> = () => {
  const [companyData, setCompanyData] = useState<ICompany[]>();
  const [templateData, setTemplateData] = useState<ITemplatesData>({})
  const [zplData, setZplData] = useState(null);
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [renderResponse, setRenderResponse] = useState(null);
  const [renderError, setRenderError] = useState(null);
  const [printDensityDpmm, setPrintDensityDpmm] = useState(8);
  const [labelWidth, setLabelWidth] = useState(102);
  const [labelHeight, setLabelHeight] = useState(152);

  const loader = new StateLoader();
  const user: IAuthentication = loader.loadAuthState();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () =>{
    const result = await HTTPService.GETCompanyNames();
    setCompanyData(result);
  }

  const handleChangePrinterBrand = (value) => {
    setTemplateData(prevData => ({
      ...prevData,
      printerModel: value,
    }));
  };


  const TemplatesRegister = (values) => {
    let companyIdToUse;
    if (user?.role === UserRole.AltisAdmin) {
      companyIdToUse = values.selectedCompany;
    } else {
      companyIdToUse = user?.companyId;
    }
    const newTemplates = {
      companyId: companyIdToUse,
      templateName: values.templatesName,
      templatesText: zplData,
      templatesDpmm: printDensityDpmm,
      labelWidth :labelWidth,
      labelHeight: labelHeight,
      templatesCode:values.templatesCode,
      printerModel:values.printerModel
    };
    HTTPService.POST(`/Template/registerTemplate`, newTemplates)
      .then((response) => {
        if (response.status === 200) {
          toast.success(t(response.data));
          window.location.replace('/admin/templates');
        } else {
          toast.error(t(response.data));
        }
      })
      .catch((error) => {
        console.error('Şablon kaydı başarısız: ', error);
      });
  };

  const handleCancel = () => {
    window.location.replace('/admin/templates/');
  };

  const filterOption: SelectProps<any>['filterOption'] = (input, option) => {
    return (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase());
  };
  

  const render = async () => {
    setRenderError(null);
    setLoadingPreview(true);
    setRenderResponse(null);

    try {
      const payload = {
        zplData,
        printDensityDpmm,
        labelWidth,
        labelHeight,
      };
      HTTPService.POST(`/Template/Templates`, payload).then((response) => {
        if (response.status === 200) {
          const tmp = response.data;
          setRenderResponse(tmp);
        }
        else if (zplData === null) {
          toast.error(t('pleaseSelectTemplateText'));
        }else{
          toast.error(t(response.data));
        }
      });
    } catch (err) {
      setRenderError(err);
      console.error(renderError);
    } finally {
      setLoadingPreview(false);
    }
  };
  const barcode39 = `^XA
  ^FX wide-to-narrow = 2
  ^PW900
  ^LL800
  ^FO10,10
  ^BY3,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,160
  ^BY4,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,320
  ^BY5,2
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FX wide-to-narrow = 3
  
  ^FO10,500
  ^BY3,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,650
  ^BY4,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^FO10,810
  ^BY5,3
  ^B3N,N,100,Y,N
  ^FD123ABC^FS
  
  ^XZ`;
  const barcode128 = `^XA

  ^FX Normal Mode
  ^PW900
  ^LL800
  ^FO10,10
  ^BY3
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FO10,160
  ^BY4
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FO10,320
  ^BY5
  ^BCN,100,Y
  ^FDABC12345^FS
  
  ^FX Automatic Mode
  
  ^FO10,500
  ^BY3
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^FO10,650
  ^BY4
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^FO10,810
  ^BY5
  ^BCN,100,Y,,,A
  ^FDABC12345^FS
  
  ^XZ`;
  const dataMatrix = `^XA
  ^PW900
  ^LL800
  ^FO30,30^BXN,2,200^FDABC^FS
  ^FO30,70^BXN,4,200^FDABC^FS
  ^FO30,130^BXN,6,200^FDABC^FS
  ^FO30,210^BXN,8,200^FDABC^FS
  ^FO30,310^BXN,10,200^FDABC^FS
  
  ^XZ`;
  const qrCode = `^XA
  ^PW900
  ^LL800
  ^FO10,10
  ^BQN,2,1
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,50
  ^BQN,2,2
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,120
  ^BQN,2,3
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,220
  ^BQN,2,4
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^FO10,360
  ^BQN,2,8
  ^FDMA,Altis.LabelDesigner.Viewer^FS
  
  ^XZ`;
  const gs1 = `^XA
  ^PW900
  ^LL800
  ^FX all should produce 011234567890123121123456
  
  ^FT101,59^A0N,31,30^FH ^CI28^FDGS1-128 : ^FS^CI27
  ^BY2,3,54^FT110,134^BCN,,N,N
  ^FH ^FD>;>8011234567890123121123456^FS
  
  ^FT101,174^A0N,31,30^FH ^CI28^FDGS1-DataMatrix : ^FS^CI27
  ^FT185,324^BXN,6,200,0,0,1,_,1
  ^FH ^FD_1011234567890123121123456^FS
  
  ^FT548,174^A0N,31,30^FH ^CI28^FDGS1-QR: ^FS^CI27
  ^FO642,206^BQN,2,5
  ^FH ^FD>;>8011234567890123121123456^FS
  
  ^PQ1,0,1,Y
  ^XZ`;

  const trCharacter = ` ^XA
  ^PW1000
  ^LL900
  ^CI28
  ^CF0,50

  ^FO100,300
  ^BY3,2
  ^B3N,N,100,Y,N
  ^FD$barcode$^FS

  ^FO350,460^FD$inventoryName$^FS

  ^XZ `;

  const rfidCharacter = ` ^XA
  ^PW1000
  ^LL900
  ^FO50,50
  ^A0N,65
  ^FO350,460^FD$inventoryName$^FS
  ^FO50,150 
  ^BY3,3,50 
  ^RFW,H
  ^FD$barcode$^FS
  ^XZ `;

  return (
    <Card style={{ padding: '50px' }}>
      <h3>{t('register-templates')}</h3>
      <Form layout='vertical' onFinish={TemplatesRegister}>
        {user?.role === UserRole.AltisAdmin && (
          <Form.Item
          label={t('Select company')}
            name='selectedCompany'
            rules={[
              {
                required: true,
                message: t('select_company'),
              },
            ]}
          >
            <Select showSearch placeholder={t('company_name')} filterOption={filterOption}>
              {companyData?.map((company) => (
                <Select.Option key={company.companyId} value={company.companyId}>
                  {company.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}

        <Form.Item
          name='templatesName'
          label={t('template_name')}
          rules={[
            {
              required: true,
              message: t('input_template_name'),
              whitespace: true,
            },
          ]}
        >
          <Input
            placeholder={t('template_name')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>
        
        <Form.Item
          name='printerModel'
          label={t('Printer Model')}
          rules={[{ required: true, message: t('Printer Model') }]}
        >
          <Select
            showSearch
            placeholder={t('Printer Model')}
            onChange={handleChangePrinterBrand}
          >
            <Select.Option value={0}>Zebra</Select.Option>
            <Select.Option value={1}>Sato</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name='templatesCode'
          label={t('Templates Code')}
          rules={[
            {
              required: true,
              message: t('input_template_code'),
              whitespace: true,
            },
          ]}
        >
          <Input
            prefix={<BarcodeOutlined />}
            placeholder={t('template_code')}
            style={{ borderRadius: 0 }}
          />
        </Form.Item>

        {templateData.printerModel=== 0 && (
                   <Row gutter={[16, 16]}>
                   <Col xs={24} sm={12} md={6}>
                     <Form.Item label={t('dpmm')}>
                       <Select value={printDensityDpmm} onChange={(value) => setPrintDensityDpmm(value)}>
                         <Select.Option value={6}>6 dpmm</Select.Option>
                         <Select.Option value={8}>8 dpmm</Select.Option>
                         <Select.Option value={12}>12 dpmm</Select.Option>
                         <Select.Option value={24}>24 dpmm</Select.Option>
                       </Select>
                     </Form.Item>
                   </Col>
         
                   <Col xs={24} sm={12} md={6}>
                     <Form.Item label={t('render')}>
                       <Button type='primary' onClick={render} style={{ width: '100%',borderRadius:'5px' }}>
                         {t('render')}
                       </Button>
                     </Form.Item>
                   </Col>
                 </Row>
          )}

          

        <Row gutter={[16, 16]}>
          {templateData.printerModel ===0 &&(
               <Col xs={24} sm={12} md={6}>
               <div style={{ display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                 <Form.Item>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(barcode39)}>
                     {t('barcode39')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(barcode128)}>
                     {t('barcode128')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(dataMatrix)}>
                     {t('data_matrix')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(qrCode)}>
                     {t('qr_code')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(gs1)}>
                     {t('gs1')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(trCharacter)}>
                     {t('trCharacter')}
                   </Button>
                   <div style={{ height: 10 }}></div>
                   <Button style={{borderRadius:'5px'}} type='primary' onClick={() => setZplData(rfidCharacter)}>
                     {t('rfidCharacter')}
                   </Button>
                 </Form.Item>
               </div>
             </Col>
          )}
         
          <Col xs={24} sm={12} md={8}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('select_template_text'),
                  whitespace: true,
                },
              ]}
            >
              <Input.TextArea
                value={zplData}
                onChange={(e) => setZplData(e.target.value)}
                rows={20}
                style={{ width: '100%', height: '100%' }}
                required
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={10} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {loadingPreview && <Spin />}
            {renderResponse && (
              <div className='position-relative'>
                {renderResponse.labels?.length > 0 && (
                  <div>
                    {renderResponse.labels?.map((labelInfo, index) => (
                      <Image
                        key={index}
                        src={`data:image/png;base64,${labelInfo.imageBase64}`}
                        style={{ width: '100%', height: 'auto' }}
                        alt='Rendered Label'
                      />
                    ))}
                  </div>
                )}
                {renderResponse.nonSupportedCommands?.length > 0 && (
                  <div className='alert alert-warning mt-1' role='alert'>
                    <h4>{t('non_supported_commands')}</h4>
                    <ul>
                      {renderResponse.nonSupportedCommands.map((unknownCommand, index) => (
                        <li key={index}>{unknownCommand}</li>
                      ))}
                    </ul>
                  </div>
                )}
                {renderError && (
                  <div className='alert alert-warning mt-1' role='alert'>
                    <h4>{t('label_rendering_error')}</h4>
                    <pre>{renderError}</pre>
                  </div>
                )}
              </div>
            )}
          </Col>
        </Row>

        <Form.Item>
          <Row gutter={[8, 8]} justify='end' align='middle'>
            <Col xs={12} sm={4}>
              <Button block onClick={handleCancel} type='primary' style={{ borderRadius: '5px' }}>
                {t('cancel')}
              </Button>
            </Col>
            <Col xs={12} sm={4}>
              <Button block htmlType='submit' type='primary' style={{ borderRadius: '5px' }}>
                {t('save')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AddTemplates;